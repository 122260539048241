.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 700px) {
      margin-top: 200px !important;
      min-margin: 170px !important;
      margin-bottom: auto !important;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important;
      padding-left: 38px !important;
      padding-right: 38px !important; }

    .container_step_description {
      padding-top: 20px;
      padding-left: 20px !important;
      padding-right: 20px !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      .container_title {
        display: flex;
        align-items: center; }
      .title, .description, .link {
        color: #000000;
        // user-select: none
 }        // pointer-events: none
      .title {
        max-width: 253px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500; }
      .description {
        font-family: FuturaPT-Light;
        font-size: 17px;
        font-weight: 300;
        text-align: center; }
      .link {
        font-family: FuturaPT;
        font-size: 17px;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px; }
      .container_instruction {
        display: flex;
        flex-wrap: wrap; } }
    .container_step_instructions {
      margin-top: 25px;
      display: flex;
      align-items: left;
      flex-direction: column; }
    .container_form {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .input {
        margin-top: 15px; } }
    .container_copy {
      padding-top: 0px;
      .button {
        display: flex;
        justify-content: center; } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      margin-top: auto;
      .button {
        margin-top: 20px;
        display: flex;
        justify-content: center; } } } }
