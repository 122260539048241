.document_container {
    position: relative;
    min-width: 140px;
    max-width: 290px;
    height: 225px;
    max-height: 225px;
    padding: 10px 0 0;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0 rgba(177, 181, 198, 0.77), -2px -3px 8px 0 #fafafc;
    background-image: linear-gradient(316deg, #f4f4f5 84%, #ffffff);
    overflow: hidden;
    .status {
        z-index: 1;
        position: absolute;
        left: 10px; }
    .document_content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 155px;
        .rfp {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 10px;
            padding-left: 10px;
            height: 17px;
            border-radius: 23px;
            background-color: rgba(76, 72, 143, 0.64);
            position: absolute;
            right: 10px;
            top: 10px;
            font-family: FuturaPT-Demi;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: white; }
        .document {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 225px;
                max-width: 143px; }
            canvas {
                height: 155px !important;
                width: 120px !important; } }
        .upload {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; } }
    .document_actions {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        width: calc(100% - 20px);
        height: 50px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 0 -1px 2px 0 #ebecf0;
        background-color: #ffffff;
        bottom: 0;
        position: absolute;
        .document_name {
            font-family: FuturaPT-Medium;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal; }
        .document_date {
            font-family: FuturaPT;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #757e95;
            margin-top: 5px; } }
    .error_type_doc {
        width: 80%;
        object-fit: cover; } }
