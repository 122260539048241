.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  .container_bg {
    // background-color: #00FF00
    position: absolute;
    bottom: 0%;
    width: 40%px;
    height: 205px;
    z-index: -9999999;
    user-select: none;
    pointer-events: none;
    display: flex;
    img {
      height: 100%;
      width: 100%;
      object-fit: fill; } }
  .container_bg {
    svg {
      width: 100%;
      height: 100%; } }
  .main_content {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 10px !important;
    @media (min-width: 800px) {
      padding-left: 0px !important;
      padding-right: 40px !important; }
    .filter_buttons, .filter_roles {
      padding: 20px 0 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .filter_buttons {
      min-height: 93px; }
    .filter_roles {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
      .roles_button, .roles_button_active {
        border: none;
        height: 30px;
        margin: 10px;
        font-size: 16px;
        letter-spacing: 0.89px;
        max-width: fit-content;
        background-color: white;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.3);
        font-family: FuturaPT-Medium; }
      .roles_button_active {
        color: black;
        border-bottom: 2px solid black; } }
    .filter_box {
      min-height: 72px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .sort_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        @media (min-width: 701px) {
          flex-grow: 0;
          width: fit-content; }
        .sort_label {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500; }
        .sort_select, .filter_date {
          max-width: 150px;
          padding-left: 10px;
          padding-right: 10px;
          flex-grow: 1;
          @media (min-width: 701px) {
            flex-grow: 0;
            width: 150px; } }
        .filter_date {
          @media (min-width: 701px) {
            width: 220px;
            max-width: 220px; } } } }
    .labels_box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 20px;
      .received_circle,.nominated_circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0 5px 0 10px; }
      .received_circle {
        background-color: #757e95; }
      .nominated_circle {
        background-color: #4c488f; }
      .label {
        font-family: FuturaPT-Medium;
        font-size: 16px;
        font-weight: 500;
        margin: 0 10px 0 5px; } }
    .scroll_container {
      overflow: scroll;
      .list_container {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        padding-bottom: 80px;
        @media (min-width: 701px) {
          flex-direction: row;
          flex-flow: row wrap; } } } }
  .container_add_button {
    right: 30px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 60px;
    height: 60px;
    bottom: 27px;
    z-index: 1300;
    @media (min-width: 701px) {
      bottom: 80px;
      right: 120px; }
    .add_button {
      height: 60px;
      width: 60px;
      button {
        &::before {
          @media (min-width: 1700px) {
            content: "";
            z-index: -1;
            background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
            height: 380%;
            width: 300%;
            position: absolute;
            bottom: -80px;
            right: -1px; } } } } } }
