.container_sidebar {
    position: absolute;
    background-color: #010101;
    z-index: 1202;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    border-radius: 30px 0px 30px 0px;
    &.collapsed {
        width: 48px !important;
        padding-left: 6px !important;
        overflow: hidden; }
    .headerSidebar {
        text-align: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        .imgLogo {
            width: 25px;
            margin: 18px auto; }
        div {
            flex: 1; }
        svg {
            position: relative;
            top: 5px; }
        .iconClose {
            flex: inherit;
            width: 42px;
            text-align: right; } }
    .infoContract {
        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 18px); } }
    .transparent p {
        color: transparent !important; }
    .breadcrumb {
        margin: 0px;
        font-size: 15px;
        color: #c9ccda; }
    .nameContract {
        margin: 4px 0px;
        font-size: 16px;
        color: white;
        font-weight: bold;
        text-transform: uppercase; }
    .numRef {
        margin: 0px;
        font-size: 15px;
        color: #757e95;
        text-transform: uppercase; }
    .listMenu {
        // flex: 1
        overflow: auto; }
    .itemMenu {
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
            margin: 0px;
            text-transform: uppercase;
            font-size: 12px;
            color: white; }
        &.itemSelected {
            background-color: white;
            p {
                color: black; } }
        &.itemCollapsed {
            margin-left: -6px; } }
    .buttonBack {
        margin-top: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.collapsedBack {
            margin-left: 3px;
            p {
                color: transparent; } }
        svg {
            margin: 0px 6px;
            position: relative;
            top: 2px; }
        p {
            margin: 0px;
            text-transform: uppercase;
            color: white;
            font-size: 12px;
            opacity: 0.7; } } }
.btShowSidebar {
    position: absolute;
    top: 60px;
    left: 60px;
    background-color: #010101;
    z-index: 1202;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    svg {
        transform: rotate(180deg);
        position: relative;
        left: 9px;
        top: 2px; } }

.moreBackground {
    display: flex;
    z-index: 1201;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255, 0.9);
    .options {
        width: calc(100% - 20px);
        margin-top: auto;
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        padding: 3px 0 2px 1px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        background-color: #fff; } }
