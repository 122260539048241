.container {
    margin-top: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .content_box {
        margin: 10px;
        background-color: #000;
        border-radius: 5px;
        box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
        .box_data, .box_data_small {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            .box_row {
                display: flex;
                margin-bottom: 10px;
                .label {
                    flex-grow: 1;
                    font-family: FuturaPT;
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #fff; }
                .data {
                    flex-grow: 1;
                    font-family: FuturaPT-Light;
                    font-size: 20px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: right;
                    color: #fff; } } }
        .box_data_small {
            padding: 15px;
            .box_row {
                margin-bottom: 5px;
                .label, .data {
                    font-size: 18px; } } } } }
