.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo_border {
    z-index: 2;
    width: 100%;
    height: 100%;
    min-width: 110px;
    min-height: 110px;
    max-width: 166px;
    max-height: 166px;
    border-radius: 50%;
    border: solid 1px #000;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    position: relative;

    .container_upload_photo {
      background-color: #000;
      box-shadow: 4px 3px 19px 0 #8d8d8d;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      position: absolute;
      right: 12px;
      bottom: 0; }

    .container_photo {
      flex: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: 50%;

      .container_loading {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center; }

      .container_image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }


      .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: FuturaPT-Medium;
        font-size: 2em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.9;
        letter-spacing: normal;
        text-align: center;
        color: #fff; } } }

  .container_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    text-align: center;
    color: #000;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    .container_username {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .edit {}

      .names {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 600px) {
          max-width: 266px; }

        @media screen and (min-width: 600px) {
          font-size: 28px; } } }

    .view_profile {
      font-size: 15px;
      font-size: 3vw;
      line-height: 1.27;
      @media screen and (min-width: 600px) {
        font-size: 15px; } } } }
