.grid_container {
  @media (min-width: 701px) {
    max-width: 1920px;
    margin-left: auto !important;
    margin-right: auto !important; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important;
      object-fit: contain;
      border-radius: 5px;
      margin-right: 0px;
      margin-left: 0px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_status {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      font-family: FuturaPT-Light;
      font-size: 14px;
      font-weight: 300;
      padding: 21px 0; } }
  .container_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    .contract_reference {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .contract_name {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } } }
.label_subchart {
  width: 100%;
  background-color: #faf8f8;
  margin: 24px 0px 0px 0px;
  padding: 6px 12px;
  border-radius: 23px;
  background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
  font-family: FuturaPT-Light;
  font-size: 16px;
  text-align: center;
  color: black; }
.container_chart {
  object-fit: contain;
  width: 100%;
  @media (min-width: 701px) {
    height: 250px;
    margin: 0; }
  .title {
    padding: 15px 25px;
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: right; } }

.container_submit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: auto;
  @media (min-width: 701px) {
    margin-bottom: 25px;
    button {
      // margin-left: 30px
      // flex-basis: 100%
      width: 340px !important;
      min-height: 40px !important;
      min-width: 155px !important;
      max-width: 270px !important;
      span {
        min-height: 40px !important; } } }
  .options {
    // flex-basis: 100%
    cursor: pointer;
    height: 100%;
    margin-right: 5px;
    font-family: FuturaPT-Demi;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; }
  .box_two_options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .button {
      display: flex;
      min-width: 155px !important;
      max-width: 270px !important; } }
  .button_alone {
    display: flex;
    flex-grow: 1;
    justify-content: center; } }

.container_terms_item {
  display: flex;
  flex-direction: column;
  padding: 21px 0px;
  align-items: flex-start;
  flex-direction: row;
  .title, .container_data {
    width: 50%; }
  .title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }
  .container_data {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    @media (min-width: 701px) {
      padding: 0; }
    .title {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.75px;
      margin-bottom: 8px; }
    .description {
      font-family: FuturaPT-Light;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.3; }
    .item {
      display: flex;
      .label {
        margin-right: 2px;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
      .label_text {
        font-family: FuturaPT-Light;
        font-size: 20px;
        line-height: 1.3; } } } }

.terms_card {
  display: flex;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  flex-direction: column; }
.terms_card_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .terms_card_title {
    white-space: nowrap;
    font-family: FuturaPT;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.11px;
    text-align: left;
    color: black;
    text-transform: uppercase; }
  .terms_card_action {
    cursor: pointer;
    font-family: FuturaPT-Demi;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: 0.75px;
    text-align: right;
    color: black;
    text-transform: uppercase; } }

.team_card {
  display: flex;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: white;
  box-sizing: border-box;
  border-top: 10px solid black;
  padding: 20px;
  flex-direction: column;
  .team_card_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .team_card_title {
      font-family: FuturaPT;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.11px;
      text-align: left;
      color: black;
      text-transform: uppercase; }
    .team_card_action {
      cursor: pointer;
      font-family: FuturaPT-Demi;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.93;
      letter-spacing: 0.75px;
      text-align: right;
      color: black;
      text-transform: uppercase; } } }

.container_step_description {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 701px) {
    margin-bottom: 30px;
    margin-top: 0px;
    .form_title {
      font-size: 20px;
      font-weight: bold; } } }
.contract_details_card_mobile {
  margin: 10px 0 25px 0;
  overflow: hidden;
  .counterpart_mobile {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    margin-right: 15px; }
  .name_mobile {
    margin-left: 10px;
    font-family: FuturaPT;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black; } }
.contract_details_card {
  margin: 10px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%);
  overflow: hidden;
  .title {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    color: black;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .reference {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  @media (min-width: 701px) {
    display: flex;
    justify-content: space-between;
    .more_button {
      min-width: 30px; } }
  .box_row {
    // background-color: #11f
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .box_personal_data {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .box_email {
        // background-color: #1ff
        font-family: FuturaPT-Light;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .box_photo_name {
        display: flex;
        flex-direction: row;
        align-items: center;
        .box_name {
          // background-color: #1ff
          font-family: FuturaPT;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } } } } }
.box_image {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }
