.grid_container {
  @media (min-width: 701px) {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: auto !important;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin-bottom: -20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff;
        margin-right: 12px; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .box_contract_information {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 20px;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 10px;
      background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
      .title {
        margin-top: 10px;
        font-family: FuturaPT;
        font-size: 16px;
        color: #757e95; }
      .box_row {
        // background-color: #11f
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .box_personal_data {
          display: flex;
          flex-direction: column;
          .box_email {
            // background-color: #1ff
            font-family: FuturaPT-Light;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-left: 25px; }
          .box_photo_name {
            display: flex;
            flex-direction: row;
            align-items: center;
            .box_name {
              // background-color: #1ff
              font-family: FuturaPT;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal; } } } } }
    .box_collaborators {
      // background-color: #4fa
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      .box_item {
        // background-color: #f1f
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .box_row {
          // background-color: #11f
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .box_personal_data {
            display: flex;
            flex-direction: column;
            .box_email {
              // background-color: #1ff
              font-family: FuturaPT-Light;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-left: 25px; }
            .box_photo_name {
              display: flex;
              flex-direction: row;
              align-items: center;
              .box_name {
                // background-color: #1ff
                font-family: FuturaPT;
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal; } } }
          .box_status_data {
            display: flex;
            flex-direction: column;
            .box_status {
              display: flex;
              flex-direction: row;
              align-items: center;
              .box_status_text {
                font-family: FuturaPT;
                font-size: 12px;
                text-align: right; } }
            .box_status_date {
              font-family: FuturaPT;
              font-size: 11px;
              text-align: right;
              color: #757e95; } } } } }
    .title_form {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .subtitle_form {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .container_input {
        margin-top: 0px; }
      .container_names {
        display: flex;
        .container_firstname, .container_lastname {
          flex-grow: 1; }
        .container_lastname {
          margin-left: 17px; } } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_propose_changes {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } }
    .container_submit {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px; } }
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } }
  .container_title2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .first_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #111; } }
    .subtitle {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95; } } }
.box_image {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }
.container_propose_changes {
  // background-color: #f1f
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px !important;
  .button_alone {
    display: flex;
    width: 100%; } }
.title_container {
  display: flex;
  flex-direction: column;
  width: 100%; }
