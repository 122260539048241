.team_card_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .team_card_title {
        white-space: nowrap;
        font-family: FuturaPT;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.11px;
        text-align: left;
        color: black;
        text-transform: uppercase; }
    .team_card_action {
        cursor: pointer;
        font-family: FuturaPT-Demi;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.93;
        letter-spacing: 0.75px;
        text-align: right;
        color: black;
        text-transform: uppercase; } }
.team_card {
    display: flex;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
    background-color: white;
    box-sizing: border-box;
    // border-top: 10px solid black
    padding: 20px;
    flex-direction: column;
    .container_step_description {
        margin: 30px 0px 29px 0px;
        font-family: FuturaPT-Light;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left; }
    .container_step_title {
        margin: 25px 0px 26px 0px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px; }
    .container_inputs {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .container_input {
            margin-top: 0px; }
        .container_names {
            display: flex;
            align-items: center;
            .container_first_field {
                flex-grow: 1;
                flex-basis: 0; }
            .container_last_field {
                margin-left: 17px; } }
        .container_error {
            text-align: center;
            margin: 10px; }
        .container_propose_changes {
            display: flex;
            flex-direction: column;
            .button_alone {
                display: flex;
                flex-grow: 1;
                justify-content: center; } }
        .container_submit {
            display: flex;
            flex-direction: column; } }
    .container_send_action {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        .button_alone {
            display: flex;
            flex-grow: 1;
            justify-content: center; } }
    .list_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        .button {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            margin-top: auto; }
        .added_content {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            .added_list {
                display: flex;
                flex-direction: column;
                .item_box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .data {
                        padding: 20px 0px;
                        display: flex;
                        flex-direction: row;
                        width: calc(100% - 30px);
                        align-items: center;
                        .email {
                            font-family: FuturaPT-Light;
                            font-size: 20px;
                            font-weight: 300;
                            width: 50%;
                            text-overflow: ellipsis;
                            overflow: hidden; }
                        .role {
                            width: 50%; } }
                    .actions {
                        display: flex;
                        flex-direction: row;
                        margin-left: auto; } } } } } }
.modal_content {
    background-color: #fff;
    .container_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .title {
            font-family: FuturaPT-Medium;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.83px;
            text-align: center;
            color: #ffffff; } }
    .container_step_description {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px;
        display: flex;
        align-items: center;
        flex-direction: column; } }
