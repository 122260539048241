.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .container_pages {
    min-height: 100%;
    z-index: 1;
    .page {
      display: flex;
      flex: 1;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%; } } }
  .content_page {
    z-index: 1000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 701px) {
      justify-content: center; }
    .container_logo {
      margin-top: 100px;
 }      // margin-bottom: auto
    .content {
      margin-top: auto;
      margin-bottom: auto;
      @media (min-width: 701px) {
        background-color: #fff;
        margin-top: 53px;
        margin-bottom: 53px;
        border-radius: 5px;
        min-width: 687px !important;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important; }
      .container_step_content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: center;
        margin-bottom: 50px; }
      .container_actions {
        @media (min-width: 701px) {
          padding-top: 0px; }
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        .button {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          width: 270px; } } }
    .container_steps {
      // margin-top: auto
      margin-bottom: 50px;
      @media (min-width: 701px) {
        margin-bottom: 100px; }
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: true;
      pointer-events: true; } } }

.description {
  width: 350px;
  @media (min-width: 701px) {
    width: 480px; } }
