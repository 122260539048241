.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .grid_container {
    @media (min-width: 980px) {
      margin-top: 170px; } }
  .top_bg {
    // background-color: #00FF00
    position: absolute;
    left: 0;
    @media (min-width: 701px) {
      left: 30%; }
    right: 0;
    z-index: -9999;
    user-select: none;
    pointer-events: none;
    display: flex;
    max-height: 240px;
    top: 0;
    img {
      height: 100%;
      width: 100%;
      object-fit: fill; } }
  .top_bg {
    svg {
      width: 100%;
      height: 240px; } }

  .content {
    background-color: #fff;
    margin-top: 170px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 250px !important;
      margin-bottom: auto !important;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important; }

    .container_step_description {
      padding: 21px;
      display: flex;
      align-items: center;
      flex-direction: column; }

    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @media (max-width: 700px) {
        padding-right: 17px;
 }        // background-color: #f1f
      @media (min-width: 701px) {
        padding-left: 113px !important;
        padding-right: 113px !important; }

      .container_input {
        margin-top: 0px;
        & p {
          color: #d70000 !important; } }

      .container_names {
        display: flex;
        .container_firstname, .container_lastname {
          flex-grow: 1; }

        .container_lastname {
          margin-left: 17px; } } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      padding-top: 40px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } } }

.link {
  text-transform: capitalize !important;
  text-decoration: underline !important;
  color: #000 !important; }

.separator {
  margin: 0px 3px; }

.terms {
  font-size: 16px;
  font-weight: 300;
  text-align: center; }
