.grid_container {
  flex-wrap: nowrap !important;
  @media (min-width: 701px) {
    max-width: 1920px;
    margin-left: auto !important;
    margin-right: auto !important; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 10px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      // overflow-y: auto
      margin-bottom: auto !important;
      margin-right: 0px;
      margin-left: 0px;
      padding-bottom: 0px !important; }
    .scroll_hidden {
        /* width */
        &::-webkit-scrollbar {
          display: none; } }
    .list {
      display: flex;
      padding: 20px 10px 20px 10px;
      flex-direction: column;
      position: relative;
      @media (min-width: 701px) {
        margin: 0px 0px 0px -20px;
        padding: 30px 20px 0px 20px;
        max-height: calc(100% - 80px);
        overflow-y: auto; }
      .list_container {
        @media (min-width: 701px) {
          overflow-y: auto;
          max-height: 665px; } }
      .active_item {
        background-color: black;
        color: white; } }
    .loaderList {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px; } }
  .mobile_box_contract {
    margin-top: 20px;
    background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
    min-height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    .label {
      font-family: FuturaPT;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // white-space: nowrap
      text-overflow: ellipsis; } }
  .container_add_button {
    right: 120px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 60px;
    height: 60px;
    bottom: 27px;
    z-index: 1300;
    @media (min-width: 701px) {
      bottom: 80px; }
    .add_button {
      height: 60px;
      width: 60px;
      button {
        &::before {
          @media (min-width: 1700px) {
            content: "";
            z-index: -1;
            background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
            height: 380%;
            width: 300%;
            position: absolute;
            bottom: -130%;
            right: -1px; } } } } } }
.contract_details_card {
  margin: 10px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%);
  overflow: hidden;
  .title {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c9ccda; }
  .reference {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  @media (min-width: 701px) {
    display: flex;
    justify-content: space-between;
    .more_button {
      min-width: 30px; } } }

.detail_column {
  overflow: hidden;
  border: 3px solid black;
  border-radius: 0px 50px 0px 50px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  .close_button {
    position: absolute;
    border-radius: 50%;
    background-color: black;
    width: 32px;
    height: 32px;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 10; }
  .detail_column_container {
    max-height: 665px;
    overflow-y: auto;
    padding: 10px; } }

.taskFooterList {
  z-index: 2;
  position: sticky;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0px;
  min-height: 60px;
  background: black;
  color: white;
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  border-radius: 0px 0px 5px 5px; }

.iconAlert {
  width: 76px !important;
  height: 90px !important;
  margin: 0 auto;
  margin-bottom: -30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

@media screen and (min-width: 980px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px; }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px; } }
