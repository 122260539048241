.grid_container {
  @media (min-width: 701px) {
    width: 700px;
    max-width: 1920px; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important;
      margin-right: 0px;
      margin-left: 0px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .title_form {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .subtitle_form {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .container_input {
        margin-top: 0px; } }
    .container_error {
      text-align: center;
      margin: 10px; } } }
.container_submit {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  .box_two_options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .button {
      display: flex;
      flex-grow: 1;
      min-width: 155px;
      max-width: 270px; } }
  .button_alone {
    display: flex;
    flex-grow: 1;
    justify-content: center; } }
