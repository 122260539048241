.offTakeItem, .offTakeItemActive {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0 10px 0;
    cursor: pointer;
    @media (min-width: 701px) {
        margin: 0px 0 0px 0; }
    .date_range {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        padding-left: 20px;
        .next {
            width: 4px;
            height: 7px;
            margin: 0 17px 0 17px; }
        .date {
            display: flex;
            flex-direction: column;
            .day {
                font-family: FuturaPT-Demi;
                font-size: 25px;
                letter-spacing: 1.25px;
                text-align: center; }
            .month {
                font-family: FuturaPT;
                font-size: 12px;
                letter-spacing: 0.6px;
                text-align: center; }
            .year {
                font-family: FuturaPT;
                font-size: 12px;
                text-align: center;
                color: #757e95; } } }
    .volume {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        padding: 0 10px 0 10px;
        margin-left: 40px;
        width: 100%;
        height: 84px;
        border-radius: 5px;
        box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 white, inset 1px 1px 3px 0 #ebecf0;
        background-image: linear-gradient(289deg, #f4f4f5 118%, white 28%);
        @media (min-width: 701px) {
            box-shadow: none;
            background-image: none; }
        .data {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .label {
                font-family: FuturaPT-Medium;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.75px;
                margin-right: 5px; }
            .text {
                font-family: FuturaPT-Light;
                font-size: 20px;
                font-weight: 500; } }
        .next {
            width: 5px;
            height: 10px;
            margin-left: auto; }
        .status {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 15px;
            left: -18.5px;
            position: absolute;
            .divider {
                position: absolute;
                z-index: 0;
                height: 60%;
                top: 20%;
                background-color: lightgray; }
            .circle {
                z-index: 1;
                width: 15px;
                height: 15px;
                border-radius: 50%; } } } }

.offTakeItemActive {
    background-color: black;
    color: white;
    .date_range {
        svg.next {
            path, g {
                stroke: white;
                opacity: 1; } } }
    .volume {
        box-shadow: none;
        background-image: none;
        background-color: black;
        svg.next {
            path, g {
                stroke: white;
                opacity: 1; } } } }
