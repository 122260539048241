.modal_box_content {
    max-width: 700px;
    padding-top: 76px;
    padding-bottom: 93px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    .title {
        max-width: 471px;
        font-family: FuturaPT-Medium;
        font-size: 25px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.83px;
        text-align: center;
        margin-top: 40px;
        color: white; }
    .description {
        max-width: 471px;
        font-family: FuturaPT;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 40px;
        color: white; }
    .subtitle {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 25px;
        color: white; }
    .items {
        margin-top: 40px;
        margin-bottom: 40px; }
    .item {
        max-width: 320px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 25px; }
    .done_text {
      font-family: FuturaPT;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
    .peding_text {
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
    .buttons_container {
      display: flex;
      justify-content: space-between;
      max-width: 650px;
      margin-bottom: 10px; } }


.close {
    position: absolute;
    top: 28px;
    right: 28px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center; }


.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1299;
  background-color: rgba(255, 255, 255, 0.9);
  .box {
    display: flex;
    justify-content: center;
    position: relative;
    width: 688px;
    height: 633px;
    object-fit: contain;
    border-radius: 5px;
    background-image: linear-gradient(132deg, #757e95 0%, #000000 86%, #000000 86%);
    .background {
        position: absolute;
        z-index: -100;
        width: 100%;
        height: 100%; }
    .box_content {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: center;
        padding-top: 76px;
        padding-bottom: 93px;
        .title {
            width: 471px;
            font-family: FuturaPT-Medium;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1.67px;
            text-align: center;
            color: #ffffff;
            margin-top: 50px;
            margin-bottom: 40px; }
        .item {
            width: 320px;
            display: flex;
            align-items: center;
            font-family: FuturaPT;
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 30px; }
        .button {
            margin-top: 20px;
            width: 170px;
            height: 40px; } }
    .close {
        position: absolute;
        top: 28px;
        right: 28px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center; } } }
