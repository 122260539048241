.container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .background {
        z-index: 1300;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.97; }
    .box {
        max-width: 90%;
        max-height: 90%;
        overflow: hidden;
        z-index: 1300;
        display: flex;
        justify-content: center;
        position: relative;
        object-fit: contain;
        border-radius: 5px;
        .background_image {
            align-self: flex-end;
            position: absolute;
            z-index: -100;
            width: 688px;
            height: 633px;
            bottom: 0px;
            right: 0px; }
        .box_content {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: center;
            overflow: auto; }
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center; } } }
