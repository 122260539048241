.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important; }
    .list {
        display: flex;
        padding: 20px 10px 20px 10px;
        flex-direction: column; } } }
