.main_content {
  box-sizing: border-box;
  max-width: 400px;
  margin-right: 50px !important;
  .content {
    margin-top: 0px;
    height: 100% !important;
    padding: 30px !important;
    margin-left: 0 !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important;
      margin-left: auto;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%); } }
  .name {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    color: black;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .reference {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .buyers {
    object-fit: contain;
    border-top: solid 0.5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding-top: 20px;
    margin-top: 20px;
    .buyersTitle {
      font-family: FuturaPT;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757e95;
      margin-bottom: 5px; }
    .buyersInfoContainer {
      .avatar {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        font-size: 13px; }
      .buyersInfo {
        display: inline-block;
        overflow: hidden;
        .buyersName {
          font-family: FuturaPT;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.89px;
          color: black;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .buyersEmail {
          font-family: FuturaPT;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.89px;
          color: black;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } } } } }
