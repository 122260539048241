.full_item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border-radius: 5px
    position: relative; }
.item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    cursor: 'pointer';
    @media (min-width: 701px) {
        width: 90%;
        padding: 21px 20px 20px 30px;
        object-fit: contain;
        border-radius: 5px;
        background-color: white; }
    .date_container {
        display: flex;
        height: fit-content;
        .date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .tbd {
                font-family: FuturaPT-Demi;
                font-size: 14px;
                letter-spacing: 1.25px;
                text-align: center; }
            .day {
                font-family: FuturaPT-Demi;
                font-size: 25px;
                letter-spacing: 1.25px;
                text-align: center; }
            .month {
                font-family: FuturaPT;
                font-size: 12px;
                letter-spacing: 0.6px;
                text-align: center; }
            .year {
                font-family: FuturaPT;
                font-size: 12px;
                text-align: center;
                color: #757e95; } } }
    .data {
        display: flex;
        flex-direction: column;
        width: 100%;
        .first_row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            .next {
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center; }
            .nomination_info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin-left: 40px;
                .info_row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 5px;
                    margin-top: 5px;
                    .label {
                        margin-right: 5px;
                        font-family: FuturaPT;
                        font-size: 18px;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        white-space: nowrap; }
                    .text {
                        font-family: FuturaPT-Light;
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        white-space: nowrap; } } }
            .task_info {
                display: flex;
                flex-direction: column;
                .task_type {
                    height: 25px;
                    padding: 0 10px 0 10px;
                    border-radius: 23px;
                    border: solid 1px black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: FuturaPT;
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center; }
                .task_description {
                    margin-top: 5px;
                    font-family: FuturaPT-Demi;
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1px; }
                .task_reference {
                    margin-top: 5px;
                    font-family: FuturaPT;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #757e95; }
                .task_collaborators {
                    display: flex;
                    flex-wrap: wrap; } } }
        .second_row {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .requirements {
                display: flex;
                flex-direction: column;
                .task_requirement {
                    font-family: FuturaPT-Medium;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.6px; } } } } }
.task_status {
    margin-right: 20px;
    display: flex;
    font-family: FuturaPT;
    font-size: 14px;
    text-align: right;
    justify-content: center;
    align-items: center;
    text-align: left; }
.box_image {
    background-color: #111111;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 5px; }
.cont_floating_button {
    position: absolute;
    right: -54px;
    top: 0px; }
.floating_button {
    width: 25px;
    padding: 20px 12px;
    border-radius: 24.5px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between; }
.floating_actions {
    position: absolute;
    width: 25px;
    right: -24px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    // height: 110px
    svg {
        position: relative;
        left: 3px;
        top: 2px; }
    button {
        margin: 5px;
        background-color: black;
        width: 30px;
        height: 30px;
        &:hover {
            background-color: black; } } }
.subtasks_container {
    // background-color: red
    position: relative;
    width: 90%;
    padding: 21px 20px 20px 30px;
    object-fit: contain;
    display: flex;
    .timeline {
        // background-color: blue
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle_filled {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: black;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 3px double white; }
        .circle_empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid black; }
        .line_solid {
            &::before {
                content: "";
                margin-top: 22px;
                height: calc(100% + 22px);
                width: 1px;
                position: absolute;
                border-right: 1px solid lightgray; } }
        .line_dashed {
            &::before {
                content: "";
                margin-top: 22px;
                height: calc(100% + 22px);
                width: 1px;
                position: absolute;
                border-right: 1px dashed lightgray; } } }
    .task_container {
        padding: 0 0 40px 40px;
        width: calc(100% - 70px);
        position: relative;
        display: flex;
        flex-direction: column;

        .subtask_first_row {
            display: flex;
            align-items: center;
            .subtask_type {
                height: 25px;
                padding: 0 10px 0 10px;
                margin-right: 10px;
                border-radius: 23px;
                border: solid 1px black;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: FuturaPT;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center; }
            .right_container {
                margin-left: auto;
                display: flex;
                align-items: center;
                .collaborators_container {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    .user_photo {
                        width: 20px;
                        height: 20px;
                        margin-left: -7px; } }
                .assign_task {
                    cursor: pointer;
                    height: 25px;
                    padding: 0 10px 0 10px;
                    margin-right: 10px;
                    border-radius: 23px;
                    border: solid 1px #4c488f;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: FuturaPT;
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #4c488f; } } }
        .subtask_title {
            margin-top: 20px;
            font-family: FuturaPT-Medium;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal; }
        .subtask_description {
            margin-top: 25px;
            font-family: FuturaPT-Light;
            font-size: 18px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            .subtask_description_bold {
                font-family: FuturaPT;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                letter-spacing: normal; } }
        .subtask_subtitle {
            margin-top: 20px;
            font-family: FuturaPT-Medium;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.86;
            letter-spacing: normal; }
        .documents_container {
            display: flex;
            width: calc(100% - 20px);
            overflow-x: scroll;
            padding: 20px 0px 20px 3px;
            margin-left: 0px; }
        .datapoints_container {
            display: flex;
            flex-direction: column;
            .datapoint_container {
                display: flex;
                padding: 10px 0 10px 0;
                align-items: center;
                .container_input {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    .rfp {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-right: 10px;
                        padding-left: 10px;
                        height: 17px;
                        border-radius: 23px;
                        background-color: rgba(76, 72, 143, 0.64);
                        position: absolute;
                        margin-top: 29px;
                        font-family: FuturaPT-Demi;
                        font-size: 10px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: white; } }
                .edit_button {
                    margin-left: 20px;
                    height: 50px;
                    width: 50px; } } }
        .actions_container {
            display: flex;
            justify-content: flex-end;
            margin: 20px;
            .box_two_options {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .button {
                    display: flex;
                    flex-grow: 1;
                    min-width: 155px;
                    max-width: 270px;
                    padding: 0 10px 0 10px; } } }
        .divider {
            bottom: 0px;
            left: 40px;
            position: absolute;
            width: calc(100% - 40px); } } }
.nomination_status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 55px;
    width: calc(100% - 155px);
    margin: 35px 0 0;
    padding: 16px 78px;
    border-radius: 5px;
    background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
.loaderItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2; }

@media screen and (min-width: 980px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px; }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px; } }
