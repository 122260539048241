@import './utils/_mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Khand&display=swap');
@include font-face('FuturaPT', './assets/fonts/FuturaPTBook', null, null, 'otf');
@include font-face('FuturaPT-Demi', './assets/fonts/FuturaPTDemi', null, null, 'otf');
@include font-face('FuturaPT-Medium', './assets/fonts/FuturaPTMedium', null, null, 'otf');
@include font-face('FuturaPT-Light', './assets/fonts/FuturaPTLight', null, null, 'otf');
@include font-face('FuturaPT-LightObl', './assets/fonts/FuturaPTLightOblique', null, null, 'otf');

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html, body {
  height: 100%;
  width: 100%; }

body {

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: FuturaPT, Helvetica !important;
  -webkit-backface-visibility: hidden; }


button {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &:focus {
    outline: 0; } }

* {
  &:focus {
    outline: 0; } }

#root {
  height: 100%; }

.react-swipeable-view-container {
  height: 100%; }

@media screen and (min-width: 980px) {
  .MuiContainer-maxWidthMd {
    max-width: 980px !important; } }

#item-menu,
#product-menu {
  .MuiPaper-root {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35) !important;
    ul > li {
      font-family: FuturaPT;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.37px;
      color: #2f2f2f; } } }

.title_container {
  margin: 0 9px 9px 0;
  font-family: FuturaPT-Demi;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.39px;
  color: #000000;
  @media only screen and (max-width: 600px) {
    margin: 0 0 0 0;
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center; } }
.description_container {
  margin: 9px 0 0;
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: 600px) {
    margin: 11px 0 0 0;
    font-family: FuturaPT-Light;
    font-size: 18px;
    font-weight: 300;
    text-align: center; } }
.subtitle_container {
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: FuturaPT-Medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: center; }
.title {
  font-family: FuturaPT-Medium;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #000000; }
.subtitle {
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  text-align: center; }
.modal_title {
  font-family: FuturaPT-Medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: center; }
.modal_description {
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center; }
.form_title {
  font-family: FuturaPT-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #000000; }
.description {
  font-family: FuturaPT-Light;
  font-size: 20px;
  font-weight: 300;
  text-align: center; }
.description2 {
  font-family: FuturaPT;
  font-size: 20px;
  font-weight: 300;
  text-align: center; }

.error {
  color: #F44336;
  font-size: 12px; }

.MuiFab-primary {
 }  // background-color: #111 !important
.MuiSpeedDialAction-fab {
  color: #fff !important;
  background-color: #111 !important; }
.MuiSpeedDialAction-staticTooltipLabel {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0px !important;
  color: #111;
  text-shadow: 0 2px 4px rgba(162, 162, 162, 0.5);
  font-family: FuturaPT-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.72px;
  text-align: right; }
.MuiContainer-root {
  padding: 0 !important; }
.MuiPickersDateRangePickerInput-root {
  width: 100%; }
.MuiPickersDay-root.Mui-selected {
  background-color: #111 !important; }
.MuiPickersDateRangeDay-dayInsideRangeInterval {
  color: #111 !important; }
.MuiButton-textPrimary {
  color: #111 !important; }
.container_with_drawer {
  margin-left: 299px;
  @media screen and (min-width: 600px) {
    margin-left: 420px; } }
.MuiFilledInput-adornedEnd {
  padding-right: 0px; }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }


/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }
