.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 700px) {
      margin-top: auto !important;
      margin-bottom: auto !important;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important;
      padding-left: 38px !important;
      padding-right: 38px !important; }
    .icon {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .icon {
      svg {
        height: 80px;
        width: 80px; } }

    .container_step_description {
      padding: 21px;
      display: flex;
      align-items: center;
      flex-direction: column; }

    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .container_input {
        margin-top: 21px; } }

    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      padding-top: 40px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } }
  .content {
    margin-top: 100px; } }
