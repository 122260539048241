.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1299;
  background-color: rgba(255, 255, 255, 0.9);
  .box {
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
    backdrop-filter: blur(10px);
    // background-color: #000000
    width: 711px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px;
    max-height: 90%;
    overflow: scroll;
    .actions {
      margin-top: 27px;
      width: 100%;
      height: 100%;
      .container_buttons {
        display: flex;
        .container_button1, .container_button2 {
          flex-grow: 1;
          .button {
            display: flex;
            flex-grow: 1;
            justify-content: center; } }
        .container_button2 {
          margin-left: 17px; } } }

    .title {
      margin-top: 30px;
      font-family: FuturaPT-Medium;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #ffffff; }

    .message {
      white-space: pre-line;
      margin-top: 10px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; } } }
