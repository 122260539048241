.grid_container {
  @media (min-width: 701px) {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 30px !important;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    @media (min-width: 701px) {
      margin-bottom: auto !important;
      margin-left: 0px;
      margin-right: 0px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .infoCounterpart {
        padding: 20px 40px;
        background-image: linear-gradient(to right, white, #DEE0E4);
        width: calc(100% - 40px);
        margin: 0px 0px 36px -20px;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        .userCounterpart {
          display: flex;
          align-items: center;
          flex: 1;
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            overflow: hidden;
            background-color: black; }
          p {
            margin: 0px 0px 0px 12px;
            flex: 1;
            font-size: 18px;
            font-weight: bold; } }
        .statusCounterpart {
          text-align: right;
          margin-bottom: -18px;
          p {
            margin: 0px 0px 6px 0px;
            font-weight: bold;
            font-size: 15px;
            text-transform: uppercase; }
          .iconCounterpart {
            display: flex;
            align-items: center;
            p {
              margin: -9px 0px 6px -12px;
              font-weight: 100;
              font-size: 14px;
              opacity: 0.4; } } } }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .container_contract_type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
    .title_form {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .subtitle_form {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .label_form {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .container_input {
        margin-top: 0px; }
      .container_names {
        display: flex;
        .container_first_field, .container_last_field {
          flex-grow: 1; }
        .container_last_field {
          margin-left: 17px; }
        .submitRow {
          margin-left: 12px;
          width: 60px; } } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px; } }
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } }
    .list_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-top: auto; }
      .added_content {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .title_list {
          text-transform: uppercase; }
        .desc_list {
          text-align: center;
          margin: 12px 0px 24px 0px;
          font-size: 18px; }
        .added_list {
          display: flex;
          flex-direction: column;
          .header_box {
            padding-left: 30px;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .volume {
              width: 70px; }
            .date_range {
              margin-left: 10px; }
            .title {
              font-family: FuturaPT-Medium;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.75px; } }
          .item_box {
            padding-left: 30px;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .volume {
              width: 70px; }
            .date_range {
              margin-left: 10px; }
            .title {
              font-family: FuturaPT-Light;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.75px; }
            .actions {
              display: flex;
              flex-direction: row;
              margin-left: auto;
              padding-right: 20px;
              .lblManage {
                color: black;
                text-transform: uppercase; } } } } } } } }
.container_propose_changes {
  // background-color: #f1f
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px !important;
  .button_alone {
    display: flex;
    width: 100%; } }
.title_container {
  display: flex;
  flex-direction: column;
  width: 100%; }
