.grid_container {
  @media (min-width: 701px) {
    padding-right: 90px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important;
      margin-left: 0px;
      margin-right: 0px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; } } }
