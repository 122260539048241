.grid_container {
  @media (min-width: 701px) {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 30px !important;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    @media (min-width: 701px) {
      margin-bottom: auto !important;
      margin-right: 0px;
      margin-left: 0px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .container_contract_type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
    .title_form {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .subtitle_form {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .label_form {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .container_input {
        margin-top: 0px; }
      .container_names {
        display: flex;
        .container_first_field, .container_last_field {
          flex-grow: 1;
          flex-basis: 0; }
        .container_last_field {
          margin-left: 17px; } } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      // background-color: #f1f
      display: flex;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px;
          padding: 0 10px 0 10px; } }
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } }
    .list_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-top: auto; }
      .added_content {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .added_list {
          display: flex;
          flex-direction: column;
          .item_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            .data {
              padding: 20px;
              display: flex;
              flex-direction: column;
              .label {
                font-family: FuturaPT-Medium;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.75px;
                margin-bottom: 10px; }
              .description {
                font-family: FuturaPT-Light;
                font-size: 20px;
                font-weight: 300;
                line-height: 1.3; }
              .type {
                font-family: FuturaPT;
                font-size: 20px;
                line-height: 1.3; } }
            .actions {
              display: flex;
              flex-direction: row;
              margin-left: auto; } } } } } } }
.container_terms_item {
  display: flex;
  flex-direction: row;
  padding: 21px 0px;
  align-items: flex-start;
  .title {
    width: 40%;
    max-width: 200px; }
  .title {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase; }
  .container_data {
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (min-width: 701px) {
 }      // padding: 0
    .title {
      font-family: FuturaPT-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      margin-bottom: 8px; }
    .description {
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44; }
    .item {
      display: flex;
      .label {
        margin-right: 2px;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
      .label_text {
        font-family: FuturaPT-Light;
        font-size: 20px;
        line-height: 1.3; } } }
  .container_actions {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    justify-content: center;
    align-items: center; } }

.container_propose_changes {
  // background-color: #f1f
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px !important;
  .button_alone {
    display: flex;
    width: 100%; } }
.title_container {
  display: flex;
  flex-direction: column;
  width: 100%; }
.terms_card {
  padding: 0 10px 0 10px; }

.container_actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: auto;
  @media (min-width: 701px) {
    margin-bottom: 25px;
    button {
      // margin-left: 30px
      // flex-basis: 100%
      width: 340px !important;
      min-height: 40px !important;
      min-width: 155px !important;
      max-width: 270px !important;
      span {
        min-height: 40px !important; } } } }
.options {
    // flex-basis: 100%
    cursor: pointer;
    height: 100%;
    margin-right: 5px;
    font-family: FuturaPT-Demi;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; }
