.top_bg {
    // background-color: #00FF00
    position: absolute;
    right: 60%;
    left: 0;
    bottom: 0;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    display: flex;
    img {
        height: 100%;
        width: 100%;
        object-fit: fill; } }
.top_bg {
    svg {
        height: 733px;
        width: 100%; } }
