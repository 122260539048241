.main_content {
  max-width: 400px;
  margin-right: 50px !important; }
.content {
  background-color: #fff;
  margin-top: 0px;
  height: 100% !important;
  margin-left: 0 !important;
  @media (min-width: 701px) {
    margin-top: 40px !important;
    margin-bottom: auto !important;
    margin-left: auto; }
  .spec_item {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    padding: 0 20px 0 20px;
    height: 64px;
    box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
    background-image: linear-gradient(280deg, #f4f4f5 119%, #fff 28%);
    .title {
      margin-right: auto;
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500; }
    @media (min-width: 701px) {
      margin: 0;
      background-image: none;
      .title {
        font-size: 16px; }
      box-shadow: 1px 1px 5px 0 #d4d7df, inset 1px 1px 3px 0 #ebecf0;
      &:first-child {
        border-radius: 5px 5px 0px 0px; }
      &:last-child {
        border-radius: 0px 0px 5px 5px; } } }
  .container_step_description {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px;
    display: flex;
    align-items: center;
    flex-direction: column; }
  .container_submit {
    // background-color: #f1f
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding-top: 40px;
    .box_two_options {
      // background-color: #1f1
      display: flex;
      flex-direction: row;
      justify-content: center;
      .button {
        display: flex;
        flex-grow: 1;
        min-width: 155px;
        max-width: 270px; } }
    .button_alone {
      display: flex;
      flex-grow: 1;
      justify-content: center; } } }
.container_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contract_reference {
    font-family: FuturaPT;
    font-size: 15px;
    text-align: center;
    color: #757e95; }
  .contract_name {
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center; } }

.active {
  background-color: black;
  color: white;
  svg {
    path, g {
      stroke: white;
      opacity: 1; } } }
