.list {
    display: flex;
    margin: 20px 10px 20px 10px;
    flex-direction: column;
    @media (min-width: 701px) {
        overflow-y: auto;
        max-height: 400px;
        padding-top: 0px;
        margin-top: 20px; }
    border-radius: 5px;
    box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
    .title {
        margin: 16px 0 14px 0;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: FuturaPT-Demi;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center; } }
