.list_menu_content {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 265px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    min-height: 225px;
    border-radius: 5px 50px 5px 50px;
    box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
    background-color: #fff;
    .action {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        height: 35px; }
    .list_menu_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        .task_collaborator_content {
            display: flex;
            flex-direction: column;
            height: 100%;
            .task_type_content {
                display: flex;
                flex-grow: 1;
                align-items: center;
                padding-left: 35px;
                padding-right: 28px;
                .icon {
                    width: 21px;
                    height: 21px; }
                .type {
                    margin-left: 13px;
                    font-family: FuturaPT-Medium;
                    font-size: 12px;
                    font-weight: 500;
                    margin-right: auto; }
                .new {
                    border-radius: 15.5px;
                    border: solid 1px #4c488f;
                    padding: 3px 6px;
                    font-family: FuturaPT-Medium;
                    font-size: 9px;
                    font-weight: 500;
                    text-align: center;
                    color: #4c488f; }
                .number {
                    margin-left: 22px;
                    font-family: FuturaPT-Demi;
                    font-size: 20px;
                    letter-spacing: 1px;
                    text-align: right; } } }

        .financier_content {
            display: flex;
            justify-content: space-between;
            height: 100%;
            overflow: hidden;
            .user_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;
                flex-grow: 1;
                padding-bottom: 22px;
                .photo {
                    width: 26px;
                    height: 26px;
                    object-fit: contain;
                    .photo_container {
                        width: 100%;
                        height: 100%; } }
                .status {
                    width: 67px;
                    border-radius: 15.5px;
                    border: solid 1px #111;
                    padding: 3px;
                    font-family: FuturaPT-Medium;
                    font-size: 9px;
                    font-weight: 500;
                    text-align: center; }
                .company {
                    margin-top: 6px;
                    font-family: FuturaPT-Light;
                    font-size: 16px;
                    font-weight: 300;
                    text-align: center;
                    max-width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap; }
                .role {
                    font-family: FuturaPT;
                    font-size: 18px;
                    text-align: center;
                    margin-bottom: 8px; } }
            .center_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 1px;
                .you {
                    margin-top: 6px;
                    font-family: FuturaPT-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.8px;
                    text-align: center; }
                .status {
                    margin-top: 6px;
                    border-radius: 15.5px;
                    border: solid 1px #111;
                    font-family: FuturaPT-Medium;
                    width: 74px;
                    padding: 5px;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center; } } }
        .contract_reference {
            padding: 0 40px 0 40px;
            height: 21px;
            font-family: FuturaPT;
            font-size: 16px;
            text-align: center;
            color: #757e95;
            margin-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }
        .contract_volume {
            padding: 0px 50px;
            height: 23px;
            font-family: FuturaPT-Medium;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.9px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; }
        .sugar_spec {
            height: 21px;
            font-family: FuturaPT;
            font-size: 16px;
            text-align: center; }
        .line {
            margin-top: 15px;
            height: 0.5px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.2); }
        .chart {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            .status {
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25px;
                font-family: FuturaPT-Medium;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                border-radius: 15.5px;
                border: solid 1px #111; }
            .line {
                width: 1px;
                height: 34px;
                margin-bottom: -9px;
                background-color: #111; }
            .time_line {
                position: relative;
                width: 100%;
                margin: 0 30px 0 30px;
                height: 9px;
                border-radius: 4.5px;
                box-shadow: inset 1px 1px 3px 0 #bdc1d1;
                .bar {
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 4.5px;
                    overflow: hidden; }
                .date_label {
                    position: absolute;
                    font-family: FuturaPT;
                    font-size: 10px;
                    text-align: right;
                    margin-top: -14px; }
                .date_line {
                    position: absolute;
                    width: 1px;
                    height: 23px;
                    margin-top: -23px+9px;
                    background-color:#e3e3e3 {} } }
            .labels_box {
                margin-top: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
                padding-top: 20px;
                .received_circle,.nominated_circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 5px 0 10px; }
                .received_circle {
                    background-color: #757e95; }
                .nominated_circle {
                    background-color: #4c488f; }
                .label {
                    font-family: FuturaPT-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    margin: 0 10px 0 5px; } } } } }
//         .first_line
//             margin-top: -10px
//             margin-bottom: 10px
//             display: flex
//             flex-direction: row
//             justify-content: space-between
//             align-items: center
//             .item_date
//                 margin-right: -10px
//                 align-self: flex-end
//                 font-family: FuturaPT
//                 font-size: 13px
//                 font-weight: normal
//                 font-stretch: normal
//                 font-style: normal
//                 line-height: normal
//                 letter-spacing: normal
//                 color: #757e95
//             .item_photo
//                 margin-right: -10px
//                 margin-top: -10px
//                 font-family: FuturaPT
//                 font-size: 13px
//                 font-weight: normal
//                 font-stretch: normal
//                 font-style: normal
//                 line-height: normal
//                 letter-spacing: normal
//                 color: #757e95
//         .item_title
//             font-family: FuturaPT
//             font-size: 15px
//             font-weight: normal
//             font-stretch: normal
//             font-style: normal
//             line-height: normal
//             letter-spacing: normal
//             color: #757e95
//         .item_contract_number
//             font-family: FuturaPT-Medium
//             font-size: 20px
//             font-weight: 500
//             font-stretch: normal
//             font-style: normal
//             line-height: normal
//             letter-spacing: 1px
//         .container_notifications
//             display: flex
//             flex-direction: row
//             padding-top: 10px
//         .item_notification
//             font-family: FuturaPT
//             font-size: 14px
//             font-weight: normal
//             font-stretch: normal
//             font-style: normal
//             line-height: normal
//             letter-spacing: normal
// .box_image
//     background-color: #111111
//     border-radius: 50%
//     width: 20px
//     height: 20px
//     margin-right: 5px
