.grid_container {
  @media (min-width: 701px) {
    padding-left: 6% !important; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    margin-left: 0 !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important;
      margin-left: auto; }
    .spec_item {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: center;
      padding: 0 20px 0 20px;
      margin: 20px 0 20px 0;
      height: 64px;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
      background-image: linear-gradient(280deg, #f4f4f5 119%, #fff 28%);
      @media (min-width: 701px) {
        margin: 0; }
      .title {
        margin-right: auto;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .container_submit {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      margin-top: auto;
      padding-top: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px; } }
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } }
  .container_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .contract_reference {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95; }
    .contract_name {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center; } } }
