.content {
    .container_step_description {
        padding: 21px;
        display: flex;
        align-items: center;
        flex-direction: column; }
    .content_form {
        display: flex;
        justify-content: center;
        .form {
            display: flex;
            flex-direction: column;
            align-items: center; }
        .container_inputs {
            max-width: 350px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding-right: 17px;
            .container_input {
                margin-top: 0px; }
            .container_numbers {
                display: flex; }
            .container_first, .container_following {
                flex-grow: 1; }
            .container_following {
                margin-left: 5px; } }
        .container_error {
            text-align: center;
            margin: 10px; }
        .container_submit {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            .button {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                width: 270px; } } } }
