.box_collaborators {
    background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
    display: flex;
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 27px;
    margin-bottom: 20px;
    .box_state_description {
        display: flex;
        // align-items: center
        // justify-content: center
        .box_text {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .title {
                font-family: FuturaPT;
                font-size: 20px; }
            .title2 {
                font-family: FuturaPT-Light;
                font-size: 20px;
                font-weight: 300; }
            .bold {
                font-family: FuturaPT-Medium;
                font-size: 20px;
                font-weight: 500; }
            .subtitle {
                font-family: FuturaPT;
                font-size: 15px;
                color: #757e95; } } }


    .box_item {
        // background-color: #f1f
        display: flex;
        flex-direction: column;
        .box_row {
            // background-color: #11f
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .box_personal_data {
                display: flex;
                flex-direction: column;
                .box_email {
                    // background-color: #1ff
                    font-family: FuturaPT-Light;
                    font-size: 16px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    margin-left: 25px; }
                .box_photo_name {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .box_name {
                        // background-color: #1ff
                        margin-left: 5px;
                        font-family: FuturaPT;
                        font-size: 16px;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal; } } }
            .box_status_data {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .box_status {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .box_status_text {
                        font-family: FuturaPT;
                        font-size: 12px;
                        text-align: right; } }
                .box_status_date {
                    font-family: FuturaPT;
                    font-size: 11px;
                    text-align: right;
                    color: #757e95; } } } } }
.box_image {
    background-color: #111111;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px; }
