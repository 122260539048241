$drawer-width: 299px;
$bar-height: 50px;
.container_full {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-shadow: -2px 0 8px 0 rgba(195, 195, 195, 0.5); }
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-shadow: -2px 0 8px 0 rgba(195, 195, 195, 0.5); }
.container_page {
  margin-top: 70px;
  height: calc(100% - 70px);
  transition: all 0.2s;
  padding-right: 60px;
  @media (min-width: 1200px) {
    padding-right: 150px; } }
.titleDescriptionCont {
  margin-top: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 600px) {
      margin-top: 0px; }
  .breadcrums {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: FuturaPT;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757e95;
    @media only screen and (max-width: 600px) {
      justify-content: center; } }
  .titleCont {
    margin: 0px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold; }
  .descriptionCont {
    font-weight: 100;
    margin: 12px 0px 0px 0px; } }
.full_bar {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  z-index: 1201;
  position: absolute;
  .first_bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: $bar-height;
    width: 100%;
    padding: 10px 0px;
    .bar {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      max-width: 1920px;
      .left_buttons {
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon_container {
          margin: 0 8px; } }
      .right_buttons {
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .icon_container {
          margin: 0px 8px; }
        .supportButton {
          white-space: nowrap;
          margin: 0px 8px;
          & svg {
            margin-right: 6px;
            font-size: 28px; } }
        .selectLang {
          margin: 0px 3px 0px 6px;
          div, svg {
            color: white;
            box-shadow: none; }
          svg {
            font-size: 16px;
            top: 9px; } } } } }
  .tab_bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $bar-height;
    max-width: 1820px;
    @media (min-width: 701px) {
      width: calc(100% - 100px);
      margin-left: 70px;
      position: absolute;
      bottom: -1px; } }
  .second_bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    // width: 100%
    max-width: 1020px;
    height: $bar-height;
    background-color: transparent !important;
    @media (min-width: 701px) {
      max-width: 1920px;
      box-sizing: border-box;
      padding-left: 0px;
      // padding-right: 50px !important
      height: 100px; }
    .left_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon_container {
        margin: 0px 8px; }
      .breadcrums {
        display: flex;
        align-items: center;
        font-family: FuturaPT-Demi;
        font-size: 16px;
        letter-spacing: 0.8px;
        white-space: nowrap;
        .active {
          padding-left: 10px;
          color: #757e95;
          white-space: nowrap; } } }
    .container_logo {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media (min-width: 701px) {
        align-self: flex-end; }
      .container_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; }
        .subtitle {
          font-family: FuturaPT-Light;
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c9ccda; } } }
    .right_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      @media (min-width: 1700px) {
        margin-right: 260px; }
      .icon_container {
        margin: 0px 8px; } } } }
.container_logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  .container_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.83px;
      text-align: left;
      color: #ffffff; } } }
.drawer {
  display: flex; }

.drawer_content {
  width: $drawer-width; }

.avatar {
  width: 25px !important;
  height: 25px !important;
  font-size: 13px !important; }
.notificationsButton {
  z-index: 1;
  position: relative;
  width: 60px;
  height: 60px;
  border: none;
  cursor: pointer;
  min-width: 60px;
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 50%;
  align-items: center !important;
  background-color: black;
  box-shadow: 4px 3px 19px 0 #8d8d8d, -9px -7px 16px 0 rgba(250, 251, 252, 0.06);
  @media (min-width: 701px) {
    margin-right: 120px; }
  @media (min-width: 1200px) {
    margin-right: 42px; } }
.notificationsButtonLabel {
  display: flex;
  justify-content: center;
  align-items: center; }

.profile_menu_top {
  position: relative;
  top: 85px !important; }
.notifications_menu_top {
  top: 110px !important;
  max-height: 70% !important; }
.notifications_list_margin {
  height: 400px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow: hidden; }
.notifications_menu {
  width: 280px;
  min-width: 280px;
  min-height: min-content;
  height: 100%;
  .date_label {
    font-family: FuturaPT-Medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.89px; }
  .notificationsItemCard {
    cursor: pointer;
    position: relative;
    right: 0px;
    border-radius: 0px;
    box-sizing: border-box;
    width: 100%;
    min-width: auto; }
  .notificationsTabs {
    font-family: FuturaPT-Medium;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
    .tab {
      cursor: pointer;
      font-family: FuturaPT-Medium;
      color: lightgray;
      text-transform: uppercase; }
    .active_tab {
      font-weight: bold;
      color: black;
      border-bottom: 2px solid black; } } }
.profile_menu {
  width: 280px;
  min-width: 280px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  .item, .view_profile {
    font-family: FuturaPT-Medium;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: black;
    cursor: pointer; }
  .view_profile {
    font-size: 12px; }
  .version {
    font-family: FuturaPT-Medium;
    font-size: 15px;
    letter-spacing: 0.75px;
    text-align: center;
    margin-top: 30px; } }
.profile_data_photo_border {
  min-width: 80px !important;
  min-height: 80px !important; }
.upload_photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px !important;
  height: 24px !important;
  right: 0px !important;
  label {
    display: flex; }
  svg {
    width: 16px; } }

.notificationsComponent {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 115px;
  top: 85px;
  max-height: calc(100vh - 120px);
  width: fit-content;
  @media (min-width: 1200px) {
    right: 35px; }
  @media (max-width: 1200px) {
    display: none; }
  &::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    border-right: 1px solid lightgray; }
  .fade {
    z-index: 1;
    background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
    height: 220px;
    width: 280px;
    position: absolute;
    bottom: 0; }
  .notificationsItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
    &:last-child {
      .notificationsItemContainer {
        &:last-child {
          margin-bottom: 0px !important; } } }
    .notificationsItemTitle {
      position: relative;
      margin: 20px 0px;
      left: 0px;
      background-color: white;
      font-family: FuturaPT-Medium;
      color: #8991A5;
      min-width: 70px;
      text-align: center;
      &:first-child {
        margin-top: 30px; } }
    .notificationsItemContainer {
      margin: 20px 0px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0px;
      .notificationsItemIcon, .notificationsItemIconPush {
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        svg {
          width: 16px !important; } }
      .notificationsItemIconPush {
        &::before {
          position: absolute;
          content: "";
          width: 26px;
          height: 26px;
          background-color: black;
          border-radius: 50%;
          animation: push-icon 3s infinite; } }

      .notificationsItemText {
        text-transform: uppercase;
        position: absolute;
        right: 45px;
        text-align: right;
        font-family: FuturaPT-Medium;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: black; } } } }
.notificationsItemCard, .notificationsItemCardTimeLine {
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  display: flex;
  min-width: 300px;
  height: 90px;
  right: 45px;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  background-color: white;
  .avatar {
    margin: 0px 10px 0 10px; }
  .notificationsItemCardInfo {
    cursor: pointer;
    width: 100%;
    .contractName {
      font-family: FuturaPT-Medium;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.6px;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      margin-bottom: 2px;
      .time {
        font-family: FuturaPT;
        font-size: 10px;
        text-align: right;
        color: #757e95; } }
    .name {
      font-family: FuturaPT-Light;
      font-size: 16px;
      font-weight: 300;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; }
    .description {
      font-family: FuturaPT-Light;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.25;
      display: flex;
      justify-content: space-between; } } }
.notificationsItemCardTimeLine {
  padding-right: 30px;
  padding-top: 15px;
  right: 15px;
  box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
  border: solid 0.3px #eaeaea;
  background-image: linear-gradient(132deg, rgba(255,255,255,1) 28%, rgba(244,244,245,1) 119%); }
@keyframes push-icon {
  0% {
    background-color: black; }
  50% {
    background-color: transparent; }
  100% {
    background-color: black; } }
