.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .content {
    background-color: #fff;
    margin-top: 70px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: auto !important;
      margin-bottom: auto !important;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important; }

    .container_personal_information {
      margin-top: 20px;
      margin-bottom: 50px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 0;

      .container_profile_data {

        width: 130px;
        height: 130px; }

      .container_data {
        z-index: 2;
        text-align: center;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;

        .names {
          font-size: 28px;
          line-height: 1.61;
          text-transform: capitalize; }

        .email {
          font-size: 15px;
          line-height: 1.27; } } }

    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;

      .container_input {
        margin-top: 0px; }

      .container_names {
        display: flex;
        .container_firstname, .container_lastname {
          flex-grow: 1; }

        .container_lastname {
          margin-left: 17px; } } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      display: flex;
      padding-top: 40px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } } }


.title, .edit {
  font-family: FuturaPT-Medium;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: black; }
.edit {
  font-size: 18px;
  cursor: pointer;
  min-width: 23px;
  width: 23px; }
