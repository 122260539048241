.container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .photo_border {
        background-color: white;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: solid 1px #000;
        padding: 1%;
        box-sizing: border-box;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 1px;
        .container_photo {
            flex: 1;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #000;
            border-radius: 50%;
            overflow: hidden;
            position: relative; }
        .container_loading {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center; }
        .container_image {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; } }
        .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: FuturaPT-Medium;
            font-size: 0.5em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: normal;
            text-align: center;
            color: #fff; } }
    .photo_container {
        width: 100%;
        height: 100%; } }
