.grid_container {
  @media (min-width: 701px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      // margin-top: 120px !important
      margin-bottom: auto !important;
      margin-left: 0px;
      margin-right: 0px; }
    .confirmation_form_card {
      @media (min-width: 701px) {
        padding-top: 35px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 40px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .container_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 30px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        text-align: center; }
      .title_pledge {
        font-family: FuturaPT;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: 1px;
        text-align: center; }
      .contract_pledge {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        text-align: center; } }
    .container_agreements {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-right: 17px;
      font-family: FuturaPT-Light;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left; }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .container_input {
        margin-top: 0px; } }
    .container_error {
      text-align: center;
      margin: 10px; }
    .container_submit {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px; } } }
    .box_item {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      .box_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .box_user_data {
          display: flex;
          flex-direction: column;
          .box_name {
            font-family: FuturaPT;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal; }
          .box_email {
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal; } } } } } }
