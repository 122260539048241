.chart {
    max-width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .emptyIcon {
        width: 90px;
        margin: - 60px 0px 12px 60px; }
    .haventDates {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase; }
    .btnAddDate {
        font-family: FuturaPT-Demi;
        font-size: 15px;
        font-weight: normal;
        text-align: center;
        color: black;
        text-transform: uppercase;
        cursor: pointer; }
    .status {
        width: fit-content;
        padding: 0px 18px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        font-size: 12px;
        text-align: center;
        border-radius: 15.5px;
        white-space: nowrap;
        background-color: rgba(76, 72, 143, 0.64);
        color: white;
        font-family: FuturaPT-Medium;
        margin-bottom: 12px;
        text-transform: uppercase; }
    .contStatus {
        margin-bottom: 120px;
        margin-top: -30px;
        width: 100%; }
    .lblStatus {
        font-family: FuturaPT-Medium;
        font-size: 24px;
        font-weight: 500;
        color: black;
        text-transform: uppercase; }
    .subLblStatus {
        display: flex;
        align-items: center;
        color: #757e95;
        svg {
            color: #757e95;
            margin-right: 6px; } }
    .time_line {
        position: relative;
        margin: 0 30px 0 30px;
        height: 9px;
        border-radius: 4.5px;
        margin-bottom: 12px;
        .bar {
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 4.5px;
            overflow: hidden;
            background-color: #F3F4F5;
            box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
        .date_label {
            position: absolute;
            font-family: FuturaPT;
            font-size: 10px;
            text-align: right;
            margin-top: -14px; }
        .date_line {
            position: absolute;
            width: 1px;
            height: 23px;
            margin-top: -23px+9px;
            background-color:#e3e3e3 {} } }
    .labels_box {
        margin-top: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;
        .label_item {
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            margin-left: 5px;
            position: relative;
            .label_box {
                display: flex;
                flex-direction: row;
                .received_circle,.nominated_circle,.white_circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin: 0 5px; }
                .received_circle {
                    background-color: #757e95; }
                .nominated_circle {
                    background-color: #4c488f; }
                .white_circle {
                    background-color: #F3F4F5;
                    box-shadow: inset 1px 1px 3px 0 #bdc1d1; } }
            .label {
                font-family: FuturaPT-Medium;
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
                margin: 0px 3px; } } }
    .labels_box2 {
        // justify-content: space-between
        width: 80%;
        margin-top: 30px; }
    .priceLabel {
        position: absolute;
        bottom: -24px;
        color: #757e95;
        font-family: FuturaPT-Medium;
        font-size: 16px;
        width: 100%;
        text-align: center; }
    .flexRow {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        border-radius: 0px 0px 0px 0px;
        margin-left: 0;
        width: 100%;
        div {
            height: 100%;
            border-radius: 12px;
            position: absolute;
            left: 0;
            top: 0; } }
    .rowDates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        height: 14px;
        .dateInRow, .dateInRowFloat {
            font-family: FuturaPT-Light;
            font-size: 13px;
            color: black;
            position: relative;
            span {
                position: absolute;
                top: -12px;
                font-family: FuturaPT-Medium;
                font-size: 13px;
                white-space: nowrap; }
            .lastRow {
                right: 0px; } }
        .dateInRowFloat {
            position: absolute;
            z-index: 1; }
        .lineTask {
            position: absolute;
            border-left: 2px solid #e3e3e3;
            height: 29px;
            width: 1px;
            left: -3px;
            top: 0px; } }
    .contLine {
        position: absolute;
        bottom: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        margin-left: -5px; }
    .justLeft {
        align-items: flex-start;
        margin-left: 0px; }
    .justRight {
        align-items: flex-end; }
    .lblToday {
        font-size: 18px;
        position: absolute;
        font-family: FuturaPT-Medium;
        white-space: nowrap;
        top: -42px;
        text-transform: uppercase; }
    .dateToday {
        font-size: 11px;
        position: absolute;
        white-space: nowrap;
        top: -22px;
        text-transform: uppercase; }
    .circleLine {
        width: 3px;
        height: 3px;
        border: 2px solid black;
        border-radius: 50%; }
    .lineDate {
        height: 55px;
        width: 1px;
        border-left: 2px solid black; } }
