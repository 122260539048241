.grid_container {
  @media (min-width: 701px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-bottom: auto !important;
      margin-right: 0px;
      margin-left: 0px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
    .container_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 30px 20px;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; } }
    .container_step_description {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column; }
    .container_contract_type {
      margin-top: 50px;
      margin-bottom: -30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
    .title_form {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .subtitle_form {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .label_form {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; } } }
.container_inputs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: 17px;
  .container_checkbox {
    background-image: linear-gradient(83deg, rgba(255,255,255,1) 0%, rgba(117,126,149,1) 323%);
    margin-left: -20px;
    margin-right: -40px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 40px 20px 20px;
    .checkbox {
      display: flex;
      align-items: center;
      margin: 0 0 0 10px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; } }
  .add_counterpart {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: FuturaPT;
    font-size: 18px; }
  .container_input {
    margin-top: 0px; }
  .container_names {
    display: flex;
    .container_first_field, .container_last_field {
      flex-grow: 1;
      flex-basis: 0; }
    .container_last_field {
      margin-left: 17px; } } }
.container_error {
  text-align: center;
  margin: 10px; }
.container_propose_changes {
  // background-color: #f1f
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .button_alone {
    display: flex;
    flex-grow: 1;
    justify-content: center; } }
.container_submit {
  // background-color: #f1f
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
  .box_two_options {
    // background-color: #1f1
    display: flex;
    flex-direction: row;
    justify-content: center;
    .button {
      display: flex;
      flex-grow: 1;
      min-width: 155px;
      max-width: 270px; } }
  .button_alone {
    display: flex;
    flex-grow: 1;
    justify-content: center; } }
.modal_box_content {
    // width: 700px
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    .title {
        // width: 471px
        font-family: FuturaPT-Medium;
        font-size: 25px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.83px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 7px;
        color: white; }
    .description {
        // width: 471px
        font-family: FuturaPT;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 40px;
        color: white; }
    .subtitle {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 25px;
        color: white; }
    .item {
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 25px; }
    .done_text {
      font-family: FuturaPT;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
    .peding_text {
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
    .buttons_container {
      display: flex;
      justify-content: space-between;
      // width: 650px
      margin-bottom: 10px; } }
