@import url(https://fonts.googleapis.com/css?family=Khand&display=swap);
@font-face {
  font-family: "FuturaPT";
  src: url(/static/media/FuturaPTBook.99541f31.otf) format("opentype"); }

@font-face {
  font-family: "FuturaPT-Demi";
  src: url(/static/media/FuturaPTDemi.5b6575cc.otf) format("opentype"); }

@font-face {
  font-family: "FuturaPT-Medium";
  src: url(/static/media/FuturaPTMedium.bd99f124.otf) format("opentype"); }

@font-face {
  font-family: "FuturaPT-Light";
  src: url(/static/media/FuturaPTLight.a15258a7.otf) format("opentype"); }

@font-face {
  font-family: "FuturaPT-LightObl";
  src: url(/static/media/FuturaPTLightOblique.a1a5c08b.otf) format("opentype"); }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html, body {
  height: 100%;
  width: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: FuturaPT, Helvetica !important;
  -webkit-backface-visibility: hidden; }

button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  button:focus {
    outline: 0; }

*:focus {
  outline: 0; }

#root {
  height: 100%; }

.react-swipeable-view-container {
  height: 100%; }

@media screen and (min-width: 980px) {
  .MuiContainer-maxWidthMd {
    max-width: 980px !important; } }

#item-menu .MuiPaper-root,
#product-menu .MuiPaper-root {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35) !important; }
  #item-menu .MuiPaper-root ul > li,
  #product-menu .MuiPaper-root ul > li {
    font-family: FuturaPT;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.37px;
    color: #2f2f2f; }

.title_container {
  margin: 0 9px 9px 0;
  font-family: FuturaPT-Demi;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.39px;
  color: #000000; }
  @media only screen and (max-width: 600px) {
    .title_container {
      margin: 0 0 0 0;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center; } }

.description_container {
  margin: 9px 0 0;
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }
  @media only screen and (max-width: 600px) {
    .description_container {
      margin: 11px 0 0 0;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      text-align: center; } }

.subtitle_container {
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: FuturaPT-Medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: center; }

.title {
  font-family: FuturaPT-Medium;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #000000; }

.subtitle {
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  text-align: center; }

.modal_title {
  font-family: FuturaPT-Medium;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: center; }

.modal_description {
  font-family: FuturaPT-Light;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center; }

.form_title {
  font-family: FuturaPT-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #000000; }

.description {
  font-family: FuturaPT-Light;
  font-size: 20px;
  font-weight: 300;
  text-align: center; }

.description2 {
  font-family: FuturaPT;
  font-size: 20px;
  font-weight: 300;
  text-align: center; }

.error {
  color: #F44336;
  font-size: 12px; }

.MuiSpeedDialAction-fab {
  color: #fff !important;
  background-color: #111 !important; }

.MuiSpeedDialAction-staticTooltipLabel {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0px !important;
  color: #111;
  text-shadow: 0 2px 4px rgba(162, 162, 162, 0.5);
  font-family: FuturaPT-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.72px;
  text-align: right; }

.MuiContainer-root {
  padding: 0 !important; }

.MuiPickersDateRangePickerInput-root {
  width: 100%; }

.MuiPickersDay-root.Mui-selected {
  background-color: #111 !important; }

.MuiPickersDateRangeDay-dayInsideRangeInterval {
  color: #111 !important; }

.MuiButton-textPrimary {
  color: #111 !important; }

.container_with_drawer {
  margin-left: 299px; }
  @media screen and (min-width: 600px) {
    .container_with_drawer {
      margin-left: 420px; } }

.MuiFilledInput-adornedEnd {
  padding-right: 0px; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.styles_container_loader__p7G_3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.logo_container_logo__SSqCo {
  display: -webkit-flex;
  display: flex;
  min-height: 60px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .logo_container_logo__SSqCo svg {
    width: 145px;
    height: 20px; }

.styles_container__Uo1E3 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__Uo1E3 .styles_container_pages__2tYSw {
    min-height: 100%;
    z-index: 1; }
    .styles_container__Uo1E3 .styles_container_pages__2tYSw .styles_page__2fgQN {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1;
      height: 100%; }
      .styles_container__Uo1E3 .styles_container_pages__2tYSw .styles_page__2fgQN img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
  .styles_container__Uo1E3 .styles_content_page__xykBC {
    z-index: 1000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    @media (min-width: 701px) {
      .styles_container__Uo1E3 .styles_content_page__xykBC {
        -webkit-justify-content: center;
                justify-content: center; } }
    .styles_container__Uo1E3 .styles_content_page__xykBC .styles_container_logo__3Xkok {
      margin-top: 100px; }
    .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD {
      margin-top: auto;
      margin-bottom: auto; }
      @media (min-width: 701px) {
        .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD {
          background-color: #fff;
          margin-top: 53px;
          margin-bottom: 53px;
          border-radius: 5px;
          min-width: 687px !important;
          max-width: 687px !important;
          box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
          padding-top: 42px !important;
          padding-bottom: 52px !important; } }
      .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD .styles_container_step_content__b3Xbv {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: stretch;
                justify-content: stretch;
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 50px; }
      .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD .styles_container_actions__1KEal {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
        @media (min-width: 701px) {
          .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD .styles_container_actions__1KEal {
            padding-top: 0px; } }
        .styles_container__Uo1E3 .styles_content_page__xykBC .styles_content__34NiD .styles_container_actions__1KEal .styles_button__k2kQ6 {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center;
          width: 270px; }
    .styles_container__Uo1E3 .styles_content_page__xykBC .styles_container_steps__1H2w0 {
      margin-bottom: 50px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-user-select: true;
          -ms-user-select: true;
              user-select: true;
      pointer-events: true; }
      @media (min-width: 701px) {
        .styles_container__Uo1E3 .styles_content_page__xykBC .styles_container_steps__1H2w0 {
          margin-bottom: 100px; } }

.styles_description__1V8Bk {
  width: 350px; }
  @media (min-width: 701px) {
    .styles_description__1V8Bk {
      width: 480px; } }

.styles_container__3u7I8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .styles_container__3u7I8 .styles_photo_border__1iLRt {
    z-index: 2;
    width: 100%;
    height: 100%;
    min-width: 110px;
    min-height: 110px;
    max-width: 166px;
    max-height: 166px;
    border-radius: 50%;
    border: solid 1px #000;
    padding: 6px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    position: relative; }
    .styles_container__3u7I8 .styles_photo_border__1iLRt .styles_container_upload_photo__2020i {
      background-color: #000;
      box-shadow: 4px 3px 19px 0 #8d8d8d;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      position: absolute;
      right: 12px;
      bottom: 0; }
    .styles_container__3u7I8 .styles_photo_border__1iLRt .styles_container_photo__1sS_f {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: 50%; }
      .styles_container__3u7I8 .styles_photo_border__1iLRt .styles_container_photo__1sS_f .styles_container_loading__2Ngom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
      .styles_container__3u7I8 .styles_photo_border__1iLRt .styles_container_photo__1sS_f .styles_container_image__1a32h img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .styles_container__3u7I8 .styles_photo_border__1iLRt .styles_container_photo__1sS_f .styles_initials__398NS {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        font-family: FuturaPT-Medium;
        font-size: 2em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.9;
        letter-spacing: normal;
        text-align: center;
        color: #fff; }
  .styles_container__3u7I8 .styles_container_data__2i8b5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 2;
    text-align: center;
    color: #000;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal; }
    .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_container_username__3B0sL {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_container_username__3B0sL .styles_names__3UCbm {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        @media screen and (max-width: 600px) {
          .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_container_username__3B0sL .styles_names__3UCbm {
            max-width: 266px; } }
        @media screen and (min-width: 600px) {
          .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_container_username__3B0sL .styles_names__3UCbm {
            font-size: 28px; } }
    .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_view_profile__2b1eR {
      font-size: 15px;
      font-size: 3vw;
      line-height: 1.27; }
      @media screen and (min-width: 600px) {
        .styles_container__3u7I8 .styles_container_data__2i8b5 .styles_view_profile__2b1eR {
          font-size: 15px; } }

.styles_container_full__11CVp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-shadow: -2px 0 8px 0 rgba(195, 195, 195, 0.5); }

.styles_container__345gb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-shadow: -2px 0 8px 0 rgba(195, 195, 195, 0.5); }

.styles_container_page__3D-oD {
  margin-top: 70px;
  height: calc(100% - 70px);
  transition: all 0.2s;
  padding-right: 60px; }
  @media (min-width: 1200px) {
    .styles_container_page__3D-oD {
      padding-right: 150px; } }

.styles_titleDescriptionCont__h7Ud1 {
  margin-top: 30px;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  @media only screen and (max-width: 600px) {
    .styles_titleDescriptionCont__h7Ud1 {
      margin-top: 0px; } }
  .styles_titleDescriptionCont__h7Ud1 .styles_breadcrums__393Zt {
    margin-bottom: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    font-family: FuturaPT;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757e95; }
    @media only screen and (max-width: 600px) {
      .styles_titleDescriptionCont__h7Ud1 .styles_breadcrums__393Zt {
        -webkit-justify-content: center;
                justify-content: center; } }
  .styles_titleDescriptionCont__h7Ud1 .styles_titleCont__20Iv2 {
    margin: 0px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold; }
  .styles_titleDescriptionCont__h7Ud1 .styles_descriptionCont__3CXhq {
    font-weight: 100;
    margin: 12px 0px 0px 0px; }

.styles_full_bar__405mv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  z-index: 1201;
  position: absolute; }
  .styles_full_bar__405mv .styles_first_bar__1HWjT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    height: 50px;
    width: 100%;
    padding: 10px 0px; }
    .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 {
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      height: 100%;
      width: 100%;
      max-width: 1920px; }
      .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_left_buttons__31xa4 {
        z-index: 10;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center; }
        .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_left_buttons__31xa4 .styles_icon_container__39fR4 {
          margin: 0 8px; }
      .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV {
        z-index: 10;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
        .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_icon_container__39fR4 {
          margin: 0px 8px; }
        .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_supportButton__Ffcic {
          white-space: nowrap;
          margin: 0px 8px; }
          .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_supportButton__Ffcic svg {
            margin-right: 6px;
            font-size: 28px; }
        .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_selectLang__2im4Z {
          margin: 0px 3px 0px 6px; }
          .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_selectLang__2im4Z div, .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_selectLang__2im4Z svg {
            color: white;
            box-shadow: none; }
          .styles_full_bar__405mv .styles_first_bar__1HWjT .styles_bar__l3Uq3 .styles_right_buttons__3ERUV .styles_selectLang__2im4Z svg {
            font-size: 16px;
            top: 9px; }
  .styles_full_bar__405mv .styles_tab_bar__2ugQ9 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 50px;
    max-width: 1820px; }
    @media (min-width: 701px) {
      .styles_full_bar__405mv .styles_tab_bar__2ugQ9 {
        width: calc(100% - 100px);
        margin-left: 70px;
        position: absolute;
        bottom: -1px; } }
  .styles_full_bar__405mv .styles_second_bar__3i3ce {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
    height: 50px;
    background-color: transparent !important; }
    @media (min-width: 701px) {
      .styles_full_bar__405mv .styles_second_bar__3i3ce {
        max-width: 1920px;
        box-sizing: border-box;
        padding-left: 0px;
        height: 100px; } }
    .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_left_buttons__31xa4 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; }
      .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_left_buttons__31xa4 .styles_icon_container__39fR4 {
        margin: 0px 8px; }
      .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_left_buttons__31xa4 .styles_breadcrums__393Zt {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        font-family: FuturaPT-Demi;
        font-size: 16px;
        letter-spacing: 0.8px;
        white-space: nowrap; }
        .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_left_buttons__31xa4 .styles_breadcrums__393Zt .styles_active__2WZeP {
          padding-left: 10px;
          color: #757e95;
          white-space: nowrap; }
    .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_container_logo__36isA {
      position: absolute;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      @media (min-width: 701px) {
        .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_container_logo__36isA {
          -webkit-align-self: flex-end;
                  align-self: flex-end; } }
      .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_container_logo__36isA .styles_container_title__3yvCs {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center; }
        .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_container_logo__36isA .styles_container_title__3yvCs .styles_title__3iZPw {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; }
        .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_container_logo__36isA .styles_container_title__3yvCs .styles_subtitle__2ADHh {
          font-family: FuturaPT-Light;
          font-size: 18px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c9ccda; }
    .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_right_buttons__3ERUV {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media (min-width: 1700px) {
        .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_right_buttons__3ERUV {
          margin-right: 260px; } }
      .styles_full_bar__405mv .styles_second_bar__3i3ce .styles_right_buttons__3ERUV .styles_icon_container__39fR4 {
        margin: 0px 8px; }

.styles_container_logo__36isA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .styles_container_logo__36isA .styles_container_title__3yvCs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center; }
    .styles_container_logo__36isA .styles_container_title__3yvCs .styles_title__3iZPw {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.83px;
      text-align: left;
      color: #ffffff; }

.styles_drawer__uZuDN {
  display: -webkit-flex;
  display: flex; }

.styles_drawer_content__1fi3U {
  width: 299px; }

.styles_avatar__BJTwf {
  width: 25px !important;
  height: 25px !important;
  font-size: 13px !important; }

.styles_notificationsButton__2HdLN {
  z-index: 1;
  position: relative;
  width: 60px;
  height: 60px;
  border: none;
  cursor: pointer;
  min-width: 60px;
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 50%;
  -webkit-align-items: center !important;
          align-items: center !important;
  background-color: black;
  box-shadow: 4px 3px 19px 0 #8d8d8d, -9px -7px 16px 0 rgba(250, 251, 252, 0.06); }
  @media (min-width: 701px) {
    .styles_notificationsButton__2HdLN {
      margin-right: 120px; } }
  @media (min-width: 1200px) {
    .styles_notificationsButton__2HdLN {
      margin-right: 42px; } }

.styles_notificationsButtonLabel__28BG5 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.styles_profile_menu_top__3rrMI {
  position: relative;
  top: 85px !important; }

.styles_notifications_menu_top__1OMkH {
  top: 110px !important;
  max-height: 70% !important; }

.styles_notifications_list_margin__2AGDW {
  height: 400px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow: hidden; }

.styles_notifications_menu__3ewFd {
  width: 280px;
  min-width: 280px;
  min-height: -webkit-min-content;
  min-height: min-content;
  height: 100%; }
  .styles_notifications_menu__3ewFd .styles_date_label__1XX10 {
    font-family: FuturaPT-Medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.89px; }
  .styles_notifications_menu__3ewFd .styles_notificationsItemCard__3xNtJ {
    cursor: pointer;
    position: relative;
    right: 0px;
    border-radius: 0px;
    box-sizing: border-box;
    width: 100%;
    min-width: auto; }
  .styles_notifications_menu__3ewFd .styles_notificationsTabs__GqiGF {
    font-family: FuturaPT-Medium;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px; }
    .styles_notifications_menu__3ewFd .styles_notificationsTabs__GqiGF .styles_tab__1cEqI {
      cursor: pointer;
      font-family: FuturaPT-Medium;
      color: lightgray;
      text-transform: uppercase; }
    .styles_notifications_menu__3ewFd .styles_notificationsTabs__GqiGF .styles_active_tab__396ro {
      font-weight: bold;
      color: black;
      border-bottom: 2px solid black; }

.styles_profile_menu__3kgXS {
  width: 280px;
  min-width: 280px;
  min-height: 350px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .styles_profile_menu__3kgXS .styles_item__1bxPZ, .styles_profile_menu__3kgXS .styles_view_profile__27zio {
    font-family: FuturaPT-Medium;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: black;
    cursor: pointer; }
  .styles_profile_menu__3kgXS .styles_view_profile__27zio {
    font-size: 12px; }
  .styles_profile_menu__3kgXS .styles_version__Tj50t {
    font-family: FuturaPT-Medium;
    font-size: 15px;
    letter-spacing: 0.75px;
    text-align: center;
    margin-top: 30px; }

.styles_profile_data_photo_border__2kmSh {
  min-width: 80px !important;
  min-height: 80px !important; }

.styles_upload_photo__35Opt {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 24px !important;
  height: 24px !important;
  right: 0px !important; }
  .styles_upload_photo__35Opt label {
    display: -webkit-flex;
    display: flex; }
  .styles_upload_photo__35Opt svg {
    width: 16px; }

.styles_notificationsComponent__JCcow {
  z-index: 1;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  right: 115px;
  top: 85px;
  max-height: calc(100vh - 120px);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  @media (min-width: 1200px) {
    .styles_notificationsComponent__JCcow {
      right: 35px; } }
  @media (max-width: 1200px) {
    .styles_notificationsComponent__JCcow {
      display: none; } }
  .styles_notificationsComponent__JCcow::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    border-right: 1px solid lightgray; }
  .styles_notificationsComponent__JCcow .styles_fade__1vVZ7 {
    z-index: 1;
    background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
    height: 220px;
    width: 280px;
    position: absolute;
    bottom: 0; }
  .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    left: 0px; }
    .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv:last-child .styles_notificationsItemContainer__tzEeY:last-child {
      margin-bottom: 0px !important; }
    .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemTitle__1iEUf {
      position: relative;
      margin: 20px 0px;
      left: 0px;
      background-color: white;
      font-family: FuturaPT-Medium;
      color: #8991A5;
      min-width: 70px;
      text-align: center; }
      .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemTitle__1iEUf:first-child {
        margin-top: 30px; }
    .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY {
      margin: 20px 0px;
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      left: 0px; }
      .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemIcon__2RFzw, .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemIconPush__2qwBW {
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        border: 1px solid black; }
        .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemIcon__2RFzw svg, .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemIconPush__2qwBW svg {
          width: 16px !important; }
      .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemIconPush__2qwBW::before {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        background-color: black;
        border-radius: 50%;
        -webkit-animation: styles_push-icon__2vjJh 3s infinite;
                animation: styles_push-icon__2vjJh 3s infinite; }
      .styles_notificationsComponent__JCcow .styles_notificationsItem___PPwv .styles_notificationsItemContainer__tzEeY .styles_notificationsItemText__3Dqlk {
        text-transform: uppercase;
        position: absolute;
        right: 45px;
        text-align: right;
        font-family: FuturaPT-Medium;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: black; }

.styles_notificationsItemCard__3xNtJ, .styles_notificationsItemCardTimeLine__h2bOW {
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  min-width: 300px;
  height: 90px;
  right: 45px;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  background-color: white; }
  .styles_notificationsItemCard__3xNtJ .styles_avatar__BJTwf, .styles_notificationsItemCardTimeLine__h2bOW .styles_avatar__BJTwf {
    margin: 0px 10px 0 10px; }
  .styles_notificationsItemCard__3xNtJ .styles_notificationsItemCardInfo__2tsif, .styles_notificationsItemCardTimeLine__h2bOW .styles_notificationsItemCardInfo__2tsif {
    cursor: pointer;
    width: 100%; }
    .styles_notificationsItemCard__3xNtJ .styles_notificationsItemCardInfo__2tsif .styles_contractName__3hsut, .styles_notificationsItemCardTimeLine__h2bOW .styles_notificationsItemCardInfo__2tsif .styles_contractName__3hsut {
      font-family: FuturaPT-Medium;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.6px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      text-transform: uppercase;
      margin-bottom: 2px; }
      .styles_notificationsItemCard__3xNtJ .styles_notificationsItemCardInfo__2tsif .styles_contractName__3hsut .styles_time__1s__b, .styles_notificationsItemCardTimeLine__h2bOW .styles_notificationsItemCardInfo__2tsif .styles_contractName__3hsut .styles_time__1s__b {
        font-family: FuturaPT;
        font-size: 10px;
        text-align: right;
        color: #757e95; }
    .styles_notificationsItemCard__3xNtJ .styles_notificationsItemCardInfo__2tsif .styles_name__VyQD0, .styles_notificationsItemCardTimeLine__h2bOW .styles_notificationsItemCardInfo__2tsif .styles_name__VyQD0 {
      font-family: FuturaPT-Light;
      font-size: 16px;
      font-weight: 300;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 5px; }
    .styles_notificationsItemCard__3xNtJ .styles_notificationsItemCardInfo__2tsif .styles_description__1kPRa, .styles_notificationsItemCardTimeLine__h2bOW .styles_notificationsItemCardInfo__2tsif .styles_description__1kPRa {
      font-family: FuturaPT-Light;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.25;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }

.styles_notificationsItemCardTimeLine__h2bOW {
  padding-right: 30px;
  padding-top: 15px;
  right: 15px;
  box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
  border: solid 0.3px #eaeaea;
  background-image: linear-gradient(132deg, white 28%, #f4f4f5 119%); }

@-webkit-keyframes styles_push-icon__2vjJh {
  0% {
    background-color: black; }
  50% {
    background-color: transparent; }
  100% {
    background-color: black; } }

@keyframes styles_push-icon__2vjJh {
  0% {
    background-color: black; }
  50% {
    background-color: transparent; }
  100% {
    background-color: black; } }

.styles_top_bg__3TcbI {
  position: absolute;
  right: 60%;
  left: 0;
  bottom: 0;
  z-index: -1;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  display: -webkit-flex;
  display: flex; }
  .styles_top_bg__3TcbI img {
    height: 100%;
    width: 100%;
    object-fit: fill; }

.styles_top_bg__3TcbI svg {
  height: 733px;
  width: 100%; }

.styles_container_sidebar__35P5I {
  position: absolute;
  background-color: #010101;
  z-index: 1202;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: all 0.2s;
  border-radius: 30px 0px 30px 0px; }
  .styles_container_sidebar__35P5I.styles_collapsed__2Ntqt {
    width: 48px !important;
    padding-left: 6px !important;
    overflow: hidden; }
  .styles_container_sidebar__35P5I .styles_headerSidebar__3ba1k {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer; }
    .styles_container_sidebar__35P5I .styles_headerSidebar__3ba1k .styles_imgLogo__vl7Rw {
      width: 25px;
      margin: 18px auto; }
    .styles_container_sidebar__35P5I .styles_headerSidebar__3ba1k div {
      -webkit-flex: 1 1;
              flex: 1 1; }
    .styles_container_sidebar__35P5I .styles_headerSidebar__3ba1k svg {
      position: relative;
      top: 5px; }
    .styles_container_sidebar__35P5I .styles_headerSidebar__3ba1k .styles_iconClose__2hQdS {
      -webkit-flex: inherit;
              flex: inherit;
      width: 42px;
      text-align: right; }
  .styles_container_sidebar__35P5I .styles_infoContract__24kQn p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 18px); }
  .styles_container_sidebar__35P5I .styles_transparent__3Cl18 p {
    color: transparent !important; }
  .styles_container_sidebar__35P5I .styles_breadcrumb__3F6qv {
    margin: 0px;
    font-size: 15px;
    color: #c9ccda; }
  .styles_container_sidebar__35P5I .styles_nameContract__2B7Sv {
    margin: 4px 0px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: uppercase; }
  .styles_container_sidebar__35P5I .styles_numRef__2J34b {
    margin: 0px;
    font-size: 15px;
    color: #757e95;
    text-transform: uppercase; }
  .styles_container_sidebar__35P5I .styles_listMenu__2j72o {
    overflow: auto; }
  .styles_container_sidebar__35P5I .styles_itemMenu__Rm_Lp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer; }
    .styles_container_sidebar__35P5I .styles_itemMenu__Rm_Lp p {
      margin: 0px;
      text-transform: uppercase;
      font-size: 12px;
      color: white; }
    .styles_container_sidebar__35P5I .styles_itemMenu__Rm_Lp.styles_itemSelected__3NTuO {
      background-color: white; }
      .styles_container_sidebar__35P5I .styles_itemMenu__Rm_Lp.styles_itemSelected__3NTuO p {
        color: black; }
    .styles_container_sidebar__35P5I .styles_itemMenu__Rm_Lp.styles_itemCollapsed__21i_Q {
      margin-left: -6px; }
  .styles_container_sidebar__35P5I .styles_buttonBack__KO56H {
    margin-top: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer; }
    .styles_container_sidebar__35P5I .styles_buttonBack__KO56H.styles_collapsedBack__1RHj6 {
      margin-left: 3px; }
      .styles_container_sidebar__35P5I .styles_buttonBack__KO56H.styles_collapsedBack__1RHj6 p {
        color: transparent; }
    .styles_container_sidebar__35P5I .styles_buttonBack__KO56H svg {
      margin: 0px 6px;
      position: relative;
      top: 2px; }
    .styles_container_sidebar__35P5I .styles_buttonBack__KO56H p {
      margin: 0px;
      text-transform: uppercase;
      color: white;
      font-size: 12px;
      opacity: 0.7; }

.styles_btShowSidebar__3KkcU {
  position: absolute;
  top: 60px;
  left: 60px;
  background-color: #010101;
  z-index: 1202;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer; }
  .styles_btShowSidebar__3KkcU svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    left: 9px;
    top: 2px; }

.styles_moreBackground__1xpew {
  display: -webkit-flex;
  display: flex;
  z-index: 1201;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9); }
  .styles_moreBackground__1xpew .styles_options__1K1ek {
    width: calc(100% - 20px);
    margin-top: auto;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 0 2px 1px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
    background-color: #fff; }

.styles_container__amAFm {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 99999999999999999999;
  background-color: rgba(255, 255, 255, 0.9); }
  .styles_container__amAFm .styles_box__1O2nf {
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    background-color: #000000;
    width: 230px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 39px 27px;
    -webkit-justify-content: space-around;
            justify-content: space-around; }
    @media (min-width: 701px) {
      .styles_container__amAFm .styles_box__1O2nf {
        width: 655px;
        height: 390px; } }
    .styles_container__amAFm .styles_box__1O2nf .styles_actions__1n9Wz {
      margin-top: 27px;
      width: 100%; }
      .styles_container__amAFm .styles_box__1O2nf .styles_actions__1n9Wz .styles_container_buttons__XiuwB {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__amAFm .styles_box__1O2nf .styles_actions__1n9Wz .styles_container_buttons__XiuwB .styles_container_button1__1o6MG .styles_button__3AGQI, .styles_container__amAFm .styles_box__1O2nf .styles_actions__1n9Wz .styles_container_buttons__XiuwB .styles_container_button2__1dl1J .styles_button__3AGQI {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center; }
        .styles_container__amAFm .styles_box__1O2nf .styles_actions__1n9Wz .styles_container_buttons__XiuwB .styles_container_button2__1dl1J {
          margin-left: 17px; }
    .styles_container__amAFm .styles_box__1O2nf .styles_title__39jMx {
      margin-top: 30px;
      font-family: FuturaPT-Medium;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #ffffff; }
    .styles_container__amAFm .styles_box__1O2nf .styles_message__m44aW {
      white-space: pre-line;
      margin-top: 10px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }

.changePassword_container__3AZLg {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .changePassword_container__3AZLg .changePassword_content__1tgra {
    background-color: #fff;
    margin-top: 70px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .changePassword_container__3AZLg .changePassword_content__1tgra {
        margin-top: auto !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important; } }
    .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_step_description__224Ul {
      padding: 21px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_inputs__2fN8y {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_inputs__2fN8y .changePassword_container_input__3EXdp {
        margin-top: 21px; }
    .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_error__84Smi {
      text-align: center;
      margin: 10px; }
    .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_submit__2qqfG {
      padding-top: 40px; }
      .changePassword_container__3AZLg .changePassword_content__1tgra .changePassword_container_submit__2qqfG .changePassword_button__3yHTk {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }

.styles_container__7EeMK {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__7EeMK .styles_content__2s_xY, .styles_container__7EeMK .styles_content_sent__Zm64k {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 700px) {
      .styles_container__7EeMK .styles_content__2s_xY, .styles_container__7EeMK .styles_content_sent__Zm64k {
        margin-top: 250px !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important; } }
    .styles_container__7EeMK .styles_content__2s_xY .styles_icon__1QEry, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_icon__1QEry {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__7EeMK .styles_content__2s_xY .styles_icon__1QEry svg, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_icon__1QEry svg {
      height: 70px;
      width: 70px; }
    .styles_container__7EeMK .styles_content__2s_xY .styles_container_step_description__36U4N, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_step_description__36U4N {
      padding: 21px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__7EeMK .styles_content__2s_xY .styles_container_inputs__1XdrN, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_inputs__1XdrN {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__7EeMK .styles_content__2s_xY .styles_container_inputs__1XdrN .styles_container_input__1hGAh, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_inputs__1XdrN .styles_container_input__1hGAh {
        margin-top: 21px; }
    .styles_container__7EeMK .styles_content__2s_xY .styles_container_error__1fIw8, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_error__1fIw8 {
      text-align: center;
      margin: 10px; }
    .styles_container__7EeMK .styles_content__2s_xY .styles_container_submit__2kBzy, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_submit__2kBzy {
      padding-top: 40px; }
      .styles_container__7EeMK .styles_content__2s_xY .styles_container_submit__2kBzy .styles_button__OBvxd, .styles_container__7EeMK .styles_content_sent__Zm64k .styles_container_submit__2kBzy .styles_button__OBvxd {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  .styles_container__7EeMK .styles_content__2s_xY {
    margin-top: 170px; }
  .styles_container__7EeMK .styles_content_sent__Zm64k {
    margin-top: 120px; }

.styles_container__Wgi2C {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__Wgi2C .styles_content__35Lwp {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 700px) {
      .styles_container__Wgi2C .styles_content__35Lwp {
        margin-top: auto !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important;
        padding-left: 38px !important;
        padding-right: 38px !important; } }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_icon__1Dfat {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_icon__1Dfat svg {
      height: 80px;
      width: 80px; }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_container_step_description__162WE {
      padding: 21px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_container_inputs__iK4_N {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__Wgi2C .styles_content__35Lwp .styles_container_inputs__iK4_N .styles_container_input__1DXM1 {
        margin-top: 21px; }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_container_error__2XQqF {
      text-align: center;
      margin: 10px; }
    .styles_container__Wgi2C .styles_content__35Lwp .styles_container_submit__1US7o {
      padding-top: 40px; }
      .styles_container__Wgi2C .styles_content__35Lwp .styles_container_submit__1US7o .styles_button__XZT2l {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  .styles_container__Wgi2C .styles_content__35Lwp {
    margin-top: 100px; }

.styles_container__2jqtt {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__2jqtt .styles_content__FsK00, .styles_container__2jqtt .styles_content_sent__1iEfU {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 700px) {
      .styles_container__2jqtt .styles_content__FsK00, .styles_container__2jqtt .styles_content_sent__1iEfU {
        margin-top: 250px !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important;
        padding-left: 38px !important;
        padding-right: 38px !important; } }
    .styles_container__2jqtt .styles_content__FsK00 .styles_icon__1KRxB, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_icon__1KRxB {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__2jqtt .styles_content__FsK00 .styles_icon__1KRxB svg, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_icon__1KRxB svg {
      height: 70px;
      width: 70px; }
    .styles_container__2jqtt .styles_content__FsK00 .styles_container_step_description__1_Ml2, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_step_description__1_Ml2 {
      padding: 21px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__2jqtt .styles_content__FsK00 .styles_container_inputs__1xVP3, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_inputs__1xVP3 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__2jqtt .styles_content__FsK00 .styles_container_inputs__1xVP3 .styles_container_input__27WXe, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_inputs__1xVP3 .styles_container_input__27WXe {
        margin-top: 21px; }
    .styles_container__2jqtt .styles_content__FsK00 .styles_container_error__3haxl, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_error__3haxl {
      text-align: center;
      margin: 10px; }
    .styles_container__2jqtt .styles_content__FsK00 .styles_container_submit__TxRRh, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_submit__TxRRh {
      padding-top: 40px; }
      .styles_container__2jqtt .styles_content__FsK00 .styles_container_submit__TxRRh .styles_button__2X8f5, .styles_container__2jqtt .styles_content_sent__1iEfU .styles_container_submit__TxRRh .styles_button__2X8f5 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  .styles_container__2jqtt .styles_content__FsK00 {
    margin-top: 170px; }
  .styles_container__2jqtt .styles_content_sent__1iEfU {
    margin-top: 120px; }

.styles_content__1_isL .styles_container_step_description__2DUo_ {
  padding: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.styles_content__1_isL .styles_content_form__2knBK {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .styles_content__1_isL .styles_content_form__2knBK .styles_form__18qT6 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF {
    max-width: 350px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
    padding-right: 17px; }
    .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF .styles_container_input__jvRuG {
      margin-top: 0px; }
    .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF .styles_container_numbers__37pa_ {
      display: -webkit-flex;
      display: flex; }
    .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF .styles_container_first__1WRf9, .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF .styles_container_following__nZXx5 {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
    .styles_content__1_isL .styles_content_form__2knBK .styles_container_inputs__qAlNF .styles_container_following__nZXx5 {
      margin-left: 5px; }
  .styles_content__1_isL .styles_content_form__2knBK .styles_container_error__3SCAQ {
    text-align: center;
    margin: 10px; }
  .styles_content__1_isL .styles_content_form__2knBK .styles_container_submit__2kUDX {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 40px; }
    .styles_content__1_isL .styles_content_form__2knBK .styles_container_submit__2kUDX .styles_button__369UM {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: center;
              justify-content: center;
      width: 270px; }

.styles_container__3t4QU {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3t4QU .styles_content__2L2Jn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 60px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 700px) {
      .styles_container__3t4QU .styles_content__2L2Jn {
        margin-top: 200px !important;
        min-margin: 170px !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important;
        padding-left: 38px !important;
        padding-right: 38px !important; } }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq {
      padding-top: 20px;
      padding-left: 20px !important;
      padding-right: 20px !important;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_container_title__ZF9cp {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_title__2IJrd, .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_description__2k7U9, .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_link__3Vl2v {
        color: #000000; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_title__2IJrd {
        max-width: 253px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_description__2k7U9 {
        font-family: FuturaPT-Light;
        font-size: 17px;
        font-weight: 300;
        text-align: center; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_link__3Vl2v {
        font-family: FuturaPT;
        font-size: 17px;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_description__1GqVq .styles_container_instruction__1W946 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_step_instructions__3TQte {
      margin-top: 25px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: left;
              align-items: left;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_form__3CzIB {
      margin-top: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_form__3CzIB .styles_input__3TRx3 {
        margin-top: 15px; }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_copy__18N_E {
      padding-top: 0px; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_copy__18N_E .styles_button__3NP4f {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_error__qOqgx {
      text-align: center;
      margin: 10px; }
    .styles_container__3t4QU .styles_content__2L2Jn .styles_container_submit__-XVU6 {
      margin-top: auto; }
      .styles_container__3t4QU .styles_content__2L2Jn .styles_container_submit__-XVU6 .styles_button__3NP4f {
        margin-top: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }

.styles_container__of8Mu {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  @media (min-width: 980px) {
    .styles_container__of8Mu .styles_grid_container__2iKUz {
      margin-top: 170px; } }
  .styles_container__of8Mu .styles_top_bg__13Jtu {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -9999;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    display: -webkit-flex;
    display: flex;
    max-height: 240px;
    top: 0; }
    @media (min-width: 701px) {
      .styles_container__of8Mu .styles_top_bg__13Jtu {
        left: 30%; } }
    .styles_container__of8Mu .styles_top_bg__13Jtu img {
      height: 100%;
      width: 100%;
      object-fit: fill; }
  .styles_container__of8Mu .styles_top_bg__13Jtu svg {
    width: 100%;
    height: 240px; }
  .styles_container__of8Mu .styles_content__j_Gu5 {
    background-color: #fff;
    margin-top: 170px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__of8Mu .styles_content__j_Gu5 {
        margin-top: 250px !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important; } }
    .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_step_description__36rRn {
      padding: 21px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      @media (max-width: 700px) {
        .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq {
          padding-right: 17px; } }
      @media (min-width: 701px) {
        .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq {
          padding-left: 113px !important;
          padding-right: 113px !important; } }
      .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_input__3UjA5 {
        margin-top: 0px; }
        .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_input__3UjA5 p {
          color: #d70000 !important; }
      .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_names__kLNmg {
        display: -webkit-flex;
        display: flex; }
        .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_names__kLNmg .styles_container_firstname__3Tw30, .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_names__kLNmg .styles_container_lastname__2XlLk {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_inputs__2BhSq .styles_container_names__kLNmg .styles_container_lastname__2XlLk {
          margin-left: 17px; }
    .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_error__2DwFe {
      text-align: center;
      margin: 10px; }
    .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_submit__2bj95 {
      padding-top: 40px; }
      .styles_container__of8Mu .styles_content__j_Gu5 .styles_container_submit__2bj95 .styles_button__PPKWS {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }

.styles_link__2zH67 {
  text-transform: capitalize !important;
  text-decoration: underline !important;
  color: #000 !important; }

.styles_separator__3zQwW {
  margin: 0px 3px; }

.styles_terms__1zK41 {
  font-size: 16px;
  font-weight: 300;
  text-align: center; }

.styles_container__2A3D- {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__2A3D- .styles_content__1cW4v {
    margin-top: 70px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 700px) {
      .styles_container__2A3D- .styles_content__1cW4v {
        margin-top: 250px !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important;
        padding-left: 38px !important;
        padding-right: 38px !important; } }
    .styles_container__2A3D- .styles_content__1cW4v .styles_container_form__1s2LY {
      margin-top: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }

.styles_container__1iZU4 {
  width: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }

.styles_container__bG4dz {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__bG4dz .styles_bottom_bg__jKcTK {
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    display: -webkit-flex;
    display: flex;
    height: 503px;
    width: 414px;
    bottom: 0; }
    .styles_container__bG4dz .styles_bottom_bg__jKcTK img {
      height: 100%;
      width: 100%;
      object-fit: fill; }
  .styles_container__bG4dz .styles_bottom_bg__jKcTK svg {
    width: 100%;
    height: 100%; }

.styles_version__3NXW7 {
  font-family: FuturaPT-Medium;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px; }

.styles_link__i8kax {
  font-family: FuturaPT-Medium;
  text-decoration: underline !important;
  cursor: pointer;
  color: #000000 !important; }

.styles_separator__3hZMV {
  padding: 0 20px; }

.styles_content__2ZzNN {
  margin-top: 170px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 70px !important; }
  @media (min-width: 700px) {
    .styles_content__2ZzNN {
      background-color: #fff;
      margin-top: 250px;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important;
      padding-left: 38px !important;
      padding-right: 38px !important; } }
  .styles_content__2ZzNN .styles_container_step_description__1CkwK {
    padding: 21px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .styles_content__2ZzNN .styles_container_form__1R5eg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch; }
    .styles_content__2ZzNN .styles_container_form__1R5eg .styles_input__2z3xV {
      margin-top: 15px; }
  .styles_content__2ZzNN .styles_container_error__1RC7j {
    text-align: center;
    margin: 10px; }
  .styles_content__2ZzNN .styles_container_submit__25kTK {
    padding-top: 40px; }
    .styles_content__2ZzNN .styles_container_submit__25kTK .styles_button__3BZiv {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: center;
              justify-content: center; }
  .styles_content__2ZzNN .styles_container_signin__lkg8q {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .styles_content__2ZzNN .styles_container_forgot_password__3uqDm {
    margin-top: 20px; }

.editProfile_container__3BdkR {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .editProfile_container__3BdkR .editProfile_content__3ltVe {
    background-color: #fff;
    margin-top: 70px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .editProfile_container__3BdkR .editProfile_content__3ltVe {
        margin-top: auto !important;
        margin-bottom: auto !important;
        border-radius: 5px;
        max-width: 687px !important;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        padding-top: 42px !important;
        padding-bottom: 52px !important; } }
    .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_personal_information__77hmi {
      margin-top: 20px;
      margin-bottom: 50px;
      background-color: #fff;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      position: relative;
      z-index: 0; }
      .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_personal_information__77hmi .editProfile_container_profile_data__1ptqt {
        width: 130px;
        height: 130px; }
      .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_personal_information__77hmi .editProfile_container_data__3pSL9 {
        z-index: 2;
        text-align: center;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal; }
        .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_personal_information__77hmi .editProfile_container_data__3pSL9 .editProfile_names__1okaW {
          font-size: 28px;
          line-height: 1.61;
          text-transform: capitalize; }
        .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_personal_information__77hmi .editProfile_container_data__3pSL9 .editProfile_email__37obb {
          font-size: 15px;
          line-height: 1.27; }
    .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF .editProfile_container_input__16onX {
        margin-top: 0px; }
      .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF .editProfile_container_names__1ts3- {
        display: -webkit-flex;
        display: flex; }
        .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF .editProfile_container_names__1ts3- .editProfile_container_firstname__2qQN4, .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF .editProfile_container_names__1ts3- .editProfile_container_lastname__rUsL9 {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_inputs__3kzqF .editProfile_container_names__1ts3- .editProfile_container_lastname__rUsL9 {
          margin-left: 17px; }
    .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_error__30aQK {
      text-align: center;
      margin: 10px; }
    .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_submit__2sv92 {
      display: -webkit-flex;
      display: flex;
      padding-top: 40px; }
      .editProfile_container__3BdkR .editProfile_content__3ltVe .editProfile_container_submit__2sv92 .editProfile_button__6uM5j {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }

.editProfile_title__2_tXJ, .editProfile_edit__2eWhI {
  font-family: FuturaPT-Medium;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: black; }

.editProfile_edit__2eWhI {
  font-size: 18px;
  cursor: pointer;
  min-width: 23px;
  width: 23px; }

.styles_content__3Y5ed {
  cursor: pointer;
  max-width: 180px;
  width: 100%;
  height: 93px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 10px; }
  .styles_content__3Y5ed .styles_button__2Kj0I {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 83px;
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
    overflow: hidden;
    background-color: #111; }
    .styles_content__3Y5ed .styles_button__2Kj0I .styles_background__xc23s {
      position: absolute;
      width: 69px;
      height: 78px;
      z-index: 1;
      top: 0px;
      left: 0px; }
    .styles_content__3Y5ed .styles_button__2Kj0I .styles_number__7ykcD {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-family: FuturaPT-Demi;
      font-size: 35px;
      line-height: 0.49;
      letter-spacing: -0.88px;
      text-align: center;
      color: white; }
    .styles_content__3Y5ed .styles_button__2Kj0I .styles_text__3H7DQ {
      margin-top: 10px;
      font-family: FuturaPT-Medium;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: 0.9px;
      text-align: center;
      color: white; }
  .styles_content__3Y5ed .styles_bottom_line__2DcwA {
    height: 2px;
    width: 100%; }

.styles_container_player__uZtms {
  height: 100%;
  background-color: rgba(34, 36, 42, 0.9);
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999999999;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 1%;
  padding-bottom: 20px;
  text-align: center;
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  align-items: center; }
  .styles_container_player__uZtms .styles_close__1KzOv {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .styles_container_player__uZtms .styles_player__1XdmZ {
    position: relative;
    margin: 20px; }

.styles_container_swipper__2Mw6- {
  margin-right: -20px;
  margin-left: -20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 50px; }
  .styles_container_swipper__2Mw6- .styles_container_pages__3NE-X {
    width: 100%; }
    @media (min-width: 701px) {
      .styles_container_swipper__2Mw6- .styles_container_pages__3NE-X {
        height: 470px; } }
    .styles_container_swipper__2Mw6- .styles_container_pages__3NE-X .styles_page__2Zw3A {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex: 1 1;
              flex: 1 1;
      height: 100%; }
      .styles_container_swipper__2Mw6- .styles_container_pages__3NE-X .styles_page__2Zw3A img {
        z-index: -1;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        top: 0; }
        @media (min-width: 701px) {
          .styles_container_swipper__2Mw6- .styles_container_pages__3NE-X .styles_page__2Zw3A img {
            position: absolute; } }

.styles_container_step_description__10NiQ {
  padding: 11px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_container_step_description__10NiQ .styles_welcome_title__2Mems {
    font-family: FuturaPT-Medium;
    font-size: 35px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.17px;
    text-align: center; }
  .styles_container_step_description__10NiQ .styles_hidden_box__24eXP {
    margin-top: 200px;
    width: 100%;
    height: 1px; }
  .styles_container_step_description__10NiQ .styles_step_number__1p_FS {
    margin-top: 35px;
    margin-bottom: 25px;
    width: 50px;
    height: 50px;
    border: solid 1px;
    border-radius: 50%; }
  .styles_container_step_description__10NiQ .styles_step_number_label__1RTbv {
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__10NiQ .styles_step_title__1AID- {
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__10NiQ .styles_step_description__MD2z5 {
    font-family: FuturaPT-LightObl;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__10NiQ .styles_step_description_with_link__29s3z {
    font-family: FuturaPT-Light;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #000000; }
    .styles_container_step_description__10NiQ .styles_step_description_with_link__29s3z .styles_link__2RhIu {
      font-family: FuturaPT-Demi;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #000; }

.styles_first_add_description__C4VB4 {
  display: -webkit-flex;
  display: flex;
  z-index: -1;
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 40px;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }
  @media (min-width: 701px) {
    .styles_first_add_description__C4VB4 {
      bottom: 100px; } }
  .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 701px) {
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC {
        -webkit-flex-direction: row;
                flex-direction: row; } }
    .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_container_first_add_text__bx6RM {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_container_first_add_text__bx6RM .styles_first_add_description__C4VB4 {
        font-family: FuturaPT-Light;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_container_first_add_text__bx6RM .styles_first_add_title__20UGt {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 5px;
        font-family: FuturaPT-Medium;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.83px;
        text-align: center; }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_container_first_add_text__bx6RM .styles_first_add_description__C4VB4 {
        font-family: FuturaPT-Light;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_container_first_add_text__bx6RM .styles_add_your_first_contract__2me7W {
        font-family: FuturaPT;
        font-size: 16px;
        line-height: 0.38;
        text-align: center;
        color: #757e95; }
    .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_icon_container__2n2rc {
      margin-top: 9px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      margin-bottom: 15px;
      margin-right: 40px; }
      @media (min-width: 701px) {
        .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_icon_container__2n2rc {
          margin-bottom: 0px;
          margin-right: 190px !important; } }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_icon_container__2n2rc .styles_first_add_arrow__1Jw-b img {
        height: 100%;
        width: 100%;
        object-fit: fill; }
      .styles_first_add_description__C4VB4 .styles_container_first_add_description__2gQPC .styles_icon_container__2n2rc .styles_first_add_arrow__1Jw-b svg {
        height: 100%;
        width: 100%; }

.styles_chart__1aTN- {
  max-width: 100%;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .styles_chart__1aTN- .styles_emptyIcon__3fUVN {
    width: 90px;
    margin: -60px 0px 12px 60px; }
  .styles_chart__1aTN- .styles_haventDates__1dZ2C {
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase; }
  .styles_chart__1aTN- .styles_btnAddDate__PNs2i {
    font-family: FuturaPT-Demi;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    color: black;
    text-transform: uppercase;
    cursor: pointer; }
  .styles_chart__1aTN- .styles_status__2yV39 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 18px;
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 25px;
    font-size: 12px;
    text-align: center;
    border-radius: 15.5px;
    white-space: nowrap;
    background-color: rgba(76, 72, 143, 0.64);
    color: white;
    font-family: FuturaPT-Medium;
    margin-bottom: 12px;
    text-transform: uppercase; }
  .styles_chart__1aTN- .styles_contStatus__1j2n0 {
    margin-bottom: 120px;
    margin-top: -30px;
    width: 100%; }
  .styles_chart__1aTN- .styles_lblStatus__3Q_oZ {
    font-family: FuturaPT-Medium;
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-transform: uppercase; }
  .styles_chart__1aTN- .styles_subLblStatus__XD35G {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #757e95; }
    .styles_chart__1aTN- .styles_subLblStatus__XD35G svg {
      color: #757e95;
      margin-right: 6px; }
  .styles_chart__1aTN- .styles_time_line__TUSey {
    position: relative;
    margin: 0 30px 0 30px;
    height: 9px;
    border-radius: 4.5px;
    margin-bottom: 12px; }
    .styles_chart__1aTN- .styles_time_line__TUSey .styles_bar__uEhaG {
      display: -webkit-flex;
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4.5px;
      overflow: hidden;
      background-color: #F3F4F5;
      box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
    .styles_chart__1aTN- .styles_time_line__TUSey .styles_date_label__1RGVW {
      position: absolute;
      font-family: FuturaPT;
      font-size: 10px;
      text-align: right;
      margin-top: -14px; }
    .styles_chart__1aTN- .styles_time_line__TUSey .styles_date_line__14BJo {
      position: absolute;
      width: 1px;
      height: 23px;
      margin-top: -14px;
      background-color: #e3e3e3; }
  .styles_chart__1aTN- .styles_labels_box__WNJne {
    margin-top: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 20px;
    padding-top: 20px; }
    .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-right: 5px;
      margin-left: 5px;
      position: relative; }
      .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row; }
        .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_received_circle__aN5PH, .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_nominated_circle__ATKGm, .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_white_circle__3GIk3 {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0 5px; }
        .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_received_circle__aN5PH {
          background-color: #757e95; }
        .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_nominated_circle__ATKGm {
          background-color: #4c488f; }
        .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label_box__1xN4c .styles_white_circle__3GIk3 {
          background-color: #F3F4F5;
          box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
      .styles_chart__1aTN- .styles_labels_box__WNJne .styles_label_item__1NLv3 .styles_label__swa_7 {
        font-family: FuturaPT-Medium;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
        margin: 0px 3px; }
  .styles_chart__1aTN- .styles_labels_box2__1xEhS {
    width: 80%;
    margin-top: 30px; }
  .styles_chart__1aTN- .styles_priceLabel__28_R5 {
    position: absolute;
    bottom: -24px;
    color: #757e95;
    font-family: FuturaPT-Medium;
    font-size: 16px;
    width: 100%;
    text-align: center; }
  .styles_chart__1aTN- .styles_flexRow__3Zl27 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    margin-left: 0;
    width: 100%; }
    .styles_chart__1aTN- .styles_flexRow__3Zl27 div {
      height: 100%;
      border-radius: 12px;
      position: absolute;
      left: 0;
      top: 0; }
  .styles_chart__1aTN- .styles_rowDates__ZHA9P {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 6px;
    height: 14px; }
    .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRow__2IJD7, .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRowFloat__-FafV {
      font-family: FuturaPT-Light;
      font-size: 13px;
      color: black;
      position: relative; }
      .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRow__2IJD7 span, .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRowFloat__-FafV span {
        position: absolute;
        top: -12px;
        font-family: FuturaPT-Medium;
        font-size: 13px;
        white-space: nowrap; }
      .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRow__2IJD7 .styles_lastRow__2ibhV, .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRowFloat__-FafV .styles_lastRow__2ibhV {
        right: 0px; }
    .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_dateInRowFloat__-FafV {
      position: absolute;
      z-index: 1; }
    .styles_chart__1aTN- .styles_rowDates__ZHA9P .styles_lineTask__33BqA {
      position: absolute;
      border-left: 2px solid #e3e3e3;
      height: 29px;
      width: 1px;
      left: -3px;
      top: 0px; }
  .styles_chart__1aTN- .styles_contLine__3K6h6 {
    position: absolute;
    bottom: -20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
    margin-left: -5px; }
  .styles_chart__1aTN- .styles_justLeft__1zJxW {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-left: 0px; }
  .styles_chart__1aTN- .styles_justRight__TpFLC {
    -webkit-align-items: flex-end;
            align-items: flex-end; }
  .styles_chart__1aTN- .styles_lblToday__2US_v {
    font-size: 18px;
    position: absolute;
    font-family: FuturaPT-Medium;
    white-space: nowrap;
    top: -42px;
    text-transform: uppercase; }
  .styles_chart__1aTN- .styles_dateToday__2vVZa {
    font-size: 11px;
    position: absolute;
    white-space: nowrap;
    top: -22px;
    text-transform: uppercase; }
  .styles_chart__1aTN- .styles_circleLine__1vfIX {
    width: 3px;
    height: 3px;
    border: 2px solid black;
    border-radius: 50%; }
  .styles_chart__1aTN- .styles_lineDate__1Er7p {
    height: 55px;
    width: 1px;
    border-left: 2px solid black; }

.styles_container__34PaI {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; }
  .styles_container__34PaI .styles_photo_border__3rMy3 {
    background-color: white;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 1px #000;
    padding: 1%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1px; }
    .styles_container__34PaI .styles_photo_border__3rMy3 .styles_container_photo__2u_65 {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: 50%;
      overflow: hidden;
      position: relative; }
    .styles_container__34PaI .styles_photo_border__3rMy3 .styles_container_loading__1PaHS {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
    .styles_container__34PaI .styles_photo_border__3rMy3 .styles_container_image__38D6I img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .styles_container__34PaI .styles_photo_border__3rMy3 .styles_initials__1-1Dt {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      font-family: FuturaPT-Medium;
      font-size: 0.5em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.9;
      letter-spacing: normal;
      text-align: center;
      color: #fff; }
  .styles_container__34PaI .styles_photo_container__1GYmg {
    width: 100%;
    height: 100%; }

.styles_list_menu_content__1DpPg {
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  min-width: 265px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  min-height: 225px;
  border-radius: 5px 50px 5px 50px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: #fff; }
  .styles_list_menu_content__1DpPg .styles_action__1hyeL {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    height: 35px; }
  .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE .styles_task_type_content__1GLOU {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-align-items: center;
                align-items: center;
        padding-left: 35px;
        padding-right: 28px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE .styles_task_type_content__1GLOU .styles_icon__2e18z {
          width: 21px;
          height: 21px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE .styles_task_type_content__1GLOU .styles_type__39qMG {
          margin-left: 13px;
          font-family: FuturaPT-Medium;
          font-size: 12px;
          font-weight: 500;
          margin-right: auto; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE .styles_task_type_content__1GLOU .styles_new__1kgHe {
          border-radius: 15.5px;
          border: solid 1px #4c488f;
          padding: 3px 6px;
          font-family: FuturaPT-Medium;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          color: #4c488f; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_task_collaborator_content__4w0fE .styles_task_type_content__1GLOU .styles_number__nWqAm {
          margin-left: 22px;
          font-family: FuturaPT-Demi;
          font-size: 20px;
          letter-spacing: 1px;
          text-align: right; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      height: 100%;
      overflow: hidden; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        overflow: hidden;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        padding-bottom: 22px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp .styles_photo__3YGae {
          width: 26px;
          height: 26px;
          object-fit: contain; }
          .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp .styles_photo__3YGae .styles_photo_container__WO77l {
            width: 100%;
            height: 100%; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp .styles_status__18gvw {
          width: 67px;
          border-radius: 15.5px;
          border: solid 1px #111;
          padding: 3px;
          font-family: FuturaPT-Medium;
          font-size: 9px;
          font-weight: 500;
          text-align: center; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp .styles_company__foPYn {
          margin-top: 6px;
          font-family: FuturaPT-Light;
          font-size: 16px;
          font-weight: 300;
          text-align: center;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_user_content__2Kpdp .styles_role__137dR {
          font-family: FuturaPT;
          font-size: 18px;
          text-align: center;
          margin-bottom: 8px; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_center_content__2RsIr {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        width: 1px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_center_content__2RsIr .styles_you__2tAAP {
          margin-top: 6px;
          font-family: FuturaPT-Medium;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.8px;
          text-align: center; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_financier_content__1A_Fz .styles_center_content__2RsIr .styles_status__18gvw {
          margin-top: 6px;
          border-radius: 15.5px;
          border: solid 1px #111;
          font-family: FuturaPT-Medium;
          width: 74px;
          padding: 5px;
          font-size: 12px;
          font-weight: 500;
          text-align: center; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_contract_reference__1h9vl {
      padding: 0 40px 0 40px;
      height: 21px;
      font-family: FuturaPT;
      font-size: 16px;
      text-align: center;
      color: #757e95;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_contract_volume__2OzaE {
      padding: 0px 50px;
      height: 23px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.9px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_sugar_spec__2DOSt {
      height: 21px;
      font-family: FuturaPT;
      font-size: 16px;
      text-align: center; }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_line__1qCZT {
      margin-top: 15px;
      height: 0.5px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2); }
    .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- {
      -webkit-flex: 1 1 auto;
              flex: 1 1 auto;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_status__18gvw {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        height: 25px;
        font-family: FuturaPT-Medium;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        border-radius: 15.5px;
        border: solid 1px #111; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_line__1qCZT {
        width: 1px;
        height: 34px;
        margin-bottom: -9px;
        background-color: #111; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_time_line__cS2vf {
        position: relative;
        width: 100%;
        margin: 0 30px 0 30px;
        height: 9px;
        border-radius: 4.5px;
        box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_time_line__cS2vf .styles_bar__1RnmP {
          display: -webkit-flex;
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 4.5px;
          overflow: hidden; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_time_line__cS2vf .styles_date_label__10MPn {
          position: absolute;
          font-family: FuturaPT;
          font-size: 10px;
          text-align: right;
          margin-top: -14px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_time_line__cS2vf .styles_date_line__2uhHO {
          position: absolute;
          width: 1px;
          height: 23px;
          margin-top: -14px;
          background-color: #e3e3e3; }
      .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn {
        margin-top: auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        padding-bottom: 20px;
        padding-top: 20px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn .styles_received_circle__tEABY, .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn .styles_nominated_circle__gXlgG {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0 5px 0 10px; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn .styles_received_circle__tEABY {
          background-color: #757e95; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn .styles_nominated_circle__gXlgG {
          background-color: #4c488f; }
        .styles_list_menu_content__1DpPg .styles_list_menu_item__1L4wz .styles_chart__2XV0- .styles_labels_box__2MhCn .styles_label__klwdE {
          font-family: FuturaPT-Medium;
          font-size: 16px;
          font-weight: 500;
          margin: 0 10px 0 5px; }

.styles_container__3ZOBp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin: 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden; }
  .styles_container__3ZOBp .styles_container_bg__ytSRl {
    position: absolute;
    bottom: 0%;
    width: 40%px;
    height: 205px;
    z-index: -9999999;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    display: -webkit-flex;
    display: flex; }
    .styles_container__3ZOBp .styles_container_bg__ytSRl img {
      height: 100%;
      width: 100%;
      object-fit: fill; }
  .styles_container__3ZOBp .styles_container_bg__ytSRl svg {
    width: 100%;
    height: 100%; }
  .styles_container__3ZOBp .styles_main_content__1oVTi {
    margin-left: 0px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .styles_container__3ZOBp .styles_content__289or {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 10px !important; }
    @media (min-width: 800px) {
      .styles_container__3ZOBp .styles_content__289or {
        padding-left: 0px !important;
        padding-right: 40px !important; } }
    .styles_container__3ZOBp .styles_content__289or .styles_filter_buttons__127gq, .styles_container__3ZOBp .styles_content__289or .styles_filter_roles__1UIle {
      padding: 20px 0 20px 0;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
    .styles_container__3ZOBp .styles_content__289or .styles_filter_buttons__127gq {
      min-height: 93px; }
    .styles_container__3ZOBp .styles_content__289or .styles_filter_roles__1UIle {
      min-height: 50px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding: 30px 0; }
      .styles_container__3ZOBp .styles_content__289or .styles_filter_roles__1UIle .styles_roles_button__1bLrm, .styles_container__3ZOBp .styles_content__289or .styles_filter_roles__1UIle .styles_roles_button_active__ik9N_ {
        border: none;
        height: 30px;
        margin: 10px;
        font-size: 16px;
        letter-spacing: 0.89px;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        background-color: white;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.3);
        font-family: FuturaPT-Medium; }
      .styles_container__3ZOBp .styles_content__289or .styles_filter_roles__1UIle .styles_roles_button_active__ik9N_ {
        color: black;
        border-bottom: 2px solid black; }
    .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU {
      min-height: 72px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
        @media (min-width: 701px) {
          .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa {
            -webkit-flex-grow: 0;
                    flex-grow: 0;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content; } }
        .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_sort_label__2oNgW {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500; }
        .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_sort_select__2JJEX, .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_filter_date__1Gl6U {
          max-width: 150px;
          padding-left: 10px;
          padding-right: 10px;
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
          @media (min-width: 701px) {
            .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_sort_select__2JJEX, .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_filter_date__1Gl6U {
              -webkit-flex-grow: 0;
                      flex-grow: 0;
              width: 150px; } }
        @media (min-width: 701px) {
          .styles_container__3ZOBp .styles_content__289or .styles_filter_box__3qyzU .styles_sort_box__XRmZa .styles_filter_date__1Gl6U {
            width: 220px;
            max-width: 220px; } }
    .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding-bottom: 20px;
      padding-top: 20px; }
      .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ .styles_received_circle__11UeG, .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ .styles_nominated_circle__1_wBH {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0 5px 0 10px; }
      .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ .styles_received_circle__11UeG {
        background-color: #757e95; }
      .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ .styles_nominated_circle__1_wBH {
        background-color: #4c488f; }
      .styles_container__3ZOBp .styles_content__289or .styles_labels_box__1uyzJ .styles_label__2412Q {
        font-family: FuturaPT-Medium;
        font-size: 16px;
        font-weight: 500;
        margin: 0 10px 0 5px; }
    .styles_container__3ZOBp .styles_content__289or .styles_scroll_container__tGEKk {
      overflow: scroll; }
      .styles_container__3ZOBp .styles_content__289or .styles_scroll_container__tGEKk .styles_list_container__2rWat {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-content: stretch;
                align-content: stretch;
        padding-bottom: 80px; }
        @media (min-width: 701px) {
          .styles_container__3ZOBp .styles_content__289or .styles_scroll_container__tGEKk .styles_list_container__2rWat {
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-flex-flow: row wrap;
                    flex-flow: row wrap; } }
  .styles_container__3ZOBp .styles_container_add_button__1etLQ {
    right: 30px;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 60px;
    height: 60px;
    bottom: 27px;
    z-index: 1300; }
    @media (min-width: 701px) {
      .styles_container__3ZOBp .styles_container_add_button__1etLQ {
        bottom: 80px;
        right: 120px; } }
    .styles_container__3ZOBp .styles_container_add_button__1etLQ .styles_add_button__jJU3q {
      height: 60px;
      width: 60px; }
      @media (min-width: 1700px) {
        .styles_container__3ZOBp .styles_container_add_button__1etLQ .styles_add_button__jJU3q button::before {
          content: "";
          z-index: -1;
          background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
          height: 380%;
          width: 300%;
          position: absolute;
          bottom: -80px;
          right: -1px; } }

.styles_container__9s2Gt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .styles_container__9s2Gt .styles_background__1hQoc {
    z-index: 1300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.97; }
  .styles_container__9s2Gt .styles_box__3vU1D {
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    z-index: 1300;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    object-fit: contain;
    border-radius: 5px; }
    .styles_container__9s2Gt .styles_box__3vU1D .styles_background_image__2iu1- {
      -webkit-align-self: flex-end;
              align-self: flex-end;
      position: absolute;
      z-index: -100;
      width: 688px;
      height: 633px;
      bottom: 0px;
      right: 0px; }
    .styles_container__9s2Gt .styles_box__3vU1D .styles_box_content__14TVU {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      align-items: center;
      overflow: auto; }
    .styles_container__9s2Gt .styles_box__3vU1D .styles_close__2GYmR {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 50px;
      height: 50px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }

.styles_box_collaborators__6mhGT {
  background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 27px;
  margin-bottom: 20px; }
  .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X {
    display: -webkit-flex;
    display: flex; }
    .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X .styles_box_text__1CiXQ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-left: 10px; }
      .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X .styles_box_text__1CiXQ .styles_title__2E3iv {
        font-family: FuturaPT;
        font-size: 20px; }
      .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X .styles_box_text__1CiXQ .styles_title2__3DofJ {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 300; }
      .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X .styles_box_text__1CiXQ .styles_bold__3kmz6 {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500; }
      .styles_box_collaborators__6mhGT .styles_box_state_description__2YG_X .styles_box_text__1CiXQ .styles_subtitle__3lDUZ {
        font-family: FuturaPT;
        font-size: 15px;
        color: #757e95; }
  .styles_box_collaborators__6mhGT .styles_box_item__1UcPK {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_personal_data__rExt6 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_personal_data__rExt6 .styles_box_email__1jOIK {
          font-family: FuturaPT-Light;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-left: 25px; }
        .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_personal_data__rExt6 .styles_box_photo_name__3dwhX {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_personal_data__rExt6 .styles_box_photo_name__3dwhX .styles_box_name__1sYOt {
            margin-left: 5px;
            font-family: FuturaPT;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal; }
      .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_status_data__2-dum {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_status_data__2-dum .styles_box_status__7eOFe {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_status_data__2-dum .styles_box_status__7eOFe .styles_box_status_text__1gWau {
            font-family: FuturaPT;
            font-size: 12px;
            text-align: right; }
        .styles_box_collaborators__6mhGT .styles_box_item__1UcPK .styles_box_row__1Tf4v .styles_box_status_data__2-dum .styles_box_status_date__2Dzf2 {
          font-family: FuturaPT;
          font-size: 11px;
          text-align: right;
          color: #757e95; }

.styles_box_image__2yUQy {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }

@media (min-width: 701px) {
  .styles_grid_container__1ujg9 {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px; } }

.styles_container__3pr9Y {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3pr9Y .styles_content__3yu-E {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__3pr9Y .styles_content__3yu-E {
        margin-bottom: auto !important;
        margin-right: 0px;
        margin-left: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_container_title__k_fp1 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0px 20px 30px 20px; }
      .styles_container__3pr9Y .styles_content__3yu-E .styles_container_title__k_fp1 .styles_title__Z-_ss {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_container_step_description__3voI_ {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_container_contract_type__1ZaHQ {
      margin-top: 50px;
      margin-bottom: -30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%; }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_title_form__2mZeg {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_subtitle_form__1AcDb {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_container__3pr9Y .styles_content__3yu-E .styles_label_form__NkLJq {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; }

.styles_container_inputs__1OOfK {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding-right: 17px; }
  .styles_container_inputs__1OOfK .styles_container_checkbox__3_476 {
    background-image: linear-gradient(83deg, white 0%, #757e95 323%);
    margin-left: -20px;
    margin-right: -40px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 40px 20px 20px; }
    .styles_container_inputs__1OOfK .styles_container_checkbox__3_476 .styles_checkbox__2BClk {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin: 0 0 0 10px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
  .styles_container_inputs__1OOfK .styles_add_counterpart__36cTG {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    font-family: FuturaPT;
    font-size: 18px; }
  .styles_container_inputs__1OOfK .styles_container_input__2K1Xl {
    margin-top: 0px; }
  .styles_container_inputs__1OOfK .styles_container_names__2jCku {
    display: -webkit-flex;
    display: flex; }
    .styles_container_inputs__1OOfK .styles_container_names__2jCku .styles_container_first_field__1pohz, .styles_container_inputs__1OOfK .styles_container_names__2jCku .styles_container_last_field__2INKR {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 0;
              flex-basis: 0; }
    .styles_container_inputs__1OOfK .styles_container_names__2jCku .styles_container_last_field__2INKR {
      margin-left: 17px; }

.styles_container_error__3geQK {
  text-align: center;
  margin: 10px; }

.styles_container_propose_changes__34teO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px; }
  .styles_container_propose_changes__34teO .styles_button_alone__2gsup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center; }

.styles_container_submit__TV7dN {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 40px; }
  .styles_container_submit__TV7dN .styles_box_two_options__XcKci {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .styles_container_submit__TV7dN .styles_box_two_options__XcKci .styles_button__2IkjC {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      min-width: 155px;
      max-width: 270px; }
  .styles_container_submit__TV7dN .styles_button_alone__2gsup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center; }

.styles_modal_box_content__10m7x {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  align-items: center; }
  .styles_modal_box_content__10m7x .styles_title__Z-_ss {
    font-family: FuturaPT-Medium;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 7px;
    color: white; }
  .styles_modal_box_content__10m7x .styles_description__1ocfS {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 40px;
    color: white; }
  .styles_modal_box_content__10m7x .styles_subtitle__27epc {
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 25px;
    color: white; }
  .styles_modal_box_content__10m7x .styles_item__1pFKF {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #ffffff;
    margin-bottom: 25px; }
  .styles_modal_box_content__10m7x .styles_done_text__3-JaB {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
  .styles_modal_box_content__10m7x .styles_peding_text__3xxrE {
    font-family: FuturaPT-Light;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
  .styles_modal_box_content__10m7x .styles_buttons_container__7r5Q2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 10px; }

.styles_main_content__MA1Ig {
  max-width: 400px;
  margin-right: 50px !important; }

.styles_content__3i2uX {
  background-color: #fff;
  margin-top: 0px;
  height: 100% !important;
  margin-left: 0 !important; }
  @media (min-width: 701px) {
    .styles_content__3i2uX {
      margin-top: 40px !important;
      margin-bottom: auto !important;
      margin-left: auto; } }
  .styles_content__3i2uX .styles_spec_item__3Mmx7 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px 0 20px;
    height: 64px;
    box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
    background-image: linear-gradient(280deg, #f4f4f5 119%, #fff 28%); }
    .styles_content__3i2uX .styles_spec_item__3Mmx7 .styles_title__33HHQ {
      margin-right: auto;
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500; }
    @media (min-width: 701px) {
      .styles_content__3i2uX .styles_spec_item__3Mmx7 {
        margin: 0;
        background-image: none;
        box-shadow: 1px 1px 5px 0 #d4d7df, inset 1px 1px 3px 0 #ebecf0; }
        .styles_content__3i2uX .styles_spec_item__3Mmx7 .styles_title__33HHQ {
          font-size: 16px; }
        .styles_content__3i2uX .styles_spec_item__3Mmx7:first-child {
          border-radius: 5px 5px 0px 0px; }
        .styles_content__3i2uX .styles_spec_item__3Mmx7:last-child {
          border-radius: 0px 0px 5px 5px; } }
  .styles_content__3i2uX .styles_container_step_description__3HLe_ {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .styles_content__3i2uX .styles_container_submit__3eVCK {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: auto;
    padding-top: 40px; }
    .styles_content__3i2uX .styles_container_submit__3eVCK .styles_box_two_options__8ZqXT {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center; }
      .styles_content__3i2uX .styles_container_submit__3eVCK .styles_box_two_options__8ZqXT .styles_button__2TM90 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        min-width: 155px;
        max-width: 270px; }
    .styles_content__3i2uX .styles_container_submit__3eVCK .styles_button_alone__3BdBz {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: center;
              justify-content: center; }

.styles_container_title__pHBPQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  .styles_container_title__pHBPQ .styles_contract_reference__3GVde {
    font-family: FuturaPT;
    font-size: 15px;
    text-align: center;
    color: #757e95; }
  .styles_container_title__pHBPQ .styles_contract_name__1Pc6g {
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center; }

.styles_active__2SiIW {
  background-color: black;
  color: white; }
  .styles_active__2SiIW svg path, .styles_active__2SiIW svg g {
    stroke: white;
    opacity: 1; }

.styles_main_content__2C3Kc {
  box-sizing: border-box;
  max-width: 400px;
  margin-right: 50px !important; }
  .styles_main_content__2C3Kc .styles_content__19G_t {
    margin-top: 0px;
    height: 100% !important;
    padding: 30px !important;
    margin-left: 0 !important; }
    @media (min-width: 701px) {
      .styles_main_content__2C3Kc .styles_content__19G_t {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        margin-left: auto;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
        background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%); } }
  .styles_main_content__2C3Kc .styles_name__3zFOc {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    color: black;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .styles_main_content__2C3Kc .styles_reference__76Re0 {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .styles_main_content__2C3Kc .styles_buyers__2lfNO {
    object-fit: contain;
    border-top: solid 0.5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding-top: 20px;
    margin-top: 20px; }
    .styles_main_content__2C3Kc .styles_buyers__2lfNO .styles_buyersTitle__1P9aO {
      font-family: FuturaPT;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757e95;
      margin-bottom: 5px; }
    .styles_main_content__2C3Kc .styles_buyers__2lfNO .styles_buyersInfoContainer__2BWkM .styles_avatar__3qevA {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      font-size: 13px; }
    .styles_main_content__2C3Kc .styles_buyers__2lfNO .styles_buyersInfoContainer__2BWkM .styles_buyersInfo__3GoJo {
      display: inline-block;
      overflow: hidden; }
      .styles_main_content__2C3Kc .styles_buyers__2lfNO .styles_buyersInfoContainer__2BWkM .styles_buyersInfo__3GoJo .styles_buyersName__7jY1a {
        font-family: FuturaPT;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.89px;
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .styles_main_content__2C3Kc .styles_buyers__2lfNO .styles_buyersInfoContainer__2BWkM .styles_buyersInfo__3GoJo .styles_buyersEmail__2imzh {
        font-family: FuturaPT;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.89px;
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }

@media (min-width: 701px) {
  .styles_grid_container__1sqfv {
    width: 700px;
    max-width: 1920px; } }

.styles_container__1XDDi {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__1XDDi .styles_content__1uXiw {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__1XDDi .styles_content__1uXiw {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        margin-right: 0px;
        margin-left: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__1XDDi .styles_content__1uXiw .styles_container_title__25EQw {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__1XDDi .styles_content__1uXiw .styles_container_title__25EQw .styles_title__2L-Ws {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__1XDDi .styles_content__1uXiw .styles_container_step_description__1I7Kv {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__1XDDi .styles_content__1uXiw .styles_title_form__1T9Cf {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .styles_container__1XDDi .styles_content__1uXiw .styles_subtitle_form__c2NeN {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_container__1XDDi .styles_content__1uXiw .styles_container_inputs__l3cFE {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__1XDDi .styles_content__1uXiw .styles_container_inputs__l3cFE .styles_container_input__3CwIC {
        margin-top: 0px; }
    .styles_container__1XDDi .styles_content__1uXiw .styles_container_error__3mo2b {
      text-align: center;
      margin: 10px; }

.styles_container_submit__1QefU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px; }
  .styles_container_submit__1QefU .styles_box_two_options__1ZGAV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .styles_container_submit__1QefU .styles_box_two_options__1ZGAV .styles_button__2Z1jM {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      min-width: 155px;
      max-width: 270px; }
  .styles_container_submit__1QefU .styles_button_alone__3vBP4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center; }

@media (min-width: 701px) {
  .styles_grid_container__1TN6R {
    width: 700px;
    max-width: 1920px; } }

.styles_container__3O6yM {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3O6yM .styles_content__3Uo8_ {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__3O6yM .styles_content__3Uo8_ {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        margin-left: 0px;
        margin-right: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__3O6yM .styles_content__3Uo8_ .styles_container_title__28mS6 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__3O6yM .styles_content__3Uo8_ .styles_container_title__28mS6 .styles_title__2Fh1V {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
        .styles_container__3O6yM .styles_content__3Uo8_ .styles_container_title__28mS6 .styles_title__2Fh1V .styles_container_step_description__1dZUG {
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 0px;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
      .styles_container__3O6yM .styles_content__3Uo8_ .styles_container_title__28mS6 .styles_comment__2WkV8 {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 300;
        text-align: left; }

.styles_box_item__2PYVR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_box_item__2PYVR .styles_box_row__2WlCR {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .styles_box_item__2PYVR .styles_box_row__2WlCR .styles_box_personal_data__6rzkV {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_box_item__2PYVR .styles_box_row__2WlCR .styles_box_email__AQErk {
      font-family: FuturaPT-Light;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-left: 25px; }
    .styles_box_item__2PYVR .styles_box_row__2WlCR .styles_box_photo_name__i1hNw {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; }
    .styles_box_item__2PYVR .styles_box_row__2WlCR .styles_box_name__2CYMu {
      font-family: FuturaPT;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal; }
  .styles_box_item__2PYVR .styles_box_status_data__2PetV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_box_item__2PYVR .styles_box_status_data__2PetV .styles_box_status__1qRoU {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; }
    .styles_box_item__2PYVR .styles_box_status_data__2PetV .styles_box_status_text__1Xtly {
      font-family: FuturaPT;
      font-size: 12px;
      text-align: right; }
    .styles_box_item__2PYVR .styles_box_status_data__2PetV .styles_box_status_date__Q27E- {
      font-family: FuturaPT;
      font-size: 11px;
      text-align: right;
      color: #757e95; }

.styles_box_image__39YdF {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.styles_container_inputs__3VjNq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding-right: 17px;
  margin-top: 40px; }
  .styles_container_inputs__3VjNq .styles_container_input__2Tm5W {
    margin-top: 0px; }

.styles_container_submit__9WcBg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 40px; }
  .styles_container_submit__9WcBg .styles_box_two_options__1SfpR {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .styles_container_submit__9WcBg .styles_box_two_options__1SfpR .styles_button__2YdYp {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      min-width: 155px;
      max-width: 270px; }
  .styles_container_submit__9WcBg .styles_button_alone__1l9CM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center; }

@media (min-width: 701px) {
  .styles_grid_container__2Vseo {
    padding-left: 6% !important; } }

.styles_container__TyHod {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__TyHod .styles_content__2B7yo {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    margin-left: 0 !important; }
    @media (min-width: 701px) {
      .styles_container__TyHod .styles_content__2B7yo {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        margin-left: auto; } }
    .styles_container__TyHod .styles_content__2B7yo .styles_spec_item__2X539 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: stretch;
              justify-content: stretch;
      -webkit-align-items: center;
              align-items: center;
      padding: 0 20px 0 20px;
      margin: 20px 0 20px 0;
      height: 64px;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 #fff, inset 1px 1px 3px 0 #ebecf0;
      background-image: linear-gradient(280deg, #f4f4f5 119%, #fff 28%); }
      @media (min-width: 701px) {
        .styles_container__TyHod .styles_content__2B7yo .styles_spec_item__2X539 {
          margin: 0; } }
      .styles_container__TyHod .styles_content__2B7yo .styles_spec_item__2X539 .styles_title__2JoDh {
        margin-right: auto;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500; }
    .styles_container__TyHod .styles_content__2B7yo .styles_container_step_description__jZZ5- {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__TyHod .styles_content__2B7yo .styles_container_submit__1Czdz {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: auto;
      padding-top: 40px; }
      .styles_container__TyHod .styles_content__2B7yo .styles_container_submit__1Czdz .styles_box_two_options__1kddZ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__TyHod .styles_content__2B7yo .styles_container_submit__1Czdz .styles_box_two_options__1kddZ .styles_button__2-Tqk {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__TyHod .styles_content__2B7yo .styles_container_submit__1Czdz .styles_button_alone__3JedO {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  .styles_container__TyHod .styles_container_title__3gX_E {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
    .styles_container__TyHod .styles_container_title__3gX_E .styles_contract_reference__3760v {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95; }
    .styles_container__TyHod .styles_container_title__3gX_E .styles_contract_name__3Hc31 {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center; }

@media (min-width: 701px) {
  .styles_grid_container__3bwci {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }

.styles_container__10NBV {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__10NBV .styles_content__JfoQj {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 30px !important;
    height: -webkit-fill-available;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 701px) {
      .styles_container__10NBV .styles_content__JfoQj {
        margin-bottom: auto !important;
        margin-left: 0px;
        margin-right: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 {
      padding: 20px 40px;
      background-image: linear-gradient(to right, white, #DEE0E4);
      width: calc(100% - 40px);
      margin: 0px 0px 36px -20px;
      border-radius: 5px 5px 0px 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_userCounterpart__24m-J {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex: 1 1;
                flex: 1 1; }
        .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_userCounterpart__24m-J img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          background-color: black; }
        .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_userCounterpart__24m-J p {
          margin: 0px 0px 0px 12px;
          -webkit-flex: 1 1;
                  flex: 1 1;
          font-size: 18px;
          font-weight: bold; }
      .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_statusCounterpart__3HyQ_ {
        text-align: right;
        margin-bottom: -18px; }
        .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_statusCounterpart__3HyQ_ p {
          margin: 0px 0px 6px 0px;
          font-weight: bold;
          font-size: 15px;
          text-transform: uppercase; }
        .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_statusCounterpart__3HyQ_ .styles_iconCounterpart__3CTwp {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__10NBV .styles_content__JfoQj .styles_infoCounterpart__26NO1 .styles_statusCounterpart__3HyQ_ .styles_iconCounterpart__3CTwp p {
            margin: -9px 0px 6px -12px;
            font-weight: 100;
            font-size: 14px;
            opacity: 0.4; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_title__3TIlH {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_container_title__3TIlH .styles_title__1JZ4g {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_step_description__1CFO_ {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_contract_type__IOyUH {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%; }
    .styles_container__10NBV .styles_content__JfoQj .styles_title_form__lSuKb {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .styles_container__10NBV .styles_content__JfoQj .styles_subtitle_form__289n2 {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_container__10NBV .styles_content__JfoQj .styles_label_form__2Pnxl {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_input__2lzS6 {
        margin-top: 0px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_names__3Xz17 {
        display: -webkit-flex;
        display: flex; }
        .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_names__3Xz17 .styles_container_first_field__3rSKs, .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_names__3Xz17 .styles_container_last_field__1rJKq {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_names__3Xz17 .styles_container_last_field__1rJKq {
          margin-left: 17px; }
        .styles_container__10NBV .styles_content__JfoQj .styles_container_inputs__2Qesl .styles_container_names__3Xz17 .styles_submitRow__kQGWp {
          margin-left: 12px;
          width: 60px; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_error__TBho1 {
      text-align: center;
      margin: 10px; }
    .styles_container__10NBV .styles_content__JfoQj .styles_container_submit__2DYnL {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: 40px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_container_submit__2DYnL .styles_box_two_options__3lAtJ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__10NBV .styles_content__JfoQj .styles_container_submit__2DYnL .styles_box_two_options__3lAtJ .styles_button__3yspd {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_container_submit__2DYnL .styles_button_alone__2NLgd {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
    .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-bottom: 30px; }
      .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_button__3yspd {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: auto; }
      .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu {
        margin-bottom: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_title_list__1hMjy {
          text-transform: uppercase; }
        .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_desc_list__2S5wX {
          text-align: center;
          margin: 12px 0px 24px 0px;
          font-size: 18px; }
        .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_header_box__2NUsb {
            padding-left: 30px;
            height: 50px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-align-items: center;
                    align-items: center; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_header_box__2NUsb .styles_volume__3Trms {
              width: 70px; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_header_box__2NUsb .styles_date_range__3Yu3w {
              margin-left: 10px; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_header_box__2NUsb .styles_title__1JZ4g {
              font-family: FuturaPT-Medium;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.75px; }
          .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 {
            padding-left: 30px;
            height: 50px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-align-items: center;
                    align-items: center; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 .styles_volume__3Trms {
              width: 70px; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 .styles_date_range__3Yu3w {
              margin-left: 10px; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 .styles_title__1JZ4g {
              font-family: FuturaPT-Light;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.75px; }
            .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 .styles_actions__HvAKU {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              margin-left: auto;
              padding-right: 20px; }
              .styles_container__10NBV .styles_content__JfoQj .styles_list_content__2tiSn .styles_added_content__fDtFu .styles_added_list__2LOdB .styles_item_box__1Prr4 .styles_actions__HvAKU .styles_lblManage__WEhQ7 {
                color: black;
                text-transform: uppercase; }

.styles_container_propose_changes__1uv2y {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px !important; }
  .styles_container_propose_changes__1uv2y .styles_button_alone__2NLgd {
    display: -webkit-flex;
    display: flex;
    width: 100%; }

.styles_title_container__W7l7K {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%; }


@media (min-width: 701px) {
  .styles_grid_container__32Znm {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }

.styles_container__3C5pp {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3C5pp .styles_content___QXhc {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 30px !important;
    height: -webkit-fill-available;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 701px) {
      .styles_container__3C5pp .styles_content___QXhc {
        margin-bottom: auto !important;
        margin-right: 0px;
        margin-left: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_title__scb4p {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_container_title__scb4p .styles_title__TzIPC {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_step_description__25KeV {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_contract_type__1nIlG {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%; }
    .styles_container__3C5pp .styles_content___QXhc .styles_title_form__3qnjI {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .styles_container__3C5pp .styles_content___QXhc .styles_subtitle_form__1lsPr {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_container__3C5pp .styles_content___QXhc .styles_label_form__2i_p_ {
      padding: 15px 0px 15px 10px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: left; }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 .styles_container_input__2o4oK {
        margin-top: 0px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 .styles_container_names__ZOnZo {
        display: -webkit-flex;
        display: flex; }
        .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 .styles_container_names__ZOnZo .styles_container_first_field__3RGW9, .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 .styles_container_names__ZOnZo .styles_container_last_field__2OuF2 {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-flex-basis: 0;
                  flex-basis: 0; }
        .styles_container__3C5pp .styles_content___QXhc .styles_container_inputs__8d4w8 .styles_container_names__ZOnZo .styles_container_last_field__2OuF2 {
          margin-left: 17px; }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_error__bGG1t {
      text-align: center;
      margin: 10px; }
    .styles_container__3C5pp .styles_content___QXhc .styles_container_submit__2IdGU {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_container_submit__2IdGU .styles_box_two_options__3q3X1 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__3C5pp .styles_content___QXhc .styles_container_submit__2IdGU .styles_box_two_options__3q3X1 .styles_button__1ALRw {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px;
          padding: 0 10px 0 10px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_container_submit__2IdGU .styles_button_alone__1TOvc {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
    .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-bottom: 30px; }
      .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_button__1ALRw {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: auto; }
      .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 {
        margin-bottom: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-align-items: center;
                    align-items: center; }
            .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi .styles_data__1ddw0 {
              padding: 20px;
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: column;
                      flex-direction: column; }
              .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi .styles_data__1ddw0 .styles_label__16CmH {
                font-family: FuturaPT-Medium;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.75px;
                margin-bottom: 10px; }
              .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi .styles_data__1ddw0 .styles_description__3BHxy {
                font-family: FuturaPT-Light;
                font-size: 20px;
                font-weight: 300;
                line-height: 1.3; }
              .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi .styles_data__1ddw0 .styles_type__2BWDn {
                font-family: FuturaPT;
                font-size: 20px;
                line-height: 1.3; }
            .styles_container__3C5pp .styles_content___QXhc .styles_list_content__2KM9W .styles_added_content__38X48 .styles_added_list__jCOQS .styles_item_box__33zWi .styles_actions__25vJs {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              margin-left: auto; }

.styles_container_terms_item__1MVNl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 21px 0px;
  -webkit-align-items: flex-start;
          align-items: flex-start; }
  .styles_container_terms_item__1MVNl .styles_title__TzIPC {
    width: 40%;
    max-width: 200px; }
  .styles_container_terms_item__1MVNl .styles_title__TzIPC {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase; }
  .styles_container_terms_item__1MVNl .styles_container_data__17e_s {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0; }
    .styles_container_terms_item__1MVNl .styles_container_data__17e_s .styles_title__TzIPC {
      font-family: FuturaPT-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      margin-bottom: 8px; }
    .styles_container_terms_item__1MVNl .styles_container_data__17e_s .styles_description__3BHxy {
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44; }
    .styles_container_terms_item__1MVNl .styles_container_data__17e_s .styles_item__1kVRG {
      display: -webkit-flex;
      display: flex; }
      .styles_container_terms_item__1MVNl .styles_container_data__17e_s .styles_item__1kVRG .styles_label__16CmH {
        margin-right: 2px;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
      .styles_container_terms_item__1MVNl .styles_container_data__17e_s .styles_item__1kVRG .styles_label_text__2ADsO {
        font-family: FuturaPT-Light;
        font-size: 20px;
        line-height: 1.3; }
  .styles_container_terms_item__1MVNl .styles_container_actions__cBG7Q {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left: auto;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }

.styles_container_propose_changes__3WWKt {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px !important; }
  .styles_container_propose_changes__3WWKt .styles_button_alone__1TOvc {
    display: -webkit-flex;
    display: flex;
    width: 100%; }

.styles_title_container__2gZ7k {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%; }

.styles_terms_card__30ij1 {
  padding: 0 10px 0 10px; }

.styles_container_actions__cBG7Q {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  margin-top: auto; }
  @media (min-width: 701px) {
    .styles_container_actions__cBG7Q {
      margin-bottom: 25px; }
      .styles_container_actions__cBG7Q button {
        width: 340px !important;
        min-height: 40px !important;
        min-width: 155px !important;
        max-width: 270px !important; }
        .styles_container_actions__cBG7Q button span {
          min-height: 40px !important; } }

.styles_options__33wij {
  cursor: pointer;
  height: 100%;
  margin-right: 5px;
  font-family: FuturaPT-Demi;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center; }

@media (min-width: 701px) {
  .styles_grid_container__3TShU {
    padding-right: 90px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px; } }

.styles_container__L4plu {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__L4plu .styles_main_content__ikoDG {
    height: 100%;
    max-width: 100%; }
    .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg {
      background-color: #fff;
      margin-top: 0px;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 30px !important;
      height: -webkit-fill-available;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      @media (min-width: 701px) {
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg {
          margin-top: 120px !important;
          margin-bottom: auto !important;
          margin-left: 0px;
          margin-right: 0px;
          object-fit: contain;
          border-radius: 5px;
          box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_title__5mVPQ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 20px; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_title__5mVPQ .styles_title__3c9Z4 {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_step_description__1usQX {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_contract_type__3VTvu {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_title_form__2dZHh {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_subtitle_form__2i0qn {
        margin-top: 5px;
        font-family: FuturaPT-LightObl;
        font-size: 17px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_label_form__-oZfT {
        padding: 15px 0px 15px 10px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: left; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_inputs__3AF9e {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: stretch;
                align-items: stretch; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_inputs__3AF9e .styles_container_input__3HeqJ {
          margin-top: 0px; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_inputs__3AF9e .styles_container_names__3izEN {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_inputs__3AF9e .styles_container_names__3izEN .styles_container_first_field__1T-_w {
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            -webkit-flex-basis: 0;
                    flex-basis: 0; }
          .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_inputs__3AF9e .styles_container_names__3izEN .styles_container_last_field__3arwp {
            margin-left: 17px; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_error__3vFtq {
        text-align: center;
        margin: 10px; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_propose_changes__3ly23 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_propose_changes__3ly23 .styles_button_alone__1cPfV {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_submit__16FEz {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_submit__16FEz .styles_box_two_options__2_WvI {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: center;
                  justify-content: center; }
          .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_submit__16FEz .styles_box_two_options__2_WvI .styles_button__9fwFF {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            min-width: 155px;
            max-width: 270px; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_container_submit__16FEz .styles_button_alone__1cPfV {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center; }
      .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-bottom: 30px; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_button__9fwFF {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center;
          margin-top: auto; }
        .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko {
          margin-bottom: 20px;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM .styles_item_box__3xzwI {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              -webkit-align-items: center;
                      align-items: center; }
              .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM .styles_item_box__3xzwI .styles_data__1QYYT {
                padding: 20px 0px;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                        flex-direction: row;
                width: calc(100% - 30px);
                -webkit-align-items: center;
                        align-items: center; }
                .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM .styles_item_box__3xzwI .styles_data__1QYYT .styles_email__1yC5a {
                  font-family: FuturaPT-Light;
                  font-size: 20px;
                  font-weight: 300;
                  width: 50%;
                  text-overflow: ellipsis;
                  overflow: hidden; }
                .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM .styles_item_box__3xzwI .styles_data__1QYYT .styles_role__pFH8c {
                  width: 50%; }
              .styles_container__L4plu .styles_main_content__ikoDG .styles_content__3_tkg .styles_list_content__fAX7h .styles_added_content__2iKko .styles_added_list__1ATNM .styles_item_box__3xzwI .styles_actions__1M0-3 {
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                        flex-direction: row;
                margin-left: auto; }

@media (min-width: 701px) {
  .styles_grid_container__n744j {
    padding-right: 90px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }

.styles_container__3o_s1 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3o_s1 .styles_content__1Yvqy {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__3o_s1 .styles_content__1Yvqy {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        margin-left: 0px;
        margin-right: 0px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__3o_s1 .styles_content__1Yvqy .styles_container_title__3oeSZ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__3o_s1 .styles_content__1Yvqy .styles_container_title__3oeSZ .styles_title__11JRi {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__3o_s1 .styles_content__1Yvqy .styles_container_step_description__31UjP {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }

.styles_team_card_title_container__FRBFK {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .styles_team_card_title_container__FRBFK .styles_team_card_title__3VsAe {
    white-space: nowrap;
    font-family: FuturaPT;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.11px;
    text-align: left;
    color: black;
    text-transform: uppercase; }
  .styles_team_card_title_container__FRBFK .styles_team_card_action__3Yhnr {
    cursor: pointer;
    font-family: FuturaPT-Demi;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: 0.75px;
    text-align: right;
    color: black;
    text-transform: uppercase; }

.styles_team_card__icwYh {
  display: -webkit-flex;
  display: flex;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_team_card__icwYh .styles_container_step_description__1lWTI {
    margin: 30px 0px 29px 0px;
    font-family: FuturaPT-Light;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left; }
  .styles_team_card__icwYh .styles_container_step_title__25_Tv {
    margin: 25px 0px 26px 0px;
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px; }
  .styles_team_card__icwYh .styles_container_inputs__3GJdR {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch; }
    .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_input__1Aks2 {
      margin-top: 0px; }
    .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_names__3a61P {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_names__3a61P .styles_container_first_field__1W_A5 {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-flex-basis: 0;
                flex-basis: 0; }
      .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_names__3a61P .styles_container_last_field__1O5DZ {
        margin-left: 17px; }
    .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_error__R8_2x {
      text-align: center;
      margin: 10px; }
    .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_propose_changes__3WwAt {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_propose_changes__3WwAt .styles_button_alone__1UbCH {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
    .styles_team_card__icwYh .styles_container_inputs__3GJdR .styles_container_submit__2fR3H {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
  .styles_team_card__icwYh .styles_container_send_action__2Y_ft {
    margin-top: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_team_card__icwYh .styles_container_send_action__2Y_ft .styles_button_alone__1UbCH {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: center;
              justify-content: center; }
  .styles_team_card__icwYh .styles_list_content__3I0Da {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 30px; }
    .styles_team_card__icwYh .styles_list_content__3I0Da .styles_button__pSW_u {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: center;
              justify-content: center;
      margin-top: auto; }
    .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre {
      margin-bottom: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv .styles_item_box__3PdjP {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv .styles_item_box__3PdjP .styles_data__2_Abg {
            padding: 20px 0px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            width: calc(100% - 30px);
            -webkit-align-items: center;
                    align-items: center; }
            .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv .styles_item_box__3PdjP .styles_data__2_Abg .styles_email__1txjb {
              font-family: FuturaPT-Light;
              font-size: 20px;
              font-weight: 300;
              width: 50%;
              text-overflow: ellipsis;
              overflow: hidden; }
            .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv .styles_item_box__3PdjP .styles_data__2_Abg .styles_role__2ozB5 {
              width: 50%; }
          .styles_team_card__icwYh .styles_list_content__3I0Da .styles_added_content__1mdre .styles_added_list__7ArCv .styles_item_box__3PdjP .styles_actions__FvvfE {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            margin-left: auto; }

.styles_modal_content__1ukbc {
  background-color: #fff; }
  .styles_modal_content__1ukbc .styles_container_title__3dot9 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 20px; }
    .styles_modal_content__1ukbc .styles_container_title__3dot9 .styles_title__2k0zP {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.83px;
      text-align: center;
      color: #ffffff; }
  .styles_modal_content__1ukbc .styles_container_step_description__1lWTI {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }

@media (min-width: 701px) {
  .styles_grid_container__3fetJ {
    max-width: 1920px;
    margin-left: auto !important;
    margin-right: auto !important; } }

.styles_container__3fiZF {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3fiZF .styles_content__3_Sbz {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__3fiZF .styles_content__3_Sbz {
        margin-top: 120px !important;
        margin-bottom: auto !important;
        object-fit: contain;
        border-radius: 5px;
        margin-right: 0px;
        margin-left: 0px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__3fiZF .styles_content__3_Sbz .styles_container_title__IoqWo {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .styles_container__3fiZF .styles_content__3_Sbz .styles_container_title__IoqWo .styles_title__1HETk {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__3fiZF .styles_content__3_Sbz .styles_container_status__1bHMr {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      height: 18px;
      font-family: FuturaPT-Light;
      font-size: 14px;
      font-weight: 300;
      padding: 21px 0; }
  .styles_container__3fiZF .styles_container_title__IoqWo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden; }
    .styles_container__3fiZF .styles_container_title__IoqWo .styles_contract_reference__Iv5zW {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .styles_container__3fiZF .styles_container_title__IoqWo .styles_contract_name__1-Nci {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.styles_label_subchart__22aXM {
  width: 100%;
  background-color: #faf8f8;
  margin: 24px 0px 0px 0px;
  padding: 6px 12px;
  border-radius: 23px;
  background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
  font-family: FuturaPT-Light;
  font-size: 16px;
  text-align: center;
  color: black; }

.styles_container_chart__KDJwz {
  object-fit: contain;
  width: 100%; }
  @media (min-width: 701px) {
    .styles_container_chart__KDJwz {
      height: 250px;
      margin: 0; } }
  .styles_container_chart__KDJwz .styles_title__1HETk {
    padding: 15px 25px;
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: right; }

.styles_container_submit__1xD2B {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  margin-top: auto; }
  @media (min-width: 701px) {
    .styles_container_submit__1xD2B {
      margin-bottom: 25px; }
      .styles_container_submit__1xD2B button {
        width: 340px !important;
        min-height: 40px !important;
        min-width: 155px !important;
        max-width: 270px !important; }
        .styles_container_submit__1xD2B button span {
          min-height: 40px !important; } }
  .styles_container_submit__1xD2B .styles_options__2acyv {
    cursor: pointer;
    height: 100%;
    margin-right: 5px;
    font-family: FuturaPT-Demi;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .styles_container_submit__1xD2B .styles_box_two_options__2Cgn6 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    .styles_container_submit__1xD2B .styles_box_two_options__2Cgn6 .styles_button__3a3M9 {
      display: -webkit-flex;
      display: flex;
      min-width: 155px !important;
      max-width: 270px !important; }
  .styles_container_submit__1xD2B .styles_button_alone__vlXrC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center; }

.styles_container_terms_item__7TDWR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 21px 0px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .styles_container_terms_item__7TDWR .styles_title__1HETk, .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj {
    width: 50%; }
  .styles_container_terms_item__7TDWR .styles_title__1HETk {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }
  .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px 10px; }
    @media (min-width: 701px) {
      .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj {
        padding: 0; } }
    .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj .styles_title__1HETk {
      font-family: FuturaPT-Medium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.75px;
      margin-bottom: 8px; }
    .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj .styles_description__214zy {
      font-family: FuturaPT-Light;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.3; }
    .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj .styles_item__2Wca1 {
      display: -webkit-flex;
      display: flex; }
      .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj .styles_item__2Wca1 .styles_label__1VHB3 {
        margin-right: 2px;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
      .styles_container_terms_item__7TDWR .styles_container_data__1Q0Mj .styles_item__2Wca1 .styles_label_text__uIhqT {
        font-family: FuturaPT-Light;
        font-size: 20px;
        line-height: 1.3; }

.styles_terms_card__3gfMm {
  display: -webkit-flex;
  display: flex;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  -webkit-flex-direction: column;
          flex-direction: column; }

.styles_terms_card_title_container__2v541 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .styles_terms_card_title_container__2v541 .styles_terms_card_title__t4JWS {
    white-space: nowrap;
    font-family: FuturaPT;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.11px;
    text-align: left;
    color: black;
    text-transform: uppercase; }
  .styles_terms_card_title_container__2v541 .styles_terms_card_action__2RLlv {
    cursor: pointer;
    font-family: FuturaPT-Demi;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: 0.75px;
    text-align: right;
    color: black;
    text-transform: uppercase; }

.styles_team_card__1Huto {
  display: -webkit-flex;
  display: flex;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-color: white;
  box-sizing: border-box;
  border-top: 10px solid black;
  padding: 20px;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_team_card__1Huto .styles_team_card_title_container__3ninU {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 15px; }
    .styles_team_card__1Huto .styles_team_card_title_container__3ninU .styles_team_card_title__3lY2r {
      font-family: FuturaPT;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.11px;
      text-align: left;
      color: black;
      text-transform: uppercase; }
    .styles_team_card__1Huto .styles_team_card_title_container__3ninU .styles_team_card_action__1V5Za {
      cursor: pointer;
      font-family: FuturaPT-Demi;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.93;
      letter-spacing: 0.75px;
      text-align: right;
      color: black;
      text-transform: uppercase; }

.styles_container_step_description__2x7bQ {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 701px) {
    .styles_container_step_description__2x7bQ {
      margin-bottom: 30px;
      margin-top: 0px; }
      .styles_container_step_description__2x7bQ .styles_form_title__35p_Q {
        font-size: 20px;
        font-weight: bold; } }

.styles_contract_details_card_mobile__h2YLJ {
  margin: 10px 0 25px 0;
  overflow: hidden; }
  .styles_contract_details_card_mobile__h2YLJ .styles_counterpart_mobile__KXFVO {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    margin-right: 15px; }
  .styles_contract_details_card_mobile__h2YLJ .styles_name_mobile__2Ouv_ {
    margin-left: 10px;
    font-family: FuturaPT;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black; }

.styles_contract_details_card__2T4q6 {
  margin: 10px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%);
  overflow: hidden; }
  .styles_contract_details_card__2T4q6 .styles_title__1HETk {
    font-family: FuturaPT;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.89px;
    color: black;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .styles_contract_details_card__2T4q6 .styles_reference__1fLoS {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757e95;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  @media (min-width: 701px) {
    .styles_contract_details_card__2T4q6 {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .styles_contract_details_card__2T4q6 .styles_more_button__1W8Mr {
        min-width: 30px; } }
  .styles_contract_details_card__2T4q6 .styles_box_row__3hdP0 {
    margin-top: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .styles_contract_details_card__2T4q6 .styles_box_row__3hdP0 .styles_box_personal_data__2MOlQ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden; }
      .styles_contract_details_card__2T4q6 .styles_box_row__3hdP0 .styles_box_personal_data__2MOlQ .styles_box_email__2StQG {
        font-family: FuturaPT-Light;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .styles_contract_details_card__2T4q6 .styles_box_row__3hdP0 .styles_box_personal_data__2MOlQ .styles_box_photo_name__2MTtU {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center; }
        .styles_contract_details_card__2T4q6 .styles_box_row__3hdP0 .styles_box_personal_data__2MOlQ .styles_box_photo_name__2MTtU .styles_box_name__Qn_Xc {
          font-family: FuturaPT;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }

.styles_box_image__3khYJ {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.styles_container__2zzIJ {
  margin-top: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .styles_container__2zzIJ .styles_content_box__2lytm {
    margin: 10px;
    background-color: #000;
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc; }
    .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data__2dY3f, .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT {
      padding: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data__2dY3f .styles_box_row__2_4_Q, .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data__2dY3f .styles_box_row__2_4_Q .styles_label__3DRHe, .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q .styles_label__3DRHe {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          font-family: FuturaPT;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff; }
        .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data__2dY3f .styles_box_row__2_4_Q .styles_data__3Imo-, .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q .styles_data__3Imo- {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #fff; }
    .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT {
      padding: 15px; }
      .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q {
        margin-bottom: 5px; }
        .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q .styles_label__3DRHe, .styles_container__2zzIJ .styles_content_box__2lytm .styles_box_data_small__293vT .styles_box_row__2_4_Q .styles_data__3Imo- {
          font-size: 18px; }

.styles_offTakeItem__18U16, .styles_offTakeItemActive__OfiZw {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px 0 10px 0;
  cursor: pointer; }
  @media (min-width: 701px) {
    .styles_offTakeItem__18U16, .styles_offTakeItemActive__OfiZw {
      margin: 0px 0 0px 0; } }
  .styles_offTakeItem__18U16 .styles_date_range__2F8YK, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 200px;
    padding-left: 20px; }
    .styles_offTakeItem__18U16 .styles_date_range__2F8YK .styles_next__1aFT4, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK .styles_next__1aFT4 {
      width: 4px;
      height: 7px;
      margin: 0 17px 0 17px; }
    .styles_offTakeItem__18U16 .styles_date_range__2F8YK .styles_date__2HMLA, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK .styles_date__2HMLA {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_offTakeItem__18U16 .styles_date_range__2F8YK .styles_date__2HMLA .styles_day__2SI0y, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK .styles_date__2HMLA .styles_day__2SI0y {
        font-family: FuturaPT-Demi;
        font-size: 25px;
        letter-spacing: 1.25px;
        text-align: center; }
      .styles_offTakeItem__18U16 .styles_date_range__2F8YK .styles_date__2HMLA .styles_month__1aJEb, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK .styles_date__2HMLA .styles_month__1aJEb {
        font-family: FuturaPT;
        font-size: 12px;
        letter-spacing: 0.6px;
        text-align: center; }
      .styles_offTakeItem__18U16 .styles_date_range__2F8YK .styles_date__2HMLA .styles_year__KX1JM, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK .styles_date__2HMLA .styles_year__KX1JM {
        font-family: FuturaPT;
        font-size: 12px;
        text-align: center;
        color: #757e95; }
  .styles_offTakeItem__18U16 .styles_volume__2TJBN, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: relative;
    padding: 0 10px 0 10px;
    margin-left: 40px;
    width: 100%;
    height: 84px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0 #d4d7df, -2px -3px 8px 0 white, inset 1px 1px 3px 0 #ebecf0;
    background-image: linear-gradient(289deg, #f4f4f5 118%, white 28%); }
    @media (min-width: 701px) {
      .styles_offTakeItem__18U16 .styles_volume__2TJBN, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN {
        box-shadow: none;
        background-image: none; } }
    .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_data__3Kw-8, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_data__3Kw-8 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_data__3Kw-8 .styles_label__1t8MB, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_data__3Kw-8 .styles_label__1t8MB {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.75px;
        margin-right: 5px; }
      .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_data__3Kw-8 .styles_text__3Iezv, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_data__3Kw-8 .styles_text__3Iezv {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 500; }
    .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_next__1aFT4, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_next__1aFT4 {
      width: 5px;
      height: 10px;
      margin-left: auto; }
    .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_status__SuOeh, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_status__SuOeh {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      height: 100%;
      width: 15px;
      left: -18.5px;
      position: absolute; }
      .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_status__SuOeh .styles_divider__1okBA, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_status__SuOeh .styles_divider__1okBA {
        position: absolute;
        z-index: 0;
        height: 60%;
        top: 20%;
        background-color: lightgray; }
      .styles_offTakeItem__18U16 .styles_volume__2TJBN .styles_status__SuOeh .styles_circle__9yU-T, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN .styles_status__SuOeh .styles_circle__9yU-T {
        z-index: 1;
        width: 15px;
        height: 15px;
        border-radius: 50%; }

.styles_offTakeItemActive__OfiZw {
  background-color: black;
  color: white; }
  .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK svg.styles_next__1aFT4 path, .styles_offTakeItemActive__OfiZw .styles_date_range__2F8YK svg.styles_next__1aFT4 g {
    stroke: white;
    opacity: 1; }
  .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN {
    box-shadow: none;
    background-image: none;
    background-color: black; }
    .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN svg.styles_next__1aFT4 path, .styles_offTakeItemActive__OfiZw .styles_volume__2TJBN svg.styles_next__1aFT4 g {
      stroke: white;
      opacity: 1; }

.styles_list__199ZM {
  display: -webkit-flex;
  display: flex;
  margin: 20px 10px 20px 10px;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; }
  @media (min-width: 701px) {
    .styles_list__199ZM {
      overflow-y: auto;
      max-height: 400px;
      padding-top: 0px;
      margin-top: 20px; } }
  .styles_list__199ZM .styles_title__Oojw2 {
    margin: 16px 0 14px 0;
    height: 55px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: FuturaPT-Demi;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center; }

.styles_container__1GQbD {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__1GQbD .styles_content__1Zf30 {
    background-color: #fff;
    margin-top: 0px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__1GQbD .styles_content__1Zf30 {
        margin-top: 120px !important;
        margin-bottom: auto !important; } }
    .styles_container__1GQbD .styles_content__1Zf30 .styles_list__39YNQ {
      display: -webkit-flex;
      display: flex;
      padding: 20px 10px 20px 10px;
      -webkit-flex-direction: column;
              flex-direction: column; }

.styles_container_player__1vfUb {
  height: 100%;
  background-color: rgba(34, 36, 42, 0.9);
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999999999;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 1%;
  padding-bottom: 20px;
  text-align: center;
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  align-items: center; }
  .styles_container_player__1vfUb .styles_close__2wn5F {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .styles_container_player__1vfUb .styles_player__3bTg0 {
    position: relative;
    margin: 20px; }

.styles_title__uZWG6, .styles_subtitle__LBver {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: FuturaPT-Medium;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.17px;
  text-align: center;
  color: black; }

.styles_title__uZWG6 {
  font-size: 35px;
  font-weight: bold; }

.styles_subtitle__LBver {
  font-size: 18px;
  font-weight: 300;
  font-family: FuturaPT-Light; }

.styles_container_image__2p3tu {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 50px; }
  @media (min-width: 701px) {
    .styles_container_image__2p3tu {
      margin-top: 50px; } }

.styles_first_step_title__2ha3P, .styles_first_step_description__3qL3M {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
  font-family: FuturaPT-Medium;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: center;
  color: black; }

.styles_first_step_description__3qL3M {
  font-size: 18px;
  font-weight: 300;
  font-family: FuturaPT-Light;
  text-align: center;
  max-width: 430px; }

.styles_container_step_description__1Tc79 {
  padding: 11px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .styles_container_step_description__1Tc79 .styles_hidden_box__13VGw {
    margin-top: 200px;
    width: 100%;
    height: 1px; }
  .styles_container_step_description__1Tc79 .styles_step_number__2BDoz {
    margin-top: 35px;
    margin-bottom: 25px;
    width: 50px;
    height: 50px;
    border: solid 1px;
    border-radius: 50%; }
  .styles_container_step_description__1Tc79 .styles_step_number_label__3v0-6 {
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__1Tc79 .styles_step_title__21Vxa {
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__1Tc79 .styles_step_description__1jNpV {
    font-family: FuturaPT-LightObl;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000; }
  .styles_container_step_description__1Tc79 .styles_step_description_with_link__2jXYQ {
    font-family: FuturaPT-Light;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: #000000; }
    .styles_container_step_description__1Tc79 .styles_step_description_with_link__2jXYQ .styles_link__1WE2d {
      font-family: FuturaPT-Demi;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #000; }

.styles_first_add_description__3SYYV {
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 40px;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%); }
  @media (min-width: 701px) {
    .styles_first_add_description__3SYYV {
      position: fixed;
      bottom: 30px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      -webkit-transform: translate(88%, 0%);
              transform: translate(88%, 0%); } }
  .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 701px) {
      .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz {
        -webkit-flex-direction: row;
                flex-direction: row; } }
    @media (min-width: 701px) {
      .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_first_add_wrapper__3JQ-k {
        min-width: 210px;
        margin-bottom: 95px; } }
    .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_first_add_description__3SYYV {
      font-family: FuturaPT-Light;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_first_add_title__2a6_g {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5px;
      font-family: FuturaPT-Medium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.83px;
      text-align: center; }
      @media (min-width: 701px) {
        .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_first_add_title__2a6_g {
          margin-bottom: 10px;
          padding-left: 0px;
          padding-right: 0px; } }
    .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_first_add_description__3SYYV {
      font-family: FuturaPT-Light;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_add_your_first_task__1qYkx {
      font-family: FuturaPT;
      font-size: 16px;
      line-height: 0.38;
      text-align: center;
      color: #757e95; }
    .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_icon_container__lRu3B {
      width: 100%;
      margin-top: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      margin-bottom: 15px;
      margin-right: 180px; }
      .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_icon_container__lRu3B .styles_first_add_arrow__1msjt img {
        height: 100%;
        width: 100%;
        object-fit: fill; }
      .styles_first_add_description__3SYYV .styles_container_first_add_description__3XOmz .styles_icon_container__lRu3B .styles_first_add_arrow__1msjt svg {
        height: 100%;
        width: 100%; }

.styles_document_container__1KPZ_ {
  position: relative;
  min-width: 140px;
  max-width: 290px;
  height: 225px;
  max-height: 225px;
  padding: 10px 0 0;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0 rgba(177, 181, 198, 0.77), -2px -3px 8px 0 #fafafc;
  background-image: linear-gradient(316deg, #f4f4f5 84%, #ffffff);
  overflow: hidden; }
  .styles_document_container__1KPZ_ .styles_status__3nQSt {
    z-index: 1;
    position: absolute;
    left: 10px; }
  .styles_document_container__1KPZ_ .styles_document_content__2viu6 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 155px; }
    .styles_document_container__1KPZ_ .styles_document_content__2viu6 .styles_rfp__3gytl {
      z-index: 1;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding-right: 10px;
      padding-left: 10px;
      height: 17px;
      border-radius: 23px;
      background-color: rgba(76, 72, 143, 0.64);
      position: absolute;
      right: 10px;
      top: 10px;
      font-family: FuturaPT-Demi;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: white; }
    .styles_document_container__1KPZ_ .styles_document_content__2viu6 .styles_document__1vpdz {
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .styles_document_container__1KPZ_ .styles_document_content__2viu6 .styles_document__1vpdz img {
        max-height: 225px;
        max-width: 143px; }
      .styles_document_container__1KPZ_ .styles_document_content__2viu6 .styles_document__1vpdz canvas {
        height: 155px !important;
        width: 120px !important; }
    .styles_document_container__1KPZ_ .styles_document_content__2viu6 .styles_upload__3bYJC {
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
  .styles_document_container__1KPZ_ .styles_document_actions__3-RaT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    width: calc(100% - 20px);
    height: 50px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 -1px 2px 0 #ebecf0;
    background-color: #ffffff;
    bottom: 0;
    position: absolute; }
    .styles_document_container__1KPZ_ .styles_document_actions__3-RaT .styles_document_name__16jNn {
      font-family: FuturaPT-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal; }
    .styles_document_container__1KPZ_ .styles_document_actions__3-RaT .styles_document_date__3abfe {
      font-family: FuturaPT;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757e95;
      margin-top: 5px; }
  .styles_document_container__1KPZ_ .styles_error_type_doc__3d8N8 {
    width: 80%;
    object-fit: cover; }

.styles_full_item__zDd59 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: relative; }

.styles_item__3g3uc {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 0px;
  cursor: 'pointer'; }
  @media (min-width: 701px) {
    .styles_item__3g3uc {
      width: 90%;
      padding: 21px 20px 20px 30px;
      object-fit: contain;
      border-radius: 5px;
      background-color: white; } }
  .styles_item__3g3uc .styles_date_container__wteAy {
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .styles_item__3g3uc .styles_date_container__wteAy .styles_date__3qkIa {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .styles_item__3g3uc .styles_date_container__wteAy .styles_date__3qkIa .styles_tbd__2qW6R {
        font-family: FuturaPT-Demi;
        font-size: 14px;
        letter-spacing: 1.25px;
        text-align: center; }
      .styles_item__3g3uc .styles_date_container__wteAy .styles_date__3qkIa .styles_day__3ZYLs {
        font-family: FuturaPT-Demi;
        font-size: 25px;
        letter-spacing: 1.25px;
        text-align: center; }
      .styles_item__3g3uc .styles_date_container__wteAy .styles_date__3qkIa .styles_month__1_cfs {
        font-family: FuturaPT;
        font-size: 12px;
        letter-spacing: 0.6px;
        text-align: center; }
      .styles_item__3g3uc .styles_date_container__wteAy .styles_date__3qkIa .styles_year__3RZkq {
        font-family: FuturaPT;
        font-size: 12px;
        text-align: center;
        color: #757e95; }
  .styles_item__3g3uc .styles_data__p_LTd {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%; }
    .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 5px; }
      .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_next__3vldP {
        margin-left: auto;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
      .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_nomination_info__2wUjw {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
        margin-left: 40px; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_nomination_info__2wUjw .styles_info_row__aHaBA {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center;
          margin-bottom: 5px;
          margin-top: 5px; }
          .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_nomination_info__2wUjw .styles_info_row__aHaBA .styles_label__Udzez {
            margin-right: 5px;
            font-family: FuturaPT;
            font-size: 18px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            white-space: nowrap; }
          .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_nomination_info__2wUjw .styles_info_row__aHaBA .styles_text__1CE1I {
            font-family: FuturaPT-Light;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            white-space: nowrap; }
      .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_task_info__2TIBg {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_task_info__2TIBg .styles_task_type__2khqL {
          height: 25px;
          padding: 0 10px 0 10px;
          border-radius: 23px;
          border: solid 1px black;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          font-family: FuturaPT;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_task_info__2TIBg .styles_task_description__1p38P {
          margin-top: 5px;
          font-family: FuturaPT-Demi;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1px; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_task_info__2TIBg .styles_task_reference__16DJr {
          margin-top: 5px;
          font-family: FuturaPT;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #757e95; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_first_row__1Xm3g .styles_task_info__2TIBg .styles_task_collaborators__29BiW {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap; }
    .styles_item__3g3uc .styles_data__p_LTd .styles_second_row__s5BYl {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-top: 5px; }
      .styles_item__3g3uc .styles_data__p_LTd .styles_second_row__s5BYl .styles_requirements__3UqEf {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_item__3g3uc .styles_data__p_LTd .styles_second_row__s5BYl .styles_requirements__3UqEf .styles_task_requirement__29O8r {
          font-family: FuturaPT-Medium;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.6px; }

.styles_task_status__2Ke4j {
  margin-right: 20px;
  display: -webkit-flex;
  display: flex;
  font-family: FuturaPT;
  font-size: 14px;
  text-align: right;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: left; }

.styles_box_image__8Mtnz {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 5px; }

.styles_cont_floating_button__ANE4P {
  position: absolute;
  right: -54px;
  top: 0px; }

.styles_floating_button__3biAr {
  width: 25px;
  padding: 20px 12px;
  border-radius: 24.5px;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.styles_floating_actions__1CRyD {
  position: absolute;
  width: 25px;
  right: -24px;
  top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .styles_floating_actions__1CRyD svg {
    position: relative;
    left: 3px;
    top: 2px; }
  .styles_floating_actions__1CRyD button {
    margin: 5px;
    background-color: black;
    width: 30px;
    height: 30px; }
    .styles_floating_actions__1CRyD button:hover {
      background-color: black; }

.styles_subtasks_container__39GdW {
  position: relative;
  width: 90%;
  padding: 21px 20px 20px 30px;
  object-fit: contain;
  display: -webkit-flex;
  display: flex; }
  .styles_subtasks_container__39GdW .styles_timeline__1RCdf {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .styles_subtasks_container__39GdW .styles_timeline__1RCdf .styles_circle_filled__3G8pw {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      background-color: black;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px double white; }
    .styles_subtasks_container__39GdW .styles_timeline__1RCdf .styles_circle_empty__3eA90 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid black; }
    .styles_subtasks_container__39GdW .styles_timeline__1RCdf .styles_line_solid__2FHXF::before {
      content: "";
      margin-top: 22px;
      height: calc(100% + 22px);
      width: 1px;
      position: absolute;
      border-right: 1px solid lightgray; }
    .styles_subtasks_container__39GdW .styles_timeline__1RCdf .styles_line_dashed__2rSSr::before {
      content: "";
      margin-top: 22px;
      height: calc(100% + 22px);
      width: 1px;
      position: absolute;
      border-right: 1px dashed lightgray; }
  .styles_subtasks_container__39GdW .styles_task_container__2QccD {
    padding: 0 0 40px 40px;
    width: calc(100% - 70px);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY .styles_subtask_type__Hzv0o {
        height: 25px;
        padding: 0 10px 0 10px;
        margin-right: 10px;
        border-radius: 23px;
        border: solid 1px black;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        font-family: FuturaPT;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY .styles_right_container__1rGVc {
        margin-left: auto;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY .styles_right_container__1rGVc .styles_collaborators_container__1ZR8M {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          margin-right: 10px; }
          .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY .styles_right_container__1rGVc .styles_collaborators_container__1ZR8M .styles_user_photo__qM-G6 {
            width: 20px;
            height: 20px;
            margin-left: -7px; }
        .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_first_row__ickkY .styles_right_container__1rGVc .styles_assign_task__1acYP {
          cursor: pointer;
          height: 25px;
          padding: 0 10px 0 10px;
          margin-right: 10px;
          border-radius: 23px;
          border: solid 1px #4c488f;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          font-family: FuturaPT;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4c488f; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_title__3MzUS {
      margin-top: 20px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: normal; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_description__1BVgI {
      margin-top: 25px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal; }
      .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_description__1BVgI .styles_subtask_description_bold__330vX {
        font-family: FuturaPT;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_subtask_subtitle__3BbKy {
      margin-top: 20px;
      font-family: FuturaPT-Medium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_documents_container__34CNi {
      display: -webkit-flex;
      display: flex;
      width: calc(100% - 20px);
      overflow-x: scroll;
      padding: 20px 0px 20px 3px;
      margin-left: 0px; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_datapoints_container__1fbam {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_datapoints_container__1fbam .styles_datapoint_container__2zAfN {
        display: -webkit-flex;
        display: flex;
        padding: 10px 0 10px 0;
        -webkit-align-items: center;
                align-items: center; }
        .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_datapoints_container__1fbam .styles_datapoint_container__2zAfN .styles_container_input__3Ga0_ {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: stretch;
                  align-items: stretch; }
          .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_datapoints_container__1fbam .styles_datapoint_container__2zAfN .styles_container_input__3Ga0_ .styles_rfp__2864k {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            padding-right: 10px;
            padding-left: 10px;
            height: 17px;
            border-radius: 23px;
            background-color: rgba(76, 72, 143, 0.64);
            position: absolute;
            margin-top: 29px;
            font-family: FuturaPT-Demi;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: white; }
        .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_datapoints_container__1fbam .styles_datapoint_container__2zAfN .styles_edit_button__3k-K_ {
          margin-left: 20px;
          height: 50px;
          width: 50px; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_actions_container__2QOVv {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      margin: 20px; }
      .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_actions_container__2QOVv .styles_box_two_options__1DuNU {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_actions_container__2QOVv .styles_box_two_options__1DuNU .styles_button__1ZPcm {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px;
          padding: 0 10px 0 10px; }
    .styles_subtasks_container__39GdW .styles_task_container__2QccD .styles_divider__1sxQ_ {
      bottom: 0px;
      left: 40px;
      position: absolute;
      width: calc(100% - 40px); }

.styles_nomination_status__3rxkM {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  height: 55px;
  width: calc(100% - 155px);
  margin: 35px 0 0;
  padding: 16px 78px;
  border-radius: 5px;
  background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
  font-family: FuturaPT;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; }

.styles_loaderItem__29P2Q {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2; }

@media screen and (min-width: 980px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px; }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px; } }

.styles_chart__oc5tu {
  max-width: 100%;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .styles_chart__oc5tu .styles_emptyIcon__EX3t2 {
    width: 90px;
    margin: -60px 0px 12px 60px; }
  .styles_chart__oc5tu .styles_haventDates__wzyjL {
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase; }
  .styles_chart__oc5tu .styles_btnAddDate__5sgb8 {
    font-family: FuturaPT-Demi;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    color: black;
    text-transform: uppercase;
    cursor: pointer; }
  .styles_chart__oc5tu .styles_status__qYb6e {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 18px;
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 25px;
    font-size: 12px;
    text-align: center;
    border-radius: 15.5px;
    white-space: nowrap;
    background-color: rgba(76, 72, 143, 0.64);
    color: white;
    font-family: FuturaPT-Medium;
    margin-bottom: 12px;
    text-transform: uppercase; }
  .styles_chart__oc5tu .styles_contStatus__3Of4Q {
    margin-bottom: 120px;
    margin-top: -30px;
    width: 100%; }
  .styles_chart__oc5tu .styles_lblStatus__VWWqU {
    font-family: FuturaPT-Medium;
    font-size: 24px;
    font-weight: 500;
    color: black;
    text-transform: uppercase; }
  .styles_chart__oc5tu .styles_subLblStatus__36Yds {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #757e95; }
    .styles_chart__oc5tu .styles_subLblStatus__36Yds svg {
      color: #757e95;
      margin-right: 6px; }
  .styles_chart__oc5tu .styles_time_line__136M6 {
    position: relative;
    margin: 0 30px 0 30px;
    height: 9px;
    border-radius: 4.5px;
    margin-bottom: 12px; }
    .styles_chart__oc5tu .styles_time_line__136M6 .styles_bar__1COnZ {
      display: -webkit-flex;
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4.5px;
      overflow: hidden;
      background-color: #F3F4F5;
      box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
    .styles_chart__oc5tu .styles_time_line__136M6 .styles_date_label__2lVwy {
      position: absolute;
      font-family: FuturaPT;
      font-size: 10px;
      text-align: right;
      margin-top: -14px; }
    .styles_chart__oc5tu .styles_time_line__136M6 .styles_date_line__3qntD {
      position: absolute;
      width: 1px;
      height: 23px;
      margin-top: -14px;
      background-color: #e3e3e3; }
  .styles_chart__oc5tu .styles_labels_box__1zsMk {
    margin-top: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 20px;
    padding-top: 20px; }
    .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-right: 5px;
      margin-left: 5px;
      position: relative; }
      .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row; }
        .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_received_circle__3B4u5, .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_nominated_circle__2WL8B, .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_white_circle__2pMk0 {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0 5px; }
        .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_received_circle__3B4u5 {
          background-color: #757e95; }
        .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_nominated_circle__2WL8B {
          background-color: #4c488f; }
        .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label_box__1hEVn .styles_white_circle__2pMk0 {
          background-color: #F3F4F5;
          box-shadow: inset 1px 1px 3px 0 #bdc1d1; }
      .styles_chart__oc5tu .styles_labels_box__1zsMk .styles_label_item__A38Fd .styles_label__2k-a3 {
        font-family: FuturaPT-Medium;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
        margin: 0px 3px; }
  .styles_chart__oc5tu .styles_labels_box2__3PXgC {
    width: 80%;
    margin-top: 30px; }
  .styles_chart__oc5tu .styles_priceLabel__2t6Y- {
    position: absolute;
    bottom: -24px;
    color: #757e95;
    font-family: FuturaPT-Medium;
    font-size: 16px;
    width: 100%;
    text-align: center; }
  .styles_chart__oc5tu .styles_flexRow__bylM4 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    margin-left: 0;
    width: 100%; }
    .styles_chart__oc5tu .styles_flexRow__bylM4 div {
      height: 100%;
      border-radius: 12px;
      position: absolute;
      left: 0;
      top: 0; }
  .styles_chart__oc5tu .styles_rowDates__2XhGH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 6px;
    height: 14px; }
    .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRow__28BFO, .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRowFloat__3MIsv {
      font-family: FuturaPT-Light;
      font-size: 13px;
      color: black;
      position: relative; }
      .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRow__28BFO span, .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRowFloat__3MIsv span {
        position: absolute;
        top: -12px;
        font-family: FuturaPT-Medium;
        font-size: 13px;
        white-space: nowrap; }
      .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRow__28BFO .styles_lastRow__2OAbl, .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRowFloat__3MIsv .styles_lastRow__2OAbl {
        right: 0px; }
    .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_dateInRowFloat__3MIsv {
      position: absolute;
      z-index: 1; }
    .styles_chart__oc5tu .styles_rowDates__2XhGH .styles_lineTask__2vTbe {
      position: absolute;
      border-left: 2px solid #e3e3e3;
      height: 29px;
      width: 1px;
      left: -3px;
      top: 0px; }
  .styles_chart__oc5tu .styles_contLine__LQmY_ {
    position: absolute;
    bottom: -20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
    margin-left: -5px; }
  .styles_chart__oc5tu .styles_justLeft__6SkS8 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-left: 0px; }
  .styles_chart__oc5tu .styles_justRight__12WvE {
    -webkit-align-items: flex-end;
            align-items: flex-end; }
  .styles_chart__oc5tu .styles_lblToday__3sgD- {
    font-size: 18px;
    position: absolute;
    font-family: FuturaPT-Medium;
    white-space: nowrap;
    top: -42px;
    text-transform: uppercase; }
  .styles_chart__oc5tu .styles_dateToday__3nGDq {
    font-size: 11px;
    position: absolute;
    white-space: nowrap;
    top: -22px;
    text-transform: uppercase; }
  .styles_chart__oc5tu .styles_circleLine__31Q-i {
    width: 3px;
    height: 3px;
    border: 2px solid black;
    border-radius: 50%; }
  .styles_chart__oc5tu .styles_lineDate__Qsme7 {
    height: 55px;
    width: 1px;
    border-left: 2px solid black; }

.styles_grid_container__1mNKo {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }
  @media (min-width: 701px) {
    .styles_grid_container__1mNKo {
      max-width: 1920px;
      margin-left: auto !important;
      margin-right: auto !important; } }

.styles_container__3v9Ar {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__3v9Ar .styles_content__fXxIU {
    background-color: #fff;
    margin-top: 10px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__3v9Ar .styles_content__fXxIU {
        margin-bottom: auto !important;
        margin-right: 0px;
        margin-left: 0px;
        padding-bottom: 0px !important; } }
    .styles_container__3v9Ar .styles_content__fXxIU .styles_scroll_hidden__3RjeK {
      /* width */ }
      .styles_container__3v9Ar .styles_content__fXxIU .styles_scroll_hidden__3RjeK::-webkit-scrollbar {
        display: none; }
    .styles_container__3v9Ar .styles_content__fXxIU .styles_list__qC09P {
      display: -webkit-flex;
      display: flex;
      padding: 20px 10px 20px 10px;
      -webkit-flex-direction: column;
              flex-direction: column;
      position: relative; }
      @media (min-width: 701px) {
        .styles_container__3v9Ar .styles_content__fXxIU .styles_list__qC09P {
          margin: 0px 0px 0px -20px;
          padding: 30px 20px 0px 20px;
          max-height: calc(100% - 80px);
          overflow-y: auto; } }
      @media (min-width: 701px) {
        .styles_container__3v9Ar .styles_content__fXxIU .styles_list__qC09P .styles_list_container__xZDJD {
          overflow-y: auto;
          max-height: 665px; } }
      .styles_container__3v9Ar .styles_content__fXxIU .styles_list__qC09P .styles_active_item__2ZHto {
        background-color: black;
        color: white; }
    .styles_container__3v9Ar .styles_content__fXxIU .styles_loaderList__2Gnqn {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding-bottom: 30px; }
  .styles_container__3v9Ar .styles_mobile_box_contract__2ORI9 {
    margin-top: 20px;
    background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff);
    min-height: 50px;
    padding: 10px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .styles_container__3v9Ar .styles_mobile_box_contract__2ORI9 .styles_label__2nfy_ {
      font-family: FuturaPT;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-overflow: ellipsis; }
  .styles_container__3v9Ar .styles_container_add_button__30Z4W {
    right: 120px;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 60px;
    height: 60px;
    bottom: 27px;
    z-index: 1300; }
    @media (min-width: 701px) {
      .styles_container__3v9Ar .styles_container_add_button__30Z4W {
        bottom: 80px; } }
    .styles_container__3v9Ar .styles_container_add_button__30Z4W .styles_add_button__14AtT {
      height: 60px;
      width: 60px; }
      @media (min-width: 1700px) {
        .styles_container__3v9Ar .styles_container_add_button__30Z4W .styles_add_button__14AtT button::before {
          content: "";
          z-index: -1;
          background: linear-gradient(0deg, white 80%, rgba(255, 255, 255, 0) 100%);
          height: 380%;
          width: 300%;
          position: absolute;
          bottom: -130%;
          right: -1px; } }

.styles_contract_details_card__15plU {
  margin: 10px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
  background-image: linear-gradient(320deg, #f4f4f5 10%, white 60%);
  overflow: hidden; }
  .styles_contract_details_card__15plU .styles_title__3iYHw {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c9ccda; }
  .styles_contract_details_card__15plU .styles_reference__HvOpB {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  @media (min-width: 701px) {
    .styles_contract_details_card__15plU {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .styles_contract_details_card__15plU .styles_more_button__2M50I {
        min-width: 30px; } }

.styles_detail_column__3FTl8 {
  overflow: hidden;
  border: 3px solid black;
  border-radius: 0px 50px 0px 50px;
  padding-left: 5px !important;
  padding-right: 5px !important; }
  .styles_detail_column__3FTl8 .styles_close_button__26l-E {
    position: absolute;
    border-radius: 50%;
    background-color: black;
    width: 32px;
    height: 32px;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 10; }
  .styles_detail_column__3FTl8 .styles_detail_column_container__36_mh {
    max-height: 665px;
    overflow-y: auto;
    padding: 10px; }

.styles_taskFooterList__3bzrE {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  bottom: 0px;
  min-height: 60px;
  background: black;
  color: white;
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  border-radius: 0px 0px 5px 5px; }

.styles_iconAlert__8i2XF {
  width: 76px !important;
  height: 90px !important;
  margin: 0 auto;
  margin-bottom: -30px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

@media screen and (min-width: 980px) {
  /* width */
  ::-webkit-scrollbar {
    width: 5px; }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px; } }

.styles_box_image__3gFVc {
  background-color: #111111;
  border-radius: 50%;
  min-height: 22px;
  min-width: 22px;
  max-height: 22px;
  max-width: 22px;
  margin: 5px; }

.styles_container__26_I1 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__26_I1 .styles_content__qRU6F, .styles_container__26_I1 .styles_content_desktop__2hUyk {
    background-color: #fff;
    margin-top: 20px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important; }
    @media (min-width: 701px) {
      .styles_container__26_I1 .styles_content__qRU6F, .styles_container__26_I1 .styles_content_desktop__2hUyk {
        margin-bottom: auto !important; } }
    .styles_container__26_I1 .styles_content__qRU6F .styles_container_step_description__MABnJ, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_step_description__MABnJ {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_step_description__MABnJ .styles_title__2a0Q5, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_step_description__MABnJ .styles_title__2a0Q5 {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_row_tabs__3GCTe, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_row_tabs__3GCTe {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 12px; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_tabBtn__1Lfdu, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_tabBtn__1Lfdu {
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 3px 0px;
      margin-right: 24px;
      color: black;
      opacity: 0.5;
      white-space: nowrap; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_tabBtn__1Lfdu.styles_tabSelected__3MZ5v, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_tabBtn__1Lfdu.styles_tabSelected__3MZ5v {
        font-weight: bold;
        opacity: 1;
        border-bottom: 2px solid black; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_container_requirement_description__3ZoPn, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_requirement_description__3ZoPn {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_requirement_description__3ZoPn .styles_title_requirement__4mqZ5, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_requirement_description__3ZoPn .styles_title_requirement__4mqZ5 {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: left; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_collaborators__1WU5g, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_collaborators__1WU5g {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 30px; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_volume__34-xp, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_volume__34-xp {
        margin-top: 23px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_row__2wPeP, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_row__2wPeP {
        margin-bottom: 2px;
        display: -webkit-flex;
        display: flex;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_row__2wPeP .styles_description__g8K6v, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_row__2wPeP .styles_description__g8K6v {
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 20px; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_row__2wPeP .styles_reference_number__1YREm, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_row__2wPeP .styles_reference_number__1YREm {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_row__2wPeP .styles_completion_date__3y5HD, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_row__2wPeP .styles_completion_date__3y5HD {
          margin-left: 5px;
          font-family: FuturaPT-Medium;
          font-size: 20px;
          line-height: 1.3;
          font-weight: 500; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirements__2s3sQ, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirements__2s3sQ {
        margin-top: 20px;
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirements_description__If4mM, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirements_description__If4mM {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirement_list__3U6p9, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirement_list__3U6p9 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k .styles_requirement__1AecG, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k .styles_requirement__1AecG {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300; }
            .styles_container__26_I1 .styles_content__qRU6F .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k .styles_requirement__1AecG .styles_required_for_payment__lffFI, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_see_box__4TSmq .styles_requirement_list__3U6p9 .styles_requirement_item__1MM4k .styles_requirement__1AecG .styles_required_for_payment__lffFI {
              font-size: 16px; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_list__3m3Hi, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_list__3m3Hi {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: center;
                  align-items: center;
          margin-top: 20px;
          margin-bottom: 20px; }
          .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_label__2Am0O, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_label__2Am0O {
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            font-family: FuturaPT-Medium;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.75px;
            margin-left: 10px; }
          .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_rfp__279R-, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_rfp__279R- {
            font-family: FuturaPT;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal; }
          .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_item__1yLe6, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_list__3m3Hi .styles_item_container__1palM .styles_item__1yLe6 {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: space-between;
                    justify-content: space-between; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_add__3AnLw, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_add__3AnLw {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_add__3AnLw .styles_button__24oh9, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_add__3AnLw .styles_button__24oh9 {
          font-family: FuturaPT-Demi;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_input__1Yusf, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_input__1Yusf {
        margin-top: 0px; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_input__1Yusf .styles_check_container__4aYss, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_input__1Yusf .styles_check_container__4aYss {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-start;
                  justify-content: flex-start;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_input__1Yusf .styles_check_container__4aYss .styles_label__2Am0O, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_input__1Yusf .styles_check_container__4aYss .styles_label__2Am0O {
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_names__s52sR, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_names__s52sR {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_first_field__37Rho, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_first_field__37Rho {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-flex-basis: 0;
                  flex-basis: 0; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_last_field__1K20I, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_last_field__1K20I {
          margin-left: 17px; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_submit__Fk50_, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs__1B-Mc .styles_container_names__s52sR .styles_container_submit__Fk50_ {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs2__24uzU, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs2__24uzU {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_inputs2__24uzU div:first-child, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_inputs2__24uzU div:first-child {
        -webkit-flex: 1 1;
                flex: 1 1;
        margin-right: 12px; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_buttonAdd__QEVGs, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_buttonAdd__QEVGs {
      border-radius: 20px;
      background-color: black;
      border-radius: 20px;
      color: white;
      width: 30px;
      padding: 8px 14px;
      cursor: pointer; }
    .styles_container__26_I1 .styles_content__qRU6F .styles_container_submit__Fk50_, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_submit__Fk50_ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: auto;
      padding-top: 40px; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_submit__Fk50_ .styles_box_two_options__1BJvz, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_submit__Fk50_ .styles_box_two_options__1BJvz {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__26_I1 .styles_content__qRU6F .styles_container_submit__Fk50_ .styles_box_two_options__1BJvz .styles_button__24oh9, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_submit__Fk50_ .styles_box_two_options__1BJvz .styles_button__24oh9 {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__26_I1 .styles_content__qRU6F .styles_container_submit__Fk50_ .styles_button_alone__3Dm7b, .styles_container__26_I1 .styles_content_desktop__2hUyk .styles_container_submit__Fk50_ .styles_button_alone__3Dm7b {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  @media (min-width: 701px) {
    .styles_container__26_I1 .styles_content_desktop__2hUyk {
      margin-top: 0px !important; } }

.styles_box_image__2wNnK {
  background-color: #111111;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px; }

.styles_task_status__19hac {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-family: FuturaPT;
  font-size: 14px;
  text-align: right;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 20px; }

.styles_grid_container__2KmK4, .styles_grid_container_desktop__3ElCk {
  height: 100%; }
  @media (min-width: 701px) {
    .styles_grid_container__2KmK4, .styles_grid_container_desktop__3ElCk {
      padding-left: 70px !important; } }
  .styles_grid_container__2KmK4 .styles_grid_page__1Cz_I, .styles_grid_container_desktop__3ElCk .styles_grid_page__1Cz_I {
    height: 100%; }

@media (min-width: 701px) {
  .styles_grid_container_desktop__3ElCk {
    padding-left: 0px !important; } }

.styles_container__1J4Rj {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__1J4Rj .styles_main_content__3YMQG {
    height: 100%; }
    .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D {
      background-color: #fff;
      margin-top: 0px;
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      @media (min-width: 701px) {
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D {
          margin-bottom: auto !important;
          margin-left: 30px;
          object-fit: contain;
          border-radius: 5px; } }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_title__3ZzDE, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_title__3ZzDE {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 20px; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_title__3ZzDE .styles_title__Ebnhu, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_title__3ZzDE .styles_title__Ebnhu {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_step_description__3u_wj, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_step_description__3u_wj {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: column;
                flex-direction: column; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_contract_type__2F3yW, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_contract_type__2F3yW {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_title_form__3PrYr, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_title_form__3PrYr {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_subtitle_form__8vijY, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_subtitle_form__8vijY {
        margin-top: 5px;
        font-family: FuturaPT-LightObl;
        font-size: 17px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_label_form__1TDKN, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_label_form__1TDKN {
        padding: 15px 0px 15px 10px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: left; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_inputs__3D6hL, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_inputs__3D6hL {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: stretch;
                align-items: stretch; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_inputs__3D6hL .styles_container_input__gSD_l, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_inputs__3D6hL .styles_container_input__gSD_l {
          margin-top: 0px; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_inputs__3D6hL .styles_container_names__2A7TY, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_inputs__3D6hL .styles_container_names__2A7TY {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_inputs__3D6hL .styles_container_names__2A7TY .styles_container_first_field__2OWF2, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_inputs__3D6hL .styles_container_names__2A7TY .styles_container_first_field__2OWF2 {
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            -webkit-flex-basis: 0;
                    flex-basis: 0; }
          .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_inputs__3D6hL .styles_container_names__2A7TY .styles_container_last_field__hUx18, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_inputs__3D6hL .styles_container_names__2A7TY .styles_container_last_field__hUx18 {
            margin-left: 17px; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_error__dpGcG, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_error__dpGcG {
        text-align: center;
        margin: 10px; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_propose_changes__1G0zT, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_propose_changes__1G0zT {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_propose_changes__1G0zT .styles_button_alone__2or6Z, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_propose_changes__1G0zT .styles_button_alone__2or6Z {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_submit__3btED, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_submit__3btED {
        margin-top: auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_submit__3btED .styles_box_two_options__2IRw7, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_submit__3btED .styles_box_two_options__2IRw7 {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: center;
                  justify-content: center; }
          .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_submit__3btED .styles_box_two_options__2IRw7 .styles_button__2oPcJ, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_submit__3btED .styles_box_two_options__2IRw7 .styles_button__2oPcJ {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            min-width: 155px;
            max-width: 270px; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_container_submit__3btED .styles_button_alone__2or6Z, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_container_submit__3btED .styles_button_alone__2or6Z {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center; }
      .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-bottom: 30px; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_button__2oPcJ, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_button__2oPcJ {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-justify-content: center;
                  justify-content: center;
          margin-top: auto; }
        .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ {
          max-height: 171px !important;
          overflow: auto;
          margin-bottom: 20px;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              -webkit-align-items: center;
                      align-items: center; }
              .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe {
                padding: 20px 0px;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                        flex-direction: row;
                width: calc(100% - 30px);
                -webkit-align-items: center;
                        align-items: center; }
                .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe .styles_email__3fRRv, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe .styles_email__3fRRv {
                  font-family: FuturaPT-Light;
                  font-size: 20px;
                  font-weight: 300;
                  width: 50%;
                  text-overflow: ellipsis;
                  overflow: hidden; }
                .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe .styles_role__DGS00, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_data__1AiHe .styles_role__DGS00 {
                  width: 50%; }
              .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content__WpabJ .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_actions__1Wcm1, .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D .styles_list_content__2yQl9 .styles_added_content__3bo__ .styles_added_list__ZfJPY .styles_item_box__1qazp .styles_actions__1Wcm1 {
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                        flex-direction: row;
                margin-left: auto; }
    .styles_container__1J4Rj .styles_main_content__3YMQG .styles_content_desktop__1Rd6D {
      max-width: 700px !important;
      min-height: 650px !important;
      max-height: 800px !important;
      margin-top: 0px !important;
      margin-left: 0px !important; }

.styles_box_image__28p6Y {
  background-color: #111111;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-right: 5px;
  margin-left: 5px; }

.styles_container__3gQTD {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 1299;
  background-color: rgba(255, 255, 255, 0.9); }
  .styles_container__3gQTD .styles_box__1OS5m {
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    width: 711px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px 0px;
    max-height: 90%;
    overflow: scroll; }
    .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu {
      margin-top: 27px;
      width: 100%;
      height: 100%; }
      .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg {
        display: -webkit-flex;
        display: flex; }
        .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg .styles_container_button1__2Yxty, .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg .styles_container_button2__1RXvi {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
          .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg .styles_container_button1__2Yxty .styles_button__2pLhF, .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg .styles_container_button2__1RXvi .styles_button__2pLhF {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            -webkit-justify-content: center;
                    justify-content: center; }
        .styles_container__3gQTD .styles_box__1OS5m .styles_actions__2YSQu .styles_container_buttons__1WpWg .styles_container_button2__1RXvi {
          margin-left: 17px; }
    .styles_container__3gQTD .styles_box__1OS5m .styles_title__2dpA4 {
      margin-top: 30px;
      font-family: FuturaPT-Medium;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #ffffff; }
    .styles_container__3gQTD .styles_box__1OS5m .styles_message__2r8pn {
      white-space: pre-line;
      margin-top: 10px;
      font-family: FuturaPT-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }

.styles_container__2l9F0 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__2l9F0 .styles_content__3AQbz, .styles_container__2l9F0 .styles_content_desktop__1HUPh {
    background-color: #fff;
    margin-top: 20px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important; }
    @media (min-width: 701px) {
      .styles_container__2l9F0 .styles_content__3AQbz, .styles_container__2l9F0 .styles_content_desktop__1HUPh {
        margin-bottom: auto !important; } }
    .styles_container__2l9F0 .styles_content__3AQbz .styles_container_step_description__123tQ, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_step_description__123tQ {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_step_description__123tQ .styles_title__PrRGa, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_step_description__123tQ .styles_title__PrRGa {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
    .styles_container__2l9F0 .styles_content__3AQbz .styles_collaborators__1GfqN, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_collaborators__1GfqN {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 30px; }
    .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_volume__3QPvO, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_volume__3QPvO {
        margin-top: 23px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_row__1ODIt, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_row__1ODIt {
        margin-bottom: 2px;
        display: -webkit-flex;
        display: flex;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_row__1ODIt .styles_package_type__1sg3l, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_row__1ODIt .styles_package_type__1sg3l {
          margin-left: 5px;
          font-family: FuturaPT-Medium;
          font-size: 20px;
          line-height: 1.3;
          font-weight: 500; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_row__1ODIt .styles_location__3-soK, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_row__1ODIt .styles_location__3-soK {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_row__1ODIt .styles_reference_number__HIBoN, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_row__1ODIt .styles_reference_number__HIBoN {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_row__1ODIt .styles_time_frame__3_fFx, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_row__1ODIt .styles_time_frame__3_fFx {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_requirements__Py-ui, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_requirements__Py-ui {
        margin-top: 20px;
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_requirement_list__3o1UX, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_requirement_list__3o1UX {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_see_box__2kdK6 .styles_requirement_list__3o1UX .styles_requirement_item__1fjDs, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_see_box__2kdK6 .styles_requirement_list__3o1UX .styles_requirement_item__1fjDs {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300; }
    .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_input__3Qi4m, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_input__3Qi4m {
        margin-top: 0px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK {
        display: -webkit-flex;
        display: flex; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK .styles_check_container__SnFn2, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK .styles_check_container__SnFn2 {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK .styles_check_container__SnFn2 .styles_label__2IVNy, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_input_checkbox__1JliK .styles_check_container__SnFn2 .styles_label__2IVNy {
            font-family: FuturaPT;
            font-size: 20px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_section_title__Y1QD-, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_section_title__Y1QD- {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.83px;
        text-align: center; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_names__2TwBm, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_names__2TwBm {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_first_field__11j5j, .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_last_field__179rR, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_first_field__11j5j, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_last_field__179rR {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-flex-basis: 0;
                  flex-basis: 0; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_last_field__179rR, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_last_field__179rR {
          margin-left: 17px; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_submit__24hqa, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_inputs__10bvV .styles_container_names__2TwBm .styles_container_submit__24hqa {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
    .styles_container__2l9F0 .styles_content__3AQbz .styles_container_submit__24hqa, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_submit__24hqa {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: auto;
      padding-top: 40px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_submit__24hqa .styles_box_two_options__g1uwI, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_submit__24hqa .styles_box_two_options__g1uwI {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__2l9F0 .styles_content__3AQbz .styles_container_submit__24hqa .styles_box_two_options__g1uwI .styles_button__2jxp9, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_submit__24hqa .styles_box_two_options__g1uwI .styles_button__2jxp9 {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__2l9F0 .styles_content__3AQbz .styles_container_submit__24hqa .styles_button_alone__2I5u3, .styles_container__2l9F0 .styles_content_desktop__1HUPh .styles_container_submit__24hqa .styles_button_alone__2I5u3 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  @media (min-width: 701px) {
    .styles_container__2l9F0 .styles_content_desktop__1HUPh {
      margin-top: 0px !important; } }

.styles_box_image__tCaVX {
  background-color: #111111;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px; }

.styles_task_status__2TSiI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-family: FuturaPT;
  font-size: 14px;
  text-align: right;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 20px; }

.styles_container__CICTp {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__CICTp .styles_content__EAIQa, .styles_container__CICTp .styles_content_desktop__3PJmR {
    background-color: #fff;
    margin-top: 20px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important; }
    @media (min-width: 701px) {
      .styles_container__CICTp .styles_content__EAIQa, .styles_container__CICTp .styles_content_desktop__3PJmR {
        margin-bottom: auto !important; } }
    .styles_container__CICTp .styles_content__EAIQa .styles_container_step_description__3Oiwh, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_step_description__3Oiwh {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_step_description__3Oiwh .styles_title__1Lx3Y, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_step_description__3Oiwh .styles_title__1Lx3Y {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
    .styles_container__CICTp .styles_content__EAIQa .styles_row_tabs__1yGu2, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_row_tabs__1yGu2 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 12px; }
    .styles_container__CICTp .styles_content__EAIQa .styles_tabBtn__JTKU6, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_tabBtn__JTKU6 {
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 3px 0px;
      margin-right: 24px;
      color: black;
      opacity: 0.5;
      white-space: nowrap; }
      .styles_container__CICTp .styles_content__EAIQa .styles_tabBtn__JTKU6.styles_tabSelected__263aT, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_tabBtn__JTKU6.styles_tabSelected__263aT {
        font-weight: bold;
        opacity: 1;
        border-bottom: 2px solid black; }
    .styles_container__CICTp .styles_content__EAIQa .styles_container_requirement_description__1aaIm, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_requirement_description__1aaIm {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_requirement_description__1aaIm .styles_title_requirement__1Yn8d, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_requirement_description__1aaIm .styles_title_requirement__1Yn8d {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: left; }
    .styles_container__CICTp .styles_content__EAIQa .styles_collaborators__1xLNG, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_collaborators__1xLNG {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 30px; }
    .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_volume__1srcP, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_volume__1srcP {
        margin-top: 23px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_row__dsUvj, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_row__dsUvj {
        margin-bottom: 2px;
        display: -webkit-flex;
        display: flex;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3; }
        .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_row__dsUvj .styles_description__2gWBG, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_row__dsUvj .styles_description__2gWBG {
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 20px; }
        .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_row__dsUvj .styles_reference_number__1ZoOL, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_row__dsUvj .styles_reference_number__1ZoOL {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
        .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_row__dsUvj .styles_completion_date__3TzyK, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_row__dsUvj .styles_completion_date__3TzyK {
          margin-left: 5px;
          font-family: FuturaPT-Medium;
          font-size: 20px;
          line-height: 1.3;
          font-weight: 500; }
      .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirements__2GADA, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirements__2GADA {
        margin-top: 20px;
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
      .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirements_description__2mZls, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirements_description__2mZls {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirement_list__1dWTB, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirement_list__1dWTB {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3 {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3 .styles_requirement__71fNY, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3 .styles_requirement__71fNY {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300; }
            .styles_container__CICTp .styles_content__EAIQa .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3 .styles_requirement__71fNY .styles_required_for_payment__2Kl_V, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_see_box__3RgZe .styles_requirement_list__1dWTB .styles_requirement_item__11LP3 .styles_requirement__71fNY .styles_required_for_payment__2Kl_V {
              font-size: 16px; }
    .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_list__RspwG, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_list__RspwG {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: center;
                  align-items: center;
          margin-top: 20px;
          margin-bottom: 20px; }
          .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_label__jJ6oi, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_label__jJ6oi {
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            font-family: FuturaPT-Medium;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.75px;
            margin-left: 10px; }
          .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_rfp__2ojJN, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_rfp__2ojJN {
            font-family: FuturaPT;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal; }
          .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_item__2J7bY, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_list__RspwG .styles_item_container__23-kL .styles_item__2J7bY {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: space-between;
                    justify-content: space-between; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_add__3GrnX, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_add__3GrnX {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_add__3GrnX .styles_button__rU6uU, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_add__3GrnX .styles_button__rU6uU {
          font-family: FuturaPT-Demi;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input__3VIB_, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input__3VIB_ {
        margin-top: 0px; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input__3VIB_ .styles_check_container__2bPD0, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input__3VIB_ .styles_check_container__2bPD0 {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-start;
                  justify-content: flex-start;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input__3VIB_ .styles_check_container__2bPD0 .styles_label__jJ6oi, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input__3VIB_ .styles_check_container__2bPD0 .styles_label__jJ6oi {
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw {
        display: -webkit-flex;
        display: flex; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw .styles_check_container__2bPD0, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw .styles_check_container__2bPD0 {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center; }
          .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw .styles_check_container__2bPD0 .styles_label__jJ6oi, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_input_checkbox__2ooTw .styles_check_container__2bPD0 .styles_label__jJ6oi {
            font-family: FuturaPT;
            font-size: 20px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_names__25kSM, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_names__25kSM {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_volume__2nWcZ, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_volume__2nWcZ {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_first_field__2AZbv, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_first_field__2AZbv {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          -webkit-flex-basis: 0;
                  flex-basis: 0; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_last_field__1Wdh_, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_last_field__1Wdh_ {
          margin-left: 17px; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_submit__7tM4O, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs__yCqhd .styles_container_names__25kSM .styles_container_submit__7tM4O {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
    .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs2__2bZrz, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs2__2bZrz {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_inputs2__2bZrz div:first-child, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_inputs2__2bZrz div:first-child {
        -webkit-flex: 1 1;
                flex: 1 1;
        margin-right: 12px; }
    .styles_container__CICTp .styles_content__EAIQa .styles_buttonAdd__2fhQq, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_buttonAdd__2fhQq {
      border-radius: 20px;
      background-color: black;
      border-radius: 20px;
      color: white;
      width: 30px;
      padding: 8px 14px;
      cursor: pointer; }
    .styles_container__CICTp .styles_content__EAIQa .styles_container_submit__7tM4O, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_submit__7tM4O {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: auto;
      padding-top: 40px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_submit__7tM4O .styles_box_two_options__NEXau, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_submit__7tM4O .styles_box_two_options__NEXau {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__CICTp .styles_content__EAIQa .styles_container_submit__7tM4O .styles_box_two_options__NEXau .styles_button__rU6uU, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_submit__7tM4O .styles_box_two_options__NEXau .styles_button__rU6uU {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__CICTp .styles_content__EAIQa .styles_container_submit__7tM4O .styles_button_alone__NrxZ3, .styles_container__CICTp .styles_content_desktop__3PJmR .styles_container_submit__7tM4O .styles_button_alone__NrxZ3 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  @media (min-width: 701px) {
    .styles_container__CICTp .styles_content_desktop__3PJmR {
      margin-top: 0px !important; } }

.styles_box_image__2stHO {
  background-color: #111111;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px; }

.styles_task_status__3PI1h {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-family: FuturaPT;
  font-size: 14px;
  text-align: right;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 20px; }

.styles_container__1NcoC {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__1NcoC .styles_content__3BLRB {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__1NcoC .styles_content__3BLRB {
        margin-top: 120px !important;
        margin-bottom: auto !important; } }
    .styles_container__1NcoC .styles_content__3BLRB .styles_filter_group__2sMLK {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__1NcoC .styles_content__3BLRB .styles_filter_group__2sMLK .styles_filter_item__2pYP_ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        font-family: FuturaPT;
        font-size: 20px; }
    .styles_container__1NcoC .styles_content__3BLRB .styles_container_submit__IOVtG {
      padding-top: 40px;
      padding-bottom: 40px; }
      .styles_container__1NcoC .styles_content__3BLRB .styles_container_submit__IOVtG .styles_button__34FVx {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }

@media (min-width: 701px) {
  .styles_grid_container__2s9Ki {
    padding: 20px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px; } }

.styles_container__2_ASn {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__2_ASn .styles_content__GrBaD {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__2_ASn .styles_content__GrBaD {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: auto !important;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_title__aSP3m {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px;
      margin-bottom: -20px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_title__aSP3m .styles_title__1mkg4 {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff;
        margin-right: 12px; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_step_description__2_S9i {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 20px;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 10px;
      background-image: linear-gradient(282deg, rgba(117, 126, 149, 0.4) 0%, #fff); }
      .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_title__1mkg4 {
        margin-top: 10px;
        font-family: FuturaPT;
        font-size: 16px;
        color: #757e95; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_box_row__39VWF {
        margin-top: 5px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between; }
        .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_box_row__39VWF .styles_box_personal_data__3ow6M {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_email__IRoZH {
            font-family: FuturaPT-Light;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-left: 25px; }
          .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_photo_name__3pbYd {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
                    flex-direction: row;
            -webkit-align-items: center;
                    align-items: center; }
            .styles_container__2_ASn .styles_content__GrBaD .styles_box_contract_information__26eBs .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_photo_name__3pbYd .styles_box_name__2e1qE {
              font-family: FuturaPT;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 10px;
      padding-right: 10px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-bottom: 20px; }
        .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: space-between;
                  justify-content: space-between; }
          .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_personal_data__3ow6M {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_email__IRoZH {
              font-family: FuturaPT-Light;
              font-size: 16px;
              font-weight: 300;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-left: 25px; }
            .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_photo_name__3pbYd {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              -webkit-align-items: center;
                      align-items: center; }
              .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_personal_data__3ow6M .styles_box_photo_name__3pbYd .styles_box_name__2e1qE {
                font-family: FuturaPT;
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal; }
          .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_status_data__12IYy {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_status_data__12IYy .styles_box_status__LD1-C {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-direction: row;
                      flex-direction: row;
              -webkit-align-items: center;
                      align-items: center; }
              .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_status_data__12IYy .styles_box_status__LD1-C .styles_box_status_text__3PTA9 {
                font-family: FuturaPT;
                font-size: 12px;
                text-align: right; }
            .styles_container__2_ASn .styles_content__GrBaD .styles_box_collaborators__oOGrD .styles_box_item__2f4rZ .styles_box_row__39VWF .styles_box_status_data__12IYy .styles_box_status_date__DEUzh {
              font-family: FuturaPT;
              font-size: 11px;
              text-align: right;
              color: #757e95; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_title_form__21BAK {
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_subtitle_form__ddKV3 {
      margin-top: 5px;
      font-family: FuturaPT-LightObl;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ .styles_container_input__2ywoP {
        margin-top: 0px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ .styles_container_names__t68PJ {
        display: -webkit-flex;
        display: flex; }
        .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ .styles_container_names__t68PJ .styles_container_firstname__1UHlJ, .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ .styles_container_names__t68PJ .styles_container_lastname__2R5L- {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .styles_container__2_ASn .styles_content__GrBaD .styles_container_inputs__1t82_ .styles_container_names__t68PJ .styles_container_lastname__2R5L- {
          margin-left: 17px; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_error__17HyW {
      text-align: center;
      margin: 10px; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_propose_changes__1Tfw0 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_propose_changes__1Tfw0 .styles_button_alone__29L-S {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
    .styles_container__2_ASn .styles_content__GrBaD .styles_container_submit__3CZN4 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: 40px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_submit__3CZN4 .styles_box_two_options__3HAt6 {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__2_ASn .styles_content__GrBaD .styles_container_submit__3CZN4 .styles_box_two_options__3HAt6 .styles_button__7J7ja {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
      .styles_container__2_ASn .styles_content__GrBaD .styles_container_submit__3CZN4 .styles_button_alone__29L-S {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-justify-content: center;
                justify-content: center; }
  .styles_container__2_ASn .styles_container_title2__2OZyI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .styles_container__2_ASn .styles_container_title2__2OZyI .styles_first_row__2e01T {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center; }
      .styles_container__2_ASn .styles_container_title2__2OZyI .styles_first_row__2e01T .styles_title__1mkg4 {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #111; }
    .styles_container__2_ASn .styles_container_title2__2OZyI .styles_subtitle__2Idaf {
      font-family: FuturaPT;
      font-size: 15px;
      text-align: center;
      color: #757e95; }

.styles_box_image__TDTHB {
  background-color: #111111;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px; }

.styles_container_propose_changes__1Tfw0 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px !important; }
  .styles_container_propose_changes__1Tfw0 .styles_button_alone__29L-S {
    display: -webkit-flex;
    display: flex;
    width: 100%; }

.styles_title_container__3I0_9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%; }

.styles_container__2YIJm {
  margin-top: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .styles_container__2YIJm .styles_content_box__4PRWP {
    margin: 10px;
    background-color: #000;
    border-radius: 5px;
    box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc; }
    .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_title__1uqZ2 {
      padding-top: 14px;
      padding-bottom: 14px;
      font-family: FuturaPT-Medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #fff; }
    .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_line__1tiuF {
      width: 100%;
      height: 0.5px;
      background-color: #fff; }
    .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_data__19iIH {
      padding: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_data__19iIH .styles_box_row__L2lvE {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px; }
        .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_data__19iIH .styles_box_row__L2lvE .styles_label__1cIGh {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          font-family: FuturaPT;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff; }
        .styles_container__2YIJm .styles_content_box__4PRWP .styles_box_data__19iIH .styles_box_row__L2lvE .styles_data__PDQ6k {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #fff; }

@media (min-width: 701px) {
  .styles_grid_container__3xnvT {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }

.styles_container__2R7rL {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .styles_container__2R7rL .styles_content__uL0TJ {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important; }
    @media (min-width: 701px) {
      .styles_container__2R7rL .styles_content__uL0TJ {
        margin-bottom: auto !important;
        margin-left: 0px;
        margin-right: 0px; } }
    @media (min-width: 701px) {
      .styles_container__2R7rL .styles_content__uL0TJ .styles_confirmation_form_card__id-3J {
        padding-top: 35px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 40px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6; } }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_title__2lvZ1 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 20px; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_title__2lvZ1 .styles_title__2b8zN {
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        text-align: center;
        color: #ffffff; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_step_description__3Vqt9 {
      margin-top: 30px;
      margin-bottom: 20px;
      padding: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_step_description__3Vqt9 .styles_title__2b8zN {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        text-align: center; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_step_description__3Vqt9 .styles_title_pledge__2HkPL {
        font-family: FuturaPT;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: 1px;
        text-align: center; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_step_description__3Vqt9 .styles_contract_pledge__2Z0UQ {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        text-align: center; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_agreements__1-smD {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 20px;
      padding-right: 17px;
      font-family: FuturaPT-Light;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_inputs__NKqfH {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch;
      padding-right: 17px; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_inputs__NKqfH .styles_container_input__2zV_0 {
        margin-top: 0px; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_error__18CHO {
      text-align: center;
      margin: 10px; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_container_submit__1Hnte {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-top: 40px; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_container_submit__1Hnte .styles_box_two_options__3SdcQ {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center; }
        .styles_container__2R7rL .styles_content__uL0TJ .styles_container_submit__1Hnte .styles_box_two_options__3SdcQ .styles_button__1bVIv {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          min-width: 155px;
          max-width: 270px; }
    .styles_container__2R7rL .styles_content__uL0TJ .styles_box_item__ELyQY {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px; }
      .styles_container__2R7rL .styles_content__uL0TJ .styles_box_item__ELyQY .styles_box_row__3fUTW {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between; }
        .styles_container__2R7rL .styles_content__uL0TJ .styles_box_item__ELyQY .styles_box_row__3fUTW .styles_box_user_data__3K-jM {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .styles_container__2R7rL .styles_content__uL0TJ .styles_box_item__ELyQY .styles_box_row__3fUTW .styles_box_user_data__3K-jM .styles_box_name__2P-Lu {
            font-family: FuturaPT;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal; }
          .styles_container__2R7rL .styles_content__uL0TJ .styles_box_item__ELyQY .styles_box_row__3fUTW .styles_box_user_data__3K-jM .styles_box_email__1k9k9 {
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal; }

.styles_container__iTga8 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px; }
  .styles_container__iTga8 .styles_rowFilters__1trhO {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 18px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 18px; }
  .styles_container__iTga8 .styles_lblFilters__1rO27 {
    font-size: 18px;
    min-width: 72px; }
  .styles_container__iTga8 .styles_inputDate__3YwTE {
    min-width: 186px;
    max-width: 186px;
    margin: 0px 12px; }
    @media only screen and (min-width: 600px) {
      .styles_container__iTga8 .styles_inputDate__3YwTE {
        min-width: 160px;
        max-width: 170px; } }
  .styles_container__iTga8 .styles_inputNomination__3W-0a {
    min-width: 120px;
    max-width: 120px;
    margin: 0px 12px; }
  .styles_container__iTga8 .styles_inputType__Ei_mE {
    min-width: 120px;
    max-width: 120px;
    margin: 0px 12px; }
  .styles_container__iTga8 .styles_checkbox__2FT_O {
    margin: 0px 12px; }
  .styles_container__iTga8 .styles_blank__1NVi5 {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .styles_container__iTga8 .styles_btFilter__3wqqR {
    padding: 0px 0px 0px 24px;
    cursor: pointer; }
  .styles_container__iTga8 .styles_gridContainer__3-GBf {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .styles_container__iTga8 .styles_emptyTxt__1dd1S {
    text-align: center;
    padding: 30px;
    font-size: 18px;
    opacity: 0.75; }
  .styles_container__iTga8 .styles_docCard__1hJ9a {
    width: calc(100% / 5 - 24px);
    min-width: none;
    max-width: none;
    margin: 12px; }
  .styles_container__iTga8 .styles_table__2l0ul {
    margin-left: 0px;
    margin-bottom: 60px; }
  .styles_container__iTga8 .styles_hdtable__1O5hG {
    background-color: black; }
    .styles_container__iTga8 .styles_hdtable__1O5hG th {
      color: white;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px; }
  .styles_container__iTga8 .styles_rowMobile__r-KVu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowRfp__2MdvA {
      padding-bottom: 5px;
      display: -webkit-flex;
      display: flex; }
    .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowName__3j_1m {
      padding: 1px 0;
      font-family: FuturaPT-Medium;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black; }
    .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowNomination__2dYfW {
      padding: 1px 0;
      font-family: FuturaPT;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase; }
    .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowDate__sLLM3 {
      padding: 1px 0;
      font-family: FuturaPT;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757e95;
      text-transform: uppercase; }
    .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowActionsDoc__35aS0 {
      margin-left: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      position: relative; }
      .styles_container__iTga8 .styles_rowMobile__r-KVu .styles_rowActionsDoc__35aS0 .styles_actionBtn__kdT9R {
        padding: 4px 12px 0px 12px;
        cursor: pointer; }
  .styles_container__iTga8 .styles_rowName__3j_1m {
    font-weight: bold;
    font-size: 16px; }
  .styles_container__iTga8 .styles_rowDate__sLLM3 {
    font-size: 14px;
    color: #757e95;
    text-transform: uppercase; }
  .styles_container__iTga8 .styles_rowNomination__2dYfW {
    font-size: 14px;
    text-transform: uppercase; }
  .styles_container__iTga8 .styles_rowProfileDoc__1GpAC {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .styles_container__iTga8 .styles_rowProfileDoc__1GpAC img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 9px; }
    .styles_container__iTga8 .styles_rowProfileDoc__1GpAC p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      -webkit-flex: 1 1;
              flex: 1 1; }
  .styles_container__iTga8 .styles_rowActionsDoc__35aS0 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
    .styles_container__iTga8 .styles_rowActionsDoc__35aS0 .styles_actionBtn__kdT9R {
      padding: 4px 12px 0px 12px;
      cursor: pointer; }

.styles_badge__2D7gr {
  background-color: #8180AC;
  color: white;
  font-weight: bold;
  font-size: 10px;
  border-radius: 12px;
  padding: 3px 10px; }

.styles_ellipsisMenu__11fJ0 {
  background-color: white;
  box-shadow: 4px 3px 10px 0 #d6d6d6, -7px -7px 16px 0 #f6f6f6 !important;
  border-radius: 6px;
  margin-top: 48px;
  margin-left: 24px;
  overflow: visible !important; }
  .styles_ellipsisMenu__11fJ0 ul {
    padding: 0; }
  .styles_ellipsisMenu__11fJ0::before {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid white;
    content: "";
    position: absolute;
    right: 0px;
    top: -12px; }
  .styles_ellipsisMenu__11fJ0 .styles_itemMenuEllipsis__lhb9w {
    border-bottom: 1px solid #E2E2E2 !important;
    padding: 12px !important;
    white-space: nowrap;
    cursor: pointer;
    background-color: white !important; }
    .styles_ellipsisMenu__11fJ0 .styles_itemMenuEllipsis__lhb9w:hover, .styles_ellipsisMenu__11fJ0 .styles_itemMenuEllipsis__lhb9w.styles_Mui-focusVisible__3EHXP {
      background-color: white !important; }

.styles_filesearch__4RY05 {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.styles_contSearch__3KPa9 {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 6px;
  position: relative;
  overflow: visible;
  min-width: 90px;
  max-width: 460px; }
  .styles_contSearch__3KPa9 .styles_contSuggest__2--9c {
    position: absolute;
    top: 100%;
    background-color: white;
    z-index: 3;
    border-radius: 6px;
    left: 6px;
    width: 100%; }
    .styles_contSearch__3KPa9 .styles_contSuggest__2--9c .styles_itemSuggest__2ZHLY {
      padding: 12px;
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      font-weight: 600; }
    .styles_contSearch__3KPa9 .styles_contSuggest__2--9c .styles_itemSuggest__2ZHLY:hover {
      background-color: rgba(0, 0, 0, 0.1); }

.styles_navBar__clzOj {
  position: relative !important; }

.styles_container__3HXhG {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch; }

.styles_containerLogo__2PfY9 {
  padding: 20px; }

.styles_iframe__OWapG {
  width: 95%;
  margin: 0px;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: none; }

.styles_icon_container__2fBeR {
  -webkit-align-self: flex-start;
          align-self: flex-start; }

.styles_modal_box_content__12wFR {
  max-width: 700px;
  padding-top: 76px;
  padding-bottom: 93px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  align-items: center; }
  .styles_modal_box_content__12wFR .styles_title__2yEtf {
    max-width: 471px;
    font-family: FuturaPT-Medium;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    text-align: center;
    margin-top: 40px;
    color: white; }
  .styles_modal_box_content__12wFR .styles_description__1E3ax {
    max-width: 471px;
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 40px;
    color: white; }
  .styles_modal_box_content__12wFR .styles_subtitle__iZpy9 {
    font-family: FuturaPT-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 25px;
    color: white; }
  .styles_modal_box_content__12wFR .styles_items__1zy7B {
    margin-top: 40px;
    margin-bottom: 40px; }
  .styles_modal_box_content__12wFR .styles_item__pkEaZ {
    max-width: 320px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #ffffff;
    margin-bottom: 25px; }
  .styles_modal_box_content__12wFR .styles_done_text__JJam0 {
    font-family: FuturaPT;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
  .styles_modal_box_content__12wFR .styles_peding_text__ANRNU {
    font-family: FuturaPT-Light;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
  .styles_modal_box_content__12wFR .styles_buttons_container__1vT1w {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-width: 650px;
    margin-bottom: 10px; }

.styles_close__2HaFP {
  position: absolute;
  top: 28px;
  right: 28px;
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.styles_container__1thxu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 1299;
  background-color: rgba(255, 255, 255, 0.9); }
  .styles_container__1thxu .styles_box__3T9X1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    width: 688px;
    height: 633px;
    object-fit: contain;
    border-radius: 5px;
    background-image: linear-gradient(132deg, #757e95 0%, #000000 86%, #000000 86%); }
    .styles_container__1thxu .styles_box__3T9X1 .styles_background__3Bi3R {
      position: absolute;
      z-index: -100;
      width: 100%;
      height: 100%; }
    .styles_container__1thxu .styles_box__3T9X1 .styles_box_content__2tUSR {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      align-items: center;
      padding-top: 76px;
      padding-bottom: 93px; }
      .styles_container__1thxu .styles_box__3T9X1 .styles_box_content__2tUSR .styles_title__2yEtf {
        width: 471px;
        font-family: FuturaPT-Medium;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 1.67px;
        text-align: center;
        color: #ffffff;
        margin-top: 50px;
        margin-bottom: 40px; }
      .styles_container__1thxu .styles_box__3T9X1 .styles_box_content__2tUSR .styles_item__pkEaZ {
        width: 320px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        font-family: FuturaPT;
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 30px; }
      .styles_container__1thxu .styles_box__3T9X1 .styles_box_content__2tUSR .styles_button__1uKfw {
        margin-top: 20px;
        width: 170px;
        height: 40px; }
    .styles_container__1thxu .styles_box__3T9X1 .styles_close__2HaFP {
      position: absolute;
      top: 28px;
      right: 28px;
      width: 50px;
      height: 50px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }

