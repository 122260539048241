.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content, .content_desktop {
    background-color: #fff;
    margin-top: 20px;
    height: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    @media (min-width: 701px) {
      margin-bottom: auto !important; }
    .container_step_description {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; } }
    .row_tabs {
      display: flex;
      align-items: center;
      margin-bottom: 12px; }
    .tabBtn {
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 3px 0px;
      margin-right: 24px;
      color: black;
      opacity: 0.5;
      white-space: nowrap;
      &.tabSelected {
        font-weight: bold;
        opacity: 1;
        border-bottom: 2px solid black; } }
    .container_requirement_description {
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 0px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .title_requirement {
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: left; } }
    .collaborators {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px; }
    .see_box {
      display: flex;
      flex-direction: column;
      .volume {
        margin-top: 23px;
        margin-bottom: 10px;
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px; }
      .row {
        margin-bottom: 2px;
        display: flex;
        font-family: FuturaPT;
        font-size: 20px;
        line-height: 1.3;
        .description {
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: 300;
          margin-bottom: 20px; }
        .reference_number {
          margin-left: 5px;
          font-family: FuturaPT-Light;
          font-size: 20px;
          font-weight: normal; }
        .completion_date {
          margin-left: 5px;
          font-family: FuturaPT-Medium;
          font-size: 20px;
          line-height: 1.3;
          font-weight: 500; } }
      .requirements {
        margin-top: 20px;
        font-family: FuturaPT-Medium;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-align: center; }
      .requirements_description {
        font-family: FuturaPT-Light;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px; }
      .requirement_list {
        display: flex;
        flex-direction: column;
        .requirement_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .requirement {
            display: flex;
            flex-direction: column;
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300;
            .required_for_payment {
              font-size: 16px; } } } } }
    .container_inputs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-right: 17px;
      .list {
        display: flex;
        flex-direction: column;
        .item_container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px;
          .label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: FuturaPT-Medium;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.75px;
            margin-left: 10px; }
          .rfp {
            font-family: FuturaPT;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal; }
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .action {} } } }
      .container_add {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .button {
          font-family: FuturaPT-Demi;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal; } }
      .container_input {
        margin-top: 0px;
        .check_container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .label {
            font-family: FuturaPT-Light;
            font-size: 20px;
            font-weight: 300; } } }
      .container_input_checkbox {
        display: flex;
        .check_container {
          display: flex;
          justify-content: center;
          align-items: center;
          .label {
            font-family: FuturaPT;
            font-size: 20px; } } }
      .container_names {
        display: flex;
        align-items: center;
        .container_volume {
          flex-grow: 1; }
        .container_first_field {
          flex-grow: 1;
          flex-basis: 0; }
        .container_last_field {
          margin-left: 17px; }
        .container_submit {
          // background-color: #f1f
          display: flex;
          flex-direction: column; } } }
    .container_inputs2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      div:first-child {
        flex: 1;
        margin-right: 12px; } }
    .buttonAdd {
      border-radius: 20px;
      background-color: black;
      border-radius: 20px;
      color: white;
      width: 30px;
      padding: 8px 14px;
      cursor: pointer; }
    .container_submit {
      // background-color: #f1f
      display: flex;
      flex-direction: column;
      margin-top: auto;
      padding-top: 40px;
      .box_two_options {
        // background-color: #1f1
        display: flex;
        flex-direction: row;
        justify-content: center;
        .button {
          display: flex;
          flex-grow: 1;
          min-width: 155px;
          max-width: 270px; } }
      .button_alone {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } }
  .content_desktop {
    @media (min-width: 701px) {
      margin-top: 0px !important; } } }
.box_image {
  background-color: #111111;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px; }
.task_status {
  display: flex;
  align-items: flex-end;
  font-family: FuturaPT;
  font-size: 14px;
  text-align: right;
  justify-content: center;
  align-items: center;
  padding-right: 20px; }
