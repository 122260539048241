.container_player {
    height: 100%;
    background-color: rgba(34, 36, 42, 0.9);
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 20px;
    text-align: center;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .player {
        position: relative;
        margin: 20px; } }
.title, .subtitle {
    display: flex;
    justify-content: center;
    font-family: FuturaPT-Medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.17px;
    text-align: center;
    color: black; }
.title {
    font-size: 35px;
    font-weight: bold; }
.subtitle {
    font-size: 18px;
    font-weight: 300;
    font-family: FuturaPT-Light; }
.container_image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    @media (min-width: 701px) {
        margin-top: 50px; } }
.first_step_title, .first_step_description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-family: FuturaPT-Medium;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.11px;
    text-align: center;
    color: black; }
.first_step_description {
    font-size: 18px;
    font-weight: 300;
    font-family: FuturaPT-Light;
    text-align: center;
    max-width: 430px; }
.container_step_description {
    padding: 11px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .hidden_box {
        margin-top: 200px;
        width: 100%;
        height: 1px; }
    .step_number {
        margin-top: 35px;
        margin-bottom: 25px;
        width: 50px;
        height: 50px;
        border: solid 1px;
        border-radius: 50%; }
    .step_number_label {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: FuturaPT-Medium;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #000000; }
    .step_title {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #000000; }
    .step_description {
        font-family: FuturaPT-LightObl;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000; }
    .step_description_with_link {
        font-family: FuturaPT-Light;
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #000000;
        .link {
            font-family: FuturaPT-Demi;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: #000; } } }
.first_add_description {
    position: fixed;
    width: 100%;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0%);
    @media (min-width: 701px) {
        position: fixed;
        bottom: 30px;
        width: fit-content;
        transform: translate(88%, 0%); }
    .container_first_add_description {
        display: flex;
        align-items: center;
        flex-direction: column;
        @media (min-width: 701px) {
            flex-direction: row; }
        .first_add_wrapper {
            @media (min-width: 701px) {
                min-width: 210px;
                margin-bottom: 95px; } }
        .first_add_description {
            font-family: FuturaPT-Light;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center; }
        .first_add_title {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 5px;
            font-family: FuturaPT-Medium;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.83px;
            text-align: center;
            @media (min-width: 701px) {
                margin-bottom: 10px;
                padding-left: 0px;
                padding-right: 0px; } }
        .first_add_description {
            font-family: FuturaPT-Light;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center; }
        .add_your_first_task {
            font-family: FuturaPT;
            font-size: 16px;
            line-height: 0.38;
            text-align: center;
            color: #757e95; }
        .icon_container {
            // background-color: #1f1
            width: 100%;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 15px;
            margin-right: 180px;
            .first_add_arrow {
                // background-color: #f1f
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: fill; }
                .first_add_arrow {}
                svg {
                    height: 100%;
                    width: 100%; } } } } }
