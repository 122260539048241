.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .bottom_bg {
    position: absolute;
    right: 0;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    display: flex;
    height: 503px;
    width: 414px;
    bottom: 0;
    img {
      height: 100%;
      width: 100%;
      object-fit: fill; } }
  .bottom_bg {
    svg {
      width: 100%;
      height: 100%; } } }
.version {
  font-family: FuturaPT-Medium;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px; }
.link {
  font-family: FuturaPT-Medium;
  text-decoration: underline !important;
  cursor: pointer;
  color: #000000 !important; }
.separator {
  padding: 0 20px; }
.content {
  margin-top: 170px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 70px !important;




  @media (min-width: 700px) {
    background-color: #fff;
    margin-top: 250px;
    border-radius: 5px;
    max-width: 687px !important;
    box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
    padding-top: 42px !important;
    padding-bottom: 52px !important;
    padding-left: 38px !important;
    padding-right: 38px !important; }

  .container_step_description {
    padding: 21px;
    display: flex;
    align-items: center;
    flex-direction: column; }

  .container_form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .input {
      margin-top: 15px; } }

  .container_error {
    text-align: center;
    margin: 10px; }
  .container_submit {
    padding-top: 40px;
    .button {
      display: flex;
      flex-grow: 1;
      justify-content: center; } }
  .container_signin {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .container_forgot_password {
    margin-top: 20px; } }
