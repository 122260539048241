.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    margin-top: 70px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 700px) {
      margin-top: 250px !important;
      margin-bottom: auto !important;
      border-radius: 5px;
      max-width: 687px !important;
      box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6;
      padding-top: 42px !important;
      padding-bottom: 52px !important;
      padding-left: 38px !important;
      padding-right: 38px !important; }
    .container_form {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: stretch; } } }
