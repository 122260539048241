.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .content {
    background-color: #fff;
    margin-top: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 70px !important;
    @media (min-width: 701px) {
      margin-top: 120px !important;
      margin-bottom: auto !important; }
    .filter_group {
      display: flex;
      flex-direction: column;
      .filter_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: FuturaPT;
        font-size: 20px; } }
    .container_submit {
      padding-top: 40px;
      padding-bottom: 40px;
      .button {
        display: flex;
        flex-grow: 1;
        justify-content: center; } } } }
