.content {
    cursor: pointer;
    max-width: 180px;
    width: 100%;
    height: 93px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
    .button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 83px;
        border-radius: 5px;
        box-shadow: 4px 3px 19px 0 #8d8d8d, -7px -7px 16px 0 #fafbfc;
        overflow: hidden;
        background-color: #111;
        .background {
            position: absolute;
            width: 69px;
            height: 78px;
            z-index: 1;
            top: 0px;
            left: 0px; }
        .number {
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            font-family: FuturaPT-Demi;
            font-size: 35px;
            line-height: 0.49;
            letter-spacing: -0.88px;
            text-align: center;
            color: white; }
        .text {
            margin-top: 10px;
            font-family: FuturaPT-Medium;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.42;
            letter-spacing: 0.9px;
            text-align: center;
            color: white; } }
    .bottom_line {
        height: 2px;
        width: 100%; } }
