.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  .rowFilters {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 18px;
    flex-wrap: wrap;
    margin-bottom: 18px; }
  .lblFilters {
    font-size: 18px;
    min-width: 72px; }
  .inputDate {
    min-width: 186px;
    max-width: 186px;
    margin: 0px 12px;
    @media only screen and (min-width: 600px) {
      min-width: 160px;
      max-width: 170px; } }
  .inputNomination {
    min-width: 120px;
    max-width: 120px;
    margin: 0px 12px; }
  .inputType {
    min-width: 120px;
    max-width: 120px;
    margin: 0px 12px; }
  .checkbox {
    margin: 0px 12px;
 }    // min-width: 200px
  .blank {
    flex: 1; }
  .btFilter {
    padding: 0px 0px 0px 24px;
    cursor: pointer; }
  .gridContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .emptyTxt {
    text-align: center;
    padding: 30px;
    font-size: 18px;
    opacity: 0.75; }
  .docCard {
    width: calc(100% / 5 - 24px);
    min-width: none;
    max-width: none;
    margin: 12px; }
  .table {
    margin-left: 0px;
    margin-bottom: 60px; }
  .hdtable {
    background-color: black;
    th {
      color: white;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px; } }
  .rowMobile {
    display: flex;
    flex-direction: column;
    .rowRfp {
      padding-bottom: 5px;
      display: flex; }
    .rowName {
      padding: 1px 0;
      font-family: FuturaPT-Medium;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black; }
    .rowNomination {
      padding: 1px 0;
      font-family: FuturaPT;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase; }
    .rowDate {
      padding: 1px 0;
      font-family: FuturaPT;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757e95;
      text-transform: uppercase; }
    .rowActionsDoc {
      margin-left: auto;
      display: flex;
      align-items: center;
      position: relative;
      .actionBtn {
        padding: 4px 12px 0px 12px;
        cursor: pointer; } } }
  .rowName {
    font-weight: bold;
    font-size: 16px; }
  .rowDate {
    font-size: 14px;
    color: #757e95;
    text-transform: uppercase; }
  .rowNomination {
    font-size: 14px;
    text-transform: uppercase; }
  .rowProfileDoc {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 9px; }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      flex: 1; } }
  .rowActionsDoc {
    display: flex;
    align-items: center;
    position: relative;
    .actionBtn {
      padding: 4px 12px 0px 12px;
      cursor: pointer; } } }
.badge {
  background-color: #8180AC;
  color: white;
  font-weight: bold;
  font-size: 10px;
  border-radius: 12px;
  padding: 3px 10px; }
.ellipsisMenu {
  background-color: white;
  box-shadow: 4px 3px 10px 0 #d6d6d6, -7px -7px 16px 0 #f6f6f6 !important;
  border-radius: 6px;
  margin-top: 48px;
  margin-left: 24px;
  overflow: visible !important;
  ul {
    padding: 0; }
  &::before {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid white;
    content: "";
    position: absolute;
    right: 0px;
    top: -12px; }
  .itemMenuEllipsis {
    border-bottom: 1px solid #E2E2E2 !important;
    padding: 12px !important;
    white-space: nowrap;
    cursor: pointer;
    background-color: white !important;
    &:hover, &.Mui-focusVisible {
      background-color: white !important; } } }
.filesearch {
  position: absolute;
  z-index: -1;
  opacity: 0; }
.contSearch {
  flex: 1;
  margin-right: 6px;
  position: relative;
  overflow: visible;
  min-width: 90px;
  max-width: 460px;
  .contSuggest {
    position: absolute;
    top: 100%;
    background-color: white;
    z-index: 3;
    border-radius: 6px;
    left: 6px;
    width: 100%;
    .itemSuggest {
      padding: 12px;
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      font-weight: 600; }
    .itemSuggest:hover {
      background-color: rgba(0, 0, 0, 0.1); } } }

