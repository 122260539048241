.navBar {
  position: relative !important; }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch; }
.containerLogo {
  padding: 20px; }
.iframe {
  width: 95%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  border: none; }
.icon_container {
  align-self: flex-start; }
