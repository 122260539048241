.grid_container, .grid_container_desktop {
  height: 100%;
  @media (min-width: 701px) {
    padding-left: 70px !important; }
  .grid_page {
    height: 100%; } }
.grid_container_desktop {
  @media (min-width: 701px) {
    padding-left: 0px !important; } }
.container {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .main_content {
    height: 100%;
    .content, .content_desktop {
      background-color: #fff;
      margin-top: 0px;
      // padding-left: 20px !important
      // padding-right: 20px !important
      // padding-bottom: 30px !important
      height: 100%;
      display: flex;
      flex-direction: column;
      @media (min-width: 701px) {
        // margin-top: 120px !important
        margin-bottom: auto !important;
        margin-left: 30px;
        object-fit: contain;
        border-radius: 5px;
 }        // box-shadow: 4px 3px 10px 0 #ededed, -7px -7px 16px 0 #f6f6f6
      .container_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .title {
          font-family: FuturaPT-Medium;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.83px;
          text-align: center;
          color: #ffffff; } }
      .container_step_description {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px;
        display: flex;
        align-items: center;
        flex-direction: column; }
      .container_contract_type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; }
      .title_form {
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center; }
      .subtitle_form {
        margin-top: 5px;
        font-family: FuturaPT-LightObl;
        font-size: 17px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center; }
      .label_form {
        padding: 15px 0px 15px 10px;
        font-family: FuturaPT-Medium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: left; }
      .container_inputs {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .container_input {
          margin-top: 0px; }
        .container_names {
          display: flex;
          align-items: center;
          .container_first_field {
            flex-grow: 1;
            flex-basis: 0; }
          .container_last_field {
            margin-left: 17px; } } }
      .container_error {
        text-align: center;
        margin: 10px; }
      .container_propose_changes {
        // background-color: #f1f
        display: flex;
        flex-direction: column;
        .button_alone {
          display: flex;
          flex-grow: 1;
          justify-content: center; } }
      .container_submit {
        // background-color: #f1f
        margin-top: auto;
        display: flex;
        flex-direction: column;
        .box_two_options {
          // background-color: #1f1
          display: flex;
          flex-direction: row;
          justify-content: center;
          .button {
            display: flex;
            flex-grow: 1;
            min-width: 155px;
            max-width: 270px; } }
        .button_alone {
          display: flex;
          flex-grow: 1;
          justify-content: center; } }
      .list_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        .button {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          margin-top: auto; }
        .added_content {
          max-height: 171px !important;
          overflow: auto;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          .added_list {
            display: flex;
            flex-direction: column;
            .item_box {
              display: flex;
              flex-direction: row;
              align-items: center;
              .data {
                padding: 20px 0px;
                display: flex;
                flex-direction: row;
                width: calc(100% - 30px);
                align-items: center;
                .email {
                  font-family: FuturaPT-Light;
                  font-size: 20px;
                  font-weight: 300;
                  width: 50%;
                  text-overflow: ellipsis;
                  overflow: hidden; }
                .role {
                  width: 50%; } }
              .actions {
                display: flex;
                flex-direction: row;
                margin-left: auto; } } } } } }
    .content_desktop {
      max-width: 700px !important;
      min-height: 650px !important;
      max-height: 800px !important;
      margin-top: 0px !important;
      margin-left: 0px !important; } } }
.box_image {
  background-color: #111111;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-right: 5px;
  margin-left: 5px; }
